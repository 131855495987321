import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import instance from '../../../context/AuthApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Modal,Button } from 'react-bootstrap'
import AvatarPlaceholder from "../../../assets/images/avatar.png";

class SitterDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            GetPost:{},
            references:[],
            Schools:[],
            Accept:false,
            profile_pic:AvatarPlaceholder,
            Reject:false,
            job_id:(this.props.location?.state?.job_id) ? this.props.location.state.job_id : '' 
        }
        //console.log(this.props.match.params.id)
        this.getSitterDetails = this.getSitterDetails.bind(this);
        this.AcceptRejectBid = this.AcceptRejectBid.bind(this);
        this.HandleModalClose = this.HandleModalClose.bind(this);
        this.HandleModalOpen = this.HandleModalOpen.bind(this);
    }
    
    componentDidMount() {
        this._isMounted = true;
        if(this._isMounted ){
            if(this.state.job_id == ''){
                this.props.history.push('/parent/dashboard');
            }else{
                this.getSitterDetails();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.getSitterDetails();
    }

    getSitterDetails = () => {
        instance.get(`/parent/job/bid/details?bid_id=${this.props.match.params.id}`).then((res) => {
            //console.log(res.data.data);
           if(this._isMounted){ 
            this.setState({
                job_id:(this.props.location?.state?.job_id) ? this.props.location.state.job_id : ''  ,
                GetPost:res.data.data,
                references:res.data.data.references,
                Schools:res.data.data.schools,
                loading:false});
            }
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })
    }

    HandleRedirect = (e,route,state)=>{
        this.props.history.push({pathname:route,state:state})
    }

    AcceptRejectBid = (e,status) => {
        
        this.setState({loading:true})
        
        instance.put(`/parent/accept-bid`,{bid_id:this.props.match.params.id,status:status}).then((res) => {    
            console.log('response is ',res.data.data.status === "Reject");
            let bidStatus = res.data.data.status
            this.HandleModalClose(e,status);
            if(status==='Accept'){
                if(bidStatus !== "" && bidStatus === "Reject"){
                    toast(`The proposal has been ${bidStatus}ed because job start time has passed away.`, { type: "success" });    
                    this.props.history.push({ 
                        pathname:'/parent/dashboard', 
                        state: {
                            firsttab:2,
                            secondtab:2
                        }
                    });
                } else if(bidStatus !== "" && bidStatus === "Accept") {
                    toast(`Sitter booked successfully`, { type: "success" });    
                    this.props.history.push({ 
                        pathname:'/parent/dashboard', 
                        state: {
                            firsttab:2,
                            secondtab:1
                        }
                    });
                }
            }
            else if(status==='Reject') {
                toast(`You have successfully ${status}ed the proposal`, { type: "success" });    
                this.props.history.push({ 
                    pathname:'/parent/dashboard', 
                    state: {
                        firsttab:2,
                        secondtab:2
                    }
                });
            }
            // console.log(res.data)     
            if(this._isMounted){
                this.setState({loading:false});
            } 
        }).catch((error) => {
            console.error(error)
            this.HandleModalClose(e,status);            
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })

    }

    HandleModalOpen = (e,modalname,id='') => {
        this.setState({[modalname]:true,CancelId:id})
    }

    HandleModalClose = (e,modalname,id='') => {
        this.setState({[modalname]:false,CancelId:id});

        if(modalname == 'SendParposal'){
            this.props.history.push('/sitter/dashboard');
        }
    }


    render() {
        return (
            <>
    <section className={`dashboard ${(this.state.loading) ? 'loader' : ''}`}>
        <div className="container">
            <div className="row sitter-detail-header pink-grad">
                <div className="col-12 pb-4 pb-md-1">
                    <a onClick={(e)=>this.HandleRedirect(e,'/parent/job/applied-shitters',
                                    {job_id:this.state.job_id})} className="back-button"><i className="fa fa-arrow-left"></i>Back</a></div>
                <div className="col-4 col-md-3 col-lg-2">
                    <div className="profile-image">
                        {(!this.state.GetPost.image || this.state.GetPost.image === undefined || this.state.GetPost.image === null)? <img src={this.state.profile_pic} alt=""/> : <img src={this.state.GetPost.image} alt=""/>}
                        
                    </div>
                </div>
                <div className="col-8 col-md-9 col-lg-10 basic-detail">
                    <h4>{this.state.GetPost.first_name} {this.state.GetPost.last_name}</h4>
                    <h5 className="hourly-rate">${this.state.GetPost.hourly_rate}/hr</h5>
                    {/* <span className="background"><i className="fa fa-check-circle"></i> Background Verified</span> */}
                </div>
            </div>
            <div className="row mt-4 m-0 mt-md-5">
                <div className="col-12 col-md-8 py-2">
                    <div className="description d-grid">
                        <h4 className="underline">About {this.state.GetPost.first_name} {this.state.GetPost.last_name}</h4>
                        <span><i className="fa fa-user"></i>{moment().diff(moment(this.state.GetPost.dob,"YYYY-MM-DD"),'years')} Y 
                        {/* , {this.state.GetPost.religion} */}
                        </span>
                        <span><i className="fa fa-school"></i>
                        {this.state.Schools.map((school)=> { 
                                    return `${school.school_name}, `;
                            })}
                            </span>
                        <span><i className="fa fa-road"></i>
                            {this.state.GetPost.transportation}
                        </span>
                        
                        <p>{this.state.GetPost.details}</p>
                    </div>
                </div>
                <div className="col-12 col-md-4 py-2">
                    <div className="references-detail d-grid">
                        <h4 className="underline">About {this.state.GetPost.first_name} {this.state.GetPost.last_name}</h4>


                        {this.state.references.map((reference,Rindex)=> { 
                                    return (<div key={Rindex}>
                                    <label className="text-grey">Reference {Rindex +1} </label>
                        <p className="text-blue">{reference.f_name} {reference.l_name} | {reference.country_id} {reference.phone}</p></div>)
                            })}
                        {/* <label className="text-pink">Invite for interview via :</label> */}
                        {/* <small className="text-grey"><i className="fa fa-envelope"></i> anna01@gmail.com</small> */}
                    </div>
                </div>
                <div className="col-12 detail-actions py-4">
                    <ul className="list-inline">
                        <li className="list-inline-item"><a onClick={(e) => this.HandleModalOpen(e,'Reject')} className="btn small-btn text-blue blue-border-button">Decline proposal</a></li>
                        <li className="list-inline-item"><a onClick={(e) => this.HandleModalOpen(e,'Accept')} className="btn small-btn blue-grad text-white" data-bs-toggle="modal" data-bs-target="#hire-sitter">Hire babysitter</a></li>
                        {/* <li className="list-inline-item"><a className="btn chat-button small-btn text-white"><i className="chat"></i>Chat</a></li> */}
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad" className="send-proposal-modal " show={this.state.Accept} onHide={(e)=> this.HandleModalClose(e,'Accept')}>        
        
    <Modal.Body>
            <div className="col-12 text-center">
                    <h3 className="text-white pt-4">Are you sure you want to hire this babysitter?</h3>
                    <p className="text-white"> By hiring this babysitter, this job will be booked for the babysitter. Cancelling less than two hours prior to the start of a job will incur a $15 cancellation fee.</p>
            </div>
        </Modal.Body>   
            <Modal.Footer className="justify-content-center" >
            <button onClick={(e)=> this.HandleModalClose(e,'Accept')} type="button" className="btn btn-primary small-btn">No, cancel</button> &nbsp;&nbsp;&nbsp;
            <button onClick={(e) => this.AcceptRejectBid(e,'Accept')} type="button" className="btn btn-white small-btn text-blue">Yes, hire babysitter</button>
         </Modal.Footer>                                    
   
  </Modal>

  <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad " className="cancel-job-modal " show={this.state.Reject} onHide={(e)=> this.HandleModalClose(e,'Reject')}>        
        <Modal.Body>
            <div className="col-12 text-center">
                    <h3 className="text-white pt-4">Are you sure?</h3>
                    <p className="text-white"> Do you want to Decline this proposal?</p>
            </div>
        </Modal.Body>   
            <Modal.Footer className="justify-content-center" >
            <button onClick={(e)=> this.HandleModalClose(e,'Reject')} type="button" className="btn btn-primary small-btn">No</button> &nbsp;&nbsp;&nbsp;
            <button onClick={(e) => this.AcceptRejectBid(e,'Reject')} type="button" className="btn btn-white small-btn text-blue">Yes</button>
         </Modal.Footer>                                     
  </Modal>     
    
            </>
        );
    }
}

SitterDetails.propTypes = {

};

export default SitterDetails;