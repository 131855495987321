import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarOne from '../../../components/SideBars/SidebarOne';
import { Link } from 'react-router-dom';
import instance from '../../../context/AuthApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import AvatarPlaceholder from "../../../assets/images/avatar.png";

class AppliedSitters extends Component {
    constructor(props) {
        super(props);
        this.getJobPosting = this.getJobPosting.bind(this);
        this.GetLocation = this.GetLocation.bind(this);
        this.getUpcomingJobs = this.getUpcomingJobs.bind(this);
        this.getRunningJobs = this.getRunningJobs.bind(this)
        this.getCompletedJobs = this.getCompletedJobs.bind(this);
        this.state ={
             loading:true,   
             BidsListing:[],
             profile_pic:AvatarPlaceholder,
             PostsList:[],
             RunningJobs:[],
             UpcomingJobs:[],
             CompletedJobs:[],
             location:{lat:'',lng:''},
             job_id:(this.props.location?.state?.job_id) ? this.props.location.state.job_id : ''            
        }
        this.getBidsListing = this.getBidsListing.bind(this);
    }
    componentDidMount() {
        if(this.state.job_id == ''){
            this.props.history.push('/parent/dashboard');
        }else{
                this.GetLocation();
                this.getBidsListing()
                this.getJobPosting();
                this.getUpcomingJobs();
                this.getRunningJobs();
                this.getCompletedJobs();
        }
    }

    getUpcomingJobs = () => {
        instance.get('/parent/upcoming-jobs?page=1').then((res) => {
            this.setState({UpcomingJobs:res.data.data,loading:false});
            // console.log(res.data.data);
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
    })
    }
    getRunningJobs = () => {
        instance.get('/parent/ongoing-jobs').then((res) => {
            this.setState({RunningJobs:res.data.data,loading:false});
            // console.log('getRunningJobs',res.data.data);
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })
    }
 
    getCompletedJobs = () => {
        //console.log('getCompletedJobs');
        instance.get('/parent/completed-jobs').then((res) => {
            this.setState({CompletedJobs:res.data.data,loading:false});
            //console.log('getcompletedobs',res.data.data);
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })
    }

    getJobPosting = () => {
        instance.get('/parent/job-postings?page=1').then((res) => {
            this.setState({PostsList:res.data.data,loading:false});
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })
    }

    GetLocation = () => {
        var pos;
        var OurThis = this;
       if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
              pos = {
               lat: position.coords.latitude,
               lng: position.coords.longitude
           }
           OurThis.setState({location:pos});
       });
       }else{
           toast('Unable to find location | Please search manually ', { type: "error" });
       }
     
   }

    getBidsListing = () => {
        instance.get(`/parent/job/bids?job_id=${this.state.job_id}`).then((res) => {
            this.setState({BidsListing:res.data.data,loading:false});
            //console.log(res.data.data);
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
    })
    }

    HandleRedirect = (e,route,state)=>{
        this.props.history.push({pathname:route,state:state})
    }
    
    render() {
        let activities = [
            {name:'Your job posts',count:this.state.PostsList.length},
            {name:'Current jobs',count:this.state.RunningJobs.length},
            {name:'Upcoming jobs',count:this.state.UpcomingJobs.length},
            {name:'Completed jobs',count:this.state.CompletedJobs.length},
        ]

        return (<section className={`dashboard ${(this.state.loading) ? 'loader' : ''}`}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-3 sidebar applied-sitters">
                <SidebarOne id="dashboard-tab" type="parent" button={<Link to='/job-post' className="view-profile-button"><i className="fa fa-briefcase"></i> Post Job </Link>}    activities={activities} ></SidebarOne>
                </div>
                <div className="col-12 col-lg-9">
                    <div className="row settings-page-title p-4 pt-5">
                        <div className="col-12">
                            <Link to={'/parent/dashboard'} className="text-grey back-button"><i className="fa fa-arrow-left"></i>Back
                            </Link>
                        </div>
                    </div>

                    {(this.state.BidsListing.length == 0) ? <h5 className="text-grey"> {(this.state.loading) ? 'loading...' : 'No sitters applied to your post'} </h5> : '' }

                    {this.state.BidsListing.map((value) => {
                        return (
                        <div key={value.bid_id}>
                        <div onClick={(e)=>this.HandleRedirect(e,`/parent/job/sitter-details/${value.bid_id}`,
                            {job_id:this.state.job_id})} className="col-12 col-lg-10 sitters-list-item">
                        <div className="row">
                            <div className="col-3 col-md-2 profile-image">
                                {(!value.image || value.image === null || value.image === undefined) ? <img src={this.state.profile_pic} alt=""/> : <img src={value.image} alt=""/>}
                                
                                {/* <img src={value.image} alt=""/> */}
                            </div>
                            <div className="col-9 col-md-10 d-grid">
                                <div className="col-12">
                                <h5 className="text-blue d-inline float-start"> {value.first_name} {value.last_name} </h5>
                                <span className="hourly-rate">${value.hourly_rate}/hr</span>
                                </div>
                                {/* <span><i className="fa fa-check-circle"></i>Background verified</span> */}
                                <span><i className="fa fa-user"></i>{moment().diff(moment(value.dob,"YYYY-MM-DD"),'years')} Y 
                                {/* , {value.religion} */}
                                </span>
                                <span><i className="fa fa-school"></i>{value.schools.map((school)=> { 
                                    return `${school.school_name},` 
                                 })}</span>
                                <span><i className="fa fa-road"></i>
                                    {value.transportation}
                                </span>
                                {value.references.map((reference, index)=> {
                                    return <span key={index}>
                                     Reference {index+1} : <br/>
                                     <i className="fa fa-users"></i> {reference.f_name} {reference.l_name} <span>&nbsp; || &nbsp;</span> <i className='fab fa-whatsapp' aria-hidden="true"></i>Phone : {reference.country_id} {reference.phone}
                                    </span>
                                })}
                                
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <small className="text-grey d-block pt-2">{value.details}</small>
                            <a className="btn-pink chat-button"><i className="chat"></i>Chat</a>
                        </div> */}
                    </div></div>)
                    })}

                    
                </div>
            </div>
        </div>
    </section>
        );
    }
}

AppliedSitters.propTypes = {

};

export default AppliedSitters;