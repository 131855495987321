import { Switch, Route } from 'react-router-dom';
import Home from './pages/home'
import Login from './pages/Auth/login';
import onboarding from './pages/onboarding/index';
import onboardingfail from './pages/onboarding/fail';
// import CheckMail from './pages/Auth/checkmail';
import CheckMail from './pages/Auth/EmailVerfiy/CheckEmail';
import CheckOtp from './pages/Auth/checkotp';
import ForgotPassword  from './pages/Auth/forgot-password';
import ResetPass from './pages/Auth/resetpassword';
import ParentSignup from './pages/Auth/signup/parent';
import SitterSignup from './pages/Auth/signup/shitter';
import SitterProfile from './pages/Profile/SitterProfile';
import ParentProfile from './pages/Profile/ParentProfile';

import PageNotFound from './pages/PageNotFound/PageNotFound';
import {PrivateRoute,PublicRoute } from './routes/routes';
import Privacy from './pages/Terms/privacy';
import Terms from './pages/Terms/terms';
import ParentDashboard from './pages/Auth/DashBoard/ParentDashboard';
import SitterDashboard from './pages/Auth/DashBoard/SitterDashboard';
import "react-datepicker/dist/react-datepicker.css";
import JobDetails from './pages/Auth/Job/JobDetails';
import JobPost from './pages/Auth/Job/JobPost';
import AppliedSitters from './pages/Auth/Job/AppliedSitters';
import SitterDetails from './pages/Auth/Job/SitterDetails';
import Reviews from './pages/Reviews/Reviews';
import TermsSitter from './pages/Terms/terms-sitter';
import Faq from './pages/Faq/faq';
const SitterRole = 2;
const ParentRole = 1;
function App() {


  return (
    <Switch>

    <PublicRoute exact path="/" component={Home} />
    <PublicRoute exact path="/login" component={Login} /> 

    <PublicRoute exact path="/stripe-onboarding" component={onboardingfail} /> 
    <PublicRoute exact path="/stripe-onboarding/:id" component={onboarding} /> 
    <PublicRoute exact path="/check-email" component={CheckMail} />
    <PublicRoute exact path="/check-otp" component={CheckOtp} />
    <PublicRoute exact path="/signup-parent" component={ParentSignup} />
    <PublicRoute exact path="/signup-sitter" component={SitterSignup} />
    <PublicRoute exact path="/forgot-pass" component={ForgotPassword} />
    <PublicRoute exact path="/term" component={Terms} />
    <PublicRoute exact path="/faq" component={Faq} />
    <PublicRoute exact path="/term-sitter" component={TermsSitter} />
    <PublicRoute exact path="/privacy-policy" component={Privacy} />
    <PublicRoute exact path="/reset-password" component={ResetPass} />
    
    {/* { (localStorage.getItem('isloggedin')) :  } */}
    <PrivateRoute role={SitterRole}  path='/sitter/profile' component={SitterProfile}  />
    <PrivateRoute  role={ParentRole} path='/parent/profile' component={ParentProfile}  />
    <PrivateRoute  role={ParentRole} path='/parent/dashboard' component={ParentDashboard}  />
    <PrivateRoute  role={ParentRole} path='/job-post' component={JobPost}  />

    <PrivateRoute  role={ParentRole} path='/parent/job/details' component={JobPost}  />
    <PrivateRoute  role={ParentRole} path='/parent/job/applied-shitters' component={AppliedSitters}  />
    
    <PrivateRoute  role={ParentRole} path='/parent/job/sitter-details/:id' component={SitterDetails}  />
    
    <PrivateRoute  role={ParentRole} path='/parent/reviews' component={() => <Reviews type='parent' />}  />
    <PrivateRoute  role={SitterRole} path='/sitter/reviews' component={() => <Reviews type='sitter' />}  />
    

    <PrivateRoute  role={SitterRole} path='/sitter/job/details' component={JobDetails}  />
    {/* <PrivateRoute  role={ParentRole} path='/sitter/job/detailsx' component={JobPost}  /> */}
    <PrivateRoute  role={SitterRole} path='/sitter/dashboard' component={SitterDashboard}  />
   
    <Route  component={PageNotFound} />

  </Switch>
  );
}

export default App;
