import React, { Component } from 'react';
import  ProfilePicturePlaceholder from "../../../assets/images/profile-picture (1).png";
import Emptyjobs from "../../../assets/images/ongoing-jobs.svg";
import EmptyUpcomingJobs from "../../../assets/images/job-list.svg";
import EmptyCompletedJobs from "../../../assets/images/completed-jobs.svg";
import  ParentPoster  from  "../../../assets/images/poster-parent (1).png";
import {Link} from 'react-router-dom';
import instance from '../../../context/AuthApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import ProfileService from '../../../services/Profile.Service';
import { Modal } from 'react-bootstrap'
import SidebarOne from '../../../components/SideBars/SidebarOne';
import Countdown from "react-countdown";
import TimerLimit from '../../../Constants/TimerLimit';
import StarRatings from 'react-star-ratings';
import Introvideo from '../../../assets/video/introvideo.mp4'
import { fetchEventSource } from '@microsoft/fetch-event-source';
import TokenService from '../../../services/Token.Service';
// import PropTypes from 'prop-types';
// import Vidposter from "../../../assets/images/baby.png";
// import  LogoTextBlue from "../../../assets/images/logo-text-blue (1).png";
// import  Chatplaceholder from "../../../assets/images/chats-empty.svg";
// import  DashIllustration  from  "../../../assets/images/dash-illustration (1).png";
// import { ChildAgeGroups, ChildAgeGroupsInYrs } from "../../../Constants/ChildAgeGroups";
// import Chat from '../../../components/Chats/Chat';
// import  io  from "socket.io-client";


// setup AbortController
let controller;
let ongoingSSEConnections = [];
let eventSource=null; 
// let Canbid ;
let counter=0;
let SSEtimmer;
let  SSETimerInMinnets;
let  SSETimerInMiliSeconds;
// let interval=0;
let isConnected = false;
let isProcessingJob = false;
let shouldUpdate = false;

class SitterDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.getJobPosting = this.getJobPosting.bind(this);
        this.listenForSSE = this.listenForSSE.bind(this);
        this.GetLocation = this.GetLocation.bind(this);
        this.getProfileDetails = this.getProfileDetails.bind(this);
        this.getUpcomingJobs = this.getUpcomingJobs.bind(this);
        this.getRunningJobs = this.getRunningJobs.bind(this)
        this.getCompletedJobs = this.getCompletedJobs.bind(this);
        this.HandleAddReview = this.HandleAddReview.bind(this);
        //this.GotoUserChat = this.GotoUserChat.bind(this);
        this.getOnboardinglink = this.getOnboardinglink.bind(this);
        this.HandleWithdaw = this.HandleWithdaw.bind(this);
        //this._connect_socket = this._connect_socket.bind(this);
        this.state = {
        profile: ProfilePicturePlaceholder,
        loading:true,
        linkloading:false,
        FirstTabs:(this.props.location?.state?.firsttab) ? this.props.location.state.firsttab : 1,
        SecondTabs:(this.props.location?.state?.secondtab) ? this.props.location.state.secondtab : 3,
        PostsList:[],
        GetPosts:[],
        RunningJobs:[],
        UpCommingJobs:[],
        CompletedJobs:[],
        setSelectedChild_id:[],
        setSelectedId:[],
        setSelectedChild_dob:[],
        setSelectedDob:[],
        jobQueue:[],
        location:{lat:'',lng:''},
        CancelModal:false,
        CancelId:'',
        Distance:0,
        last_bid_time:'',
        server_time:'',
        timer:0,
        rating:5,
        wallet_balance:0,
        ReviewText:'',
        TimmerModal:false,
        ReviewModal:false,
        Onboarding:false,
        startjobotp:'',
        child_age: [{child_id:"",f_name: "", dob: "", name: ""}],
        user_id: 1 ,
        CanBid:false,
        withdaw:false,
        BannerOpen:(localStorage.getItem("BannerShows") != '0') ? true : false,
        InfoVideo:(localStorage.getItem("VideoShows") != '0') ? true : false,
        SocketClient:'',
        tomessageid:'to',
        frommessageid:'from',
        messagejobid:'job_id',
        authHeaders:localStorage.getItem('api_token')!=="" || localStorage.getItem('api_token')!==null ? { 'Authorization': `Bearer ${localStorage.getItem('api_token')}` } : "",
        counter:0,
        }
    }

    

    // _connect_socket = () => {
    //     let NewSocket = io.connect('https://example.com:8000');
    //     //this.setState({SocketClient:NewSocket});
    // }


    // GotoUserChat = (ChatValue) => {

    //     let SenderId = TokenService.getUserId();
    //     let ReceiverId = ChatValue.parent_id;
    //     let JobId = ChatValue.job_id;


    //     this.setState({
    //         tomessageid:ReceiverId,
    //         frommessageid:SenderId,
    //         messagejobid:JobId
    //     })

        // let StartChatData = {
        //     bearer_token: TokenService.getLocalAccessToken(),
        //     message_to: ReceiverId,
        //     message: 'Hi..',
        //     job_id: ChatValue.job_id,
        // };
        // let GetUserData = {
        //     parent_id: ChatValue.parent_id,
        //     sitter_id: TokenService.getUserId(),
        //     job_id: ChatValue.job_id,
        // }
        // const Socket = io.connect('https://api.simplisitter.com:8000');
        // var ConnectSocketData = {
        //     refresh_token: TokenService.getLocalRefreshToken(),
        //     device_token: 'dummy_token',
        //     device_id: 'dummy_id',
        //     device_type: '3',
        //   };
          //console.log('---00---')


        //    Connect Socket  With Server 
        //   Socket.on('connect',()=> {
            //  Connect to User 
            // Socket.emit("connect-user", ConnectSocketData);
            // Socket.on("connect-user-response", (response)=> {
            //     if(response){
            //     Socket.emit('get-chat-id',GetUserData)
            //     Socket.emit('send-message',StartChatData);
                // Socket.on('chat-messages',(res) => {
                //     console.log('chat-messages',res)
                // })
        //         Socket.emit("get-chat-list", {"bearer_token": TokenService.getLocalAccessToken(),});
        //         this.setState({FirstTabs:3,loading:false});
        //         }else{

        //         }
                
        //      }); 
        //   Socket.on('chat-id',(res) => {
            //console.log('res',res);
        // })
        // });

        // console.log(Data);
    // }
    
    HandleVideo = () => {
     
    }

    HandleWithdaw = (e) => {
        this.setState({loading:true});
        instance.get('payment/withdraw').then((res) => {
            //console.log('pay',res.data);
            this.setState({loading:false});

            // this.setState({RunningJobs:res.data.data,loading:false});
            // console.log('getRunningJobs',res.data.data);
        }).catch((error) => {
            this.HandleModalClose(e,'withdaw')
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })
    }

    HandleCloseBanner = () => {
        this.setState({BannerOpen:false});
        localStorage.setItem("BannerShows",'0');
    }

    HandleCloseInfoVideo = () => {
        this.setState({InfoVideo:false});
        localStorage.setItem("VideoShows",'0');
    }

    HandleDistance = (e) =>  {
        //console.log(e.target.value)
        this.setState({Distance:e.target.value});
    }
    HandleFirstTab  = (e,index) => {
        this.setState({ FirstTabs:index })  
    }

    HandleRedirect = (e,pathname,state) => {
        if('/sitter/job/details' == pathname){
            if(this.state.CanBid){
                this.props.history.push({pathname:pathname,state:state})
            }else{
                this.HandleModalOpen(e,'TimmerModal');
            }
        }else{
            this.props.history.push({pathname:pathname,state:state})
        }
    }

    HandleSecondTab  = (e,index) => {
        this.setState({ SecondTabs:index }) 
    }

    handleLogoClick() {
        if(document.getElementById('dashboard-tab')){
            document.getElementById('dashboard-tab').click();
        }
    }

    // handleUpcomingClick() {
    //     if(document.getElementById('dashboard-tab')){
    //         document.getElementById('dashboard-tab').click()
    //         this.getUpcomingJobs();
    //     }
    // }
    // handleJobPostingClick() {
    //     if(document.getElementById('dashboard-tab')){
    //         document.getElementById('dashboard-tab').click()
    //         this.getJobPosting();
    //     }
    // }
    // handleRunningJobsClick() {
    //     if(document.getElementById('dashboard-tab')){
    //         document.getElementById('dashboard-tab').click()
    //         this.getRunningJobs();
    //     }
    // }
    // handleCompletedJobsClick() {
    //     if(document.getElementById('dashboard-tab')){
    //         document.getElementById('dashboard-tab').click()
    //         this.getCompletedJobs();
    //     }
    // }
    
    componentDidMount() {
        //this._connect_socket();
        this._isMounted = true;
        if(localStorage.getItem('api_token') !== ""){
            // Check if the user is using an iOS device
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            // Check if the user is using Safari
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);            
            const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
            .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
            // Check if the last visited page was the login page
            // let source = this.props.location.state?.source;
            // if (isIOS && isSafari && iOSVersion >= 17 && source === "login") {
            if (isIOS && isSafari && iOSVersion >= 17) {
                this.GetLocation();
                setTimeout(() => {
                    this.getProfileDetails();
                }, 100)
                setTimeout(() => {
                    this.getUpcomingJobs();
                }, 300)
                // setTimeout(() => {
                //     this.getJobPosting();
                // }, 550)
                setTimeout(() => {
                    this.getCompletedJobs();
                }, 750)
                setTimeout(() => {
                    this.getRunningJobs();
                }, 550)
            } else{
                this.GetLocation();
                this.getProfileDetails();
                this.getJobPosting();
                this.getUpcomingJobs();
                this.getRunningJobs();
                this.getCompletedJobs();
            }
        }
    }
  
    componentDidUpdate(preProps,PreState){
        this._isMounted = true;
        if(this._isMounted){
            if(PreState.location.lat != this.state.location.lat ||  PreState.location.lng != this.state.location.lng){
                setTimeout(() => {
                    this.getJobPosting();
                }, 550)
                // this.getJobPosting();
            }else{
                //check whether site has been given location access in browser with navigator api ?
                if (navigator.geolocation) {
                    navigator.permissions
                      .query({ name: "geolocation" })
                      .then((result) => {
                        if (result.state === "granted") {
                          //If granted then you can directly call your function here
                        } else if (result.state === "prompt") {
                            if (this.state.counter === 0) {    
                                toast("Please allow location permission to see new job posts in the app.", { type: "warning" })
                                this.setState({counter:1})
                              }
                        } else if (result.state === "denied") {
                          //If denied then show instructions to enable location
                          if (this.state.counter === 0) {    
                            toast("Please allow location permission to see new job posts in the app.", { type: "error" })
                            this.setState({counter:1})
                          }
                        }
                    })
                }
                // if(counter===0 && (this.state.location.lat==="" || this.state.location.lat==="")){
                //     toast("Please allow location permission to see new job posts in app.", { type: "error" })
                //     counter++
                // }
            }

            if(PreState.Distance  != this.state.Distance){
                setTimeout(() => {
                    this.getJobPosting();
                }, 300)
            }else{
                //check whether site has been given location access in browser with navigator api ?
                if (navigator.geolocation) {
                    navigator.permissions
                      .query({ name: "geolocation" })
                      .then((result) => {
                        if (result.state === "granted") {
                          //If granted then you can directly call your function here
                        } else if (result.state === "prompt") {
                            if (this.state.counter === 0) {    
                                toast("Please allow location permission to see new job posts in the app.", { type: "warning" })
                                this.setState({counter:1})
                              }
                        } else if (result.state === "denied") {
                          //If denied then show instructions to enable location
                          if (this.state.counter === 0) {    
                            toast("Please allow location permission to see new job posts in the app.", { type: "error" })
                            this.setState({counter:1})
                          }
                        }
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted=false;
    }

    // A function to listen for server-sent events
    listenForSSE = () => {
        let retryCount = 0;
        let catchRetryCount = 0;
        const maxRetries = 4;
        const user = JSON.parse(localStorage.getItem('user'));
        const uuid = user.user_id;
        let connectSSE = async () => {
            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            controller = abortController;
            const abortSignal = abortController.signal
            // If there is an ongoing SSE connection, cancel it
            if(ongoingSSEConnections.length > 0){
                // console.log('inside if ongoingLength: ',ongoingSSEConnections);
                ongoingSSEConnections.forEach(existingController => {
                    // console.log('Aborting SSE connection:', existingController);
                    existingController.abort();
                });
                // Clear the array after aborting all connections
                ongoingSSEConnections = [];
            }
        try {
            if(this.state.authHeaders!=="" && localStorage.getItem('api_token')!=="" && localStorage.getItem("isloggedin") === 'true' && isConnected === false){
                if(isConnected === false){                
                    const headers = {
                        ...this.state.authHeaders,
                        Accept: "text/event-stream",
                        id: uuid,
                    };
                const token = TokenService.getLocalAccessToken();
                if (token) {
                    headers.Authorization = `Bearer ${token}`;
                }
                eventSource =  await fetchEventSource(`${process.env.REACT_APP_API_SSE_BASE_URL}`, {
                withCredentials: true,
                openWhenHidden: true,
                signal: abortSignal,
                method: 'POST',
                headers,
                onopen : (response) => {
                    if ((response.status >= 200 && response.status <= 299) || response.status === 304) {
                     
                        if(isConnected === true) {
                            // isConnected = false;
                            // console.log('aborting block');
                            abortController.abort();
                            // console.log('isConnected when true ',ongoingSSEConnections, "isconnected ",isConnected);
                            ongoingSSEConnections.push(abortController);

                        }else {
                            // console.log("Connected.");
                            // console.log('isConnected in else block ',isConnected);
                            isConnected = true;
                            // console.log('isConnected in else block after true value ',isConnected);
                            // console.log('Inside else block conn added');
                            ongoingSSEConnections.push(abortController);
                            // console.log('ongoing else sses ',ongoingSSEConnections);
                        }
                        console.log('Connected Successfully');
                        return; // everything's good
                    } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                        // client-side errors are usually non-retriable:
                        isConnected = false;
                        console.log("Client side error: ", response);
                      } else {
                        isConnected = false;
                        console.log("Server side error: ", response);
                    }
                },
                onmessage : (msg) => {
                    // handle incoming message
                    // console.log('new event: ', msg.event);
                    //let state = this.state.bind(this);
                    const newPosts = JSON.parse(msg.data);
                    const bidReject = JSON.parse(msg.data);
                    const jobEnded = JSON.parse(msg.data);
                    const jobCancelled = JSON.parse(msg.data);
                    const delay = 4000;
                    // New job update state logic here
                    if(localStorage.getItem('api_token')!==""){
                        if(msg.event==="post"){
                                if (newPosts !== '' && newPosts !== undefined && newPosts?.job_id !== undefined) {
                                this.state.jobQueue.push(() => {    
                                toast("New incoming job post", {
                                    position: "top-right",
                                    autoClose: 4000,
                                    draggable: false,
                                    pauseOnHover: false,
                                    progress: undefined,
                                    hideProgressBar: false,
                                });
                                    
                                })
                            } 
                        }
                        if(msg.event==="bidAccepted"){  
                            if (bidReject !== '' && bidReject !== undefined) {
                                toast(JSON.parse(msg.data), {
                                        position: "top-right",
                                        autoClose: 4000,
                                        draggable: false,
                                        pauseOnHover: false,
                                        progress: undefined,
                                        hideProgressBar: false,
                                    });
                                    shouldUpdate = true;
                                    if(shouldUpdate){
                                        this.handleLogoClick()
                                    }
                                }
                        }
                        else if(msg.event==="bidReject"){  
                            if (bidReject !== '' && bidReject !== undefined) {
                                toast(JSON.parse(msg.data), {
                                    position: "top-right",
                                    autoClose: 4000,
                                    draggable: false,
                                    pauseOnHover: false,
                                    progress: undefined,
                                    hideProgressBar: false,
                                });
                                shouldUpdate = true;
                                if(shouldUpdate){
                                    this.handleLogoClick()
                                }
                            }
                        }
                        else if(msg.event==="jobEnded"){  
                            if (jobEnded !== '' && jobEnded !== undefined) {
                                toast(jobEnded, {
                                    position: "top-right",
                                    autoClose: 4000,
                                    draggable: false,
                                    pauseOnHover: false,
                                    progress: undefined,
                                    hideProgressBar: false,
                                });
                                shouldUpdate = true;
                                if(shouldUpdate){
                                    this.handleLogoClick()
                                }
                            }
                        }
                        else if(msg.event==="jobCancel"){  
                            if (jobCancelled !== '' && jobCancelled !== undefined) {
                                toast(jobCancelled, {
                                    position: "top-right",
                                    autoClose: 4000,
                                    draggable: false,
                                    pauseOnHover: false,
                                    progress: undefined,
                                    hideProgressBar: false,
                                });
                                shouldUpdate = true;
                                if(shouldUpdate){
                                    this.handleLogoClick()
                                }
                            }
                        }
                        if (msg.event === 'FatalError') {
                            // console.log('Fatal Error:', msg.data);
                            isConnected = false;
                            eventSource.close(); // close the connection
                        }
                        // check if there are jobs in the queue and if the previous job has completed
                        if (this.state.jobQueue.length > 0 && !isProcessingJob) {
                            // set processing flag to true and dequeue job from the queue
                            isProcessingJob = true;
                            const job = this.state.jobQueue.shift();

                            // call the job after the specified delay
                            setTimeout(() => {
                            job();
                            // set processing flag to false and check if there are more jobs in the queue
                            isProcessingJob = false;
                            if (this.state.jobQueue.length > 0) {
                                // if there are, call the next job after the specified delay
                                setTimeout(() => {
                                const nextJob = this.state.jobQueue.shift();
                                nextJob();
                                isProcessingJob = false;
                                }, delay);
                            }
                            shouldUpdate = true;
                            if(shouldUpdate){
                                // console.log('Inside else If');
                                this.handleLogoClick()
                            }
                            }, delay);
                        }
                    } else {
                        if (controller) {
                            controller.abort();
                            controller = null; // Reset the current AbortController
                        }
                        if(ongoingSSEConnections.length > 0){
                            console.log('inside if ongoingLength: ',ongoingSSEConnections);
                            ongoingSSEConnections.forEach(existingController => {
                                // console.log('Aborting SSE connection:', existingController);
                                existingController.abort();
                            });
                            // Clear the array after aborting all connections
                            ongoingSSEConnections = [];
                        }
                    }
                },
                onclose : (evt) => {
                    // if the server closes the connection unexpectedly, retry:
                    console.log('Connection closed unexpectedly, retrying in 5 seconds');
                    if (evt.target.readyState === EventSource.CLOSED) {
                        isConnected = false;
                        // abortController.abort();
                        console.log('Connection was closed by the server');
                    } else if (retryCount < maxRetries) {
                        isConnected = false;
                        console.log('Connection lost, retrying...');
                        setTimeout(()=>{
                            retryCount++;
                            if(this.state.authHeaders!=="" && localStorage.getItem('api_token')!=="" && localStorage.getItem('api_token')!==null){
                             // retry connection after 10 seconds
                                connectSSE();
                            }    
                        }, 5000*(retryCount*2)); // retry after 5 seconds
                    } else {
                        console.log(`Connection lost after ${maxRetries} attempts, giving up. OnClose`);
                        isConnected = true;
                        // abortController.abort();
                    }
                },
                onerror : (err) => {
                    console.error('An error occurred:', err);
                    console.log('Retrying after 10 seconds');
                    if (retryCount < maxRetries) {
                        console.log(`Retrying after ${retryCount} seconds`);
                        // isConnected = false;
                        setTimeout(() => {
                            console.log('retried: ',retryCount);
                            if (retryCount < maxRetries) {
                                if(this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined && localStorage.getItem('api_token')!==""&& localStorage.getItem('api_token')!==null){
                                    console.log('inside err if block');
                                    connectSSE(); // retry connection after 10 seconds
                                }
                                retryCount++;
                            }
                            return null;
                        }, 10000*(retryCount*2));
                    }else{
                        console.log(`Connection lost after ${maxRetries} attempts, giving up.OnErr`);
                        isConnected = true;
                        // abortController.abort();
                        throw new Error("Connection failed after maximum retries.");
                    }
                },
            });
        }
    } 
    } catch (err) {
        console.error('An error occurred:', err);
        console.log('Retrying in 10 seconds c-block');
        isConnected = false;
        
        if(!isConnected && catchRetryCount < maxRetries){
            setTimeout(() => {
                    console.log(`Retrying in ${catchRetryCount}`);
                    catchRetryCount++;
                if(!isConnected && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined  && localStorage.getItem('api_token')!==""){
                    connectSSE(); // retry connection after 10 seconds
                }
            }, 10000*(retryCount*2));
        }
      }
    }
        if(this.state.authHeaders!==""  && localStorage.getItem('api_token')!==""){
            // Check if there is a current AbortController and abort ongoing requests
            if (controller) {
                controller.abort();
                controller = null; // Reset the current AbortController
            }
            // Start a new SSE connection
            connectSSE();
        }
    };

    getRunningJobs = () => {
        // Check if the user is using an iOS device
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
        // let source = this.props.location.state?.source;
        if (isIOS && isSafari && iOSVersion >= 17) {
            // console.log('Is iOS:', isIOS);
            // console.log('Is Safari:', isSafari);
            const token = localStorage.getItem('api_token');
            const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/sitter/ongoing-jobs`;

            // Include your headers in the options object
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            };

            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            const abortSignal = abortController.signal;

            // Set a timeout for the request (in milliseconds)
            const timeout = 2500;

            // Use setTimeout to trigger the abort after the specified timeout
            const timeoutId = setTimeout(() => {
                abortController.abort();
            }, timeout);

            // Make the fetch request with the specified method (GET)
            setTimeout(() => {
                fetch(url, {
                    method: 'GET',
                    headers: headers,
                    signal: abortSignal
                    // You can include additional options if needed
                })
                .then((res) => {
                    clearTimeout(timeoutId);
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.json(); // Assuming the response is in JSON format
                })
                .then((res) => {
                    // Handle the data
                    this.setState({RunningJobs:res.data,loading:false});
                    // console.log('iOS Version: ifc grj');
                }).catch((error) => {
                    this.setState({loading:false});
                    // toast(error.message, { type: "error" });

                    //Retry the request
                    this.getRunningJobs();
                });
            }, 700);
        } else {
            instance.get('sitter/ongoing-jobs').then((res) => {
                this.setState({RunningJobs:res.data.data,loading:false});
                // console.log('getRunningJobs',res.data.data);
            }).catch((error) => {
                // console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            })
        }
    }
    
    getUpcomingJobs = () => {
        // Check if the user is using an iOS device
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;     
        if (isIOS && isSafari && iOSVersion >= 17) {
            // console.log('Is iOS:', isIOS);
            // console.log('Is Safari ifc gpd:', isSafari);
            const token = localStorage.getItem('api_token');
            const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/sitter/upcoming-jobs`;

            // Include your headers in the options object
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            };

            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            const abortSignal = abortController.signal;

            // Set a timeout for the request (in milliseconds)
            const timeout = 2000;

            // Use setTimeout to trigger the abort after the specified timeout
            const timeoutId = setTimeout(() => {
                abortController.abort();
            }, timeout);

            // Make the fetch request with the specified method (GET)
            setTimeout(() => {
                fetch(url, {
                    method: 'GET',
                    headers: headers,
                    signal: abortSignal
                    // You can include additional options if needed
                })
                .then((res) => {
                    clearTimeout(timeoutId);
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.json(); // Assuming the response is in JSON format
                })
                .then((res) => {
                    // Handle the data
                    this.setState({UpCommingJobs:res.data,loading:false});
                    // console.log('iOS Version: ifc guj');
                }).catch((error) => {
                    this.setState({loading:false});
                    // toast(error.message, { type: "error" });

                    //Retry the request
                    this.getUpcomingJobs();
                });
            }, 500);
        } else {
            instance.get('sitter/upcoming-jobs').then((res) => {
                this.setState({UpCommingJobs:res.data.data,loading:false});
                // console.log(res.data.data);
            }).catch((error) => {
                console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            })
        }
    }

    HandleTimmerDone  = () => {
        this.setState({timer:0,CanBid:true})
        //console.log('Done');
    }

    HandleAddReview = (e) => {
        // this.setState({:true})
        let data = {job_id:this.state.CancelId,review:this.state.ReviewText,rating:this.state.rating.toString()};

        this.HandleModalClose(e,'ReviewModal')
        instance.post('sitter/add-review',data).then((res) => {
            
            this.getCompletedJobs();
            toast('Review Added Successfully',{type:'success'});
            this.setState({rating:5,ReviewText:''});

        }).catch((error) => {
            this.setState({rating:5,ReviewText:''});        
            console.error(error) 
            // this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })
        
    }

    HandleJobOtp =  (e) => {
        this.setState({startjobotp:e.target.value});
    }

    HandleStartJob = (e) => {

        this.setState({loading:true})
        if(this.state.startjobotp){
            instance.post('sitter/start-job',{job_id:this.state.CancelId,otp:this.state.startjobotp}).then((res) => {
                this.setState({UpCommingJobs:res.data.data,loading:false});
                this.getUpcomingJobs();
                setTimeout(() => {
                    this.getRunningJobs();
                }, 250);
                this.setState({startjobotp:''});
                this.HandleModalClose(e,'StartJob')
                toast('Successfully clocked in',{type:'success'});
            }).catch((error) => {
                 console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            })
         }else{
            this.setState({startjobotp:''});
            toast('Please enter otp.', { type: "error" });
        }
    }
    
    handleAllowStartJob = (e,start_date,start_time,job_id) => {
        let currentTime = moment()
        // console.log('moment to date current time: ',currentTime);
        let jobStartTime = moment(`${start_date} ${start_time}`)
        // console.log('jobStart time: ',jobStartTime);
        let allowModalOpen
        let afterDurationMinutes = moment.duration(currentTime.diff(jobStartTime)).asMinutes().toFixed(2)
        let beforeDurationMinutes = moment.duration(jobStartTime.diff(currentTime)).asMinutes().toFixed(2)
        // console.log('check for before 20 minutes ',beforeDurationMinutes);
        // console.log('check for after 60 minutes ', afterDurationMinutes);
        // check for not allowing modal after 60 minutes from job_startTime
        if(afterDurationMinutes >= 60){
            // console.log(' inside check for after 60 minutes ', afterDurationMinutes);
            allowModalOpen = false
        }
        
        // check for allowing modal before 20 minutes from job_startTime to 60 minutes after that
        if(beforeDurationMinutes <= 20 && beforeDurationMinutes >= -60){
            // console.log('check inside for before 20 minutes ',beforeDurationMinutes);
            allowModalOpen = true
        }

        if(allowModalOpen){
            this.HandleModalOpen(e,'StartJob',job_id)
        }

    }

    HandleModalOpen = (e,modalname,id='') => {
        if(modalname === 'StartJob'){
            // console.log('job start modal active');
        }
        this.setState({[modalname]:true,CancelId:id})
    }

    HandleModalClose = (e,modalname,id='') => {
        this.setState({[modalname]:false,CancelId:id});
    }
    HandleCloseJob = (e) => {
        //console.log('Close job');
        this.setState({loading:true,CancelModal:false});
        instance.post('/parent/job/delete',{job_id:this.state.CancelId}).then((res) => {
            this.setState({loading:false,CancelId:''});
            setTimeout(() => {
                this.getJobPosting();
            }, 300)
            toast("Job has been cancelled successfully",{type: "success"});
        }).catch((error) => {
            this.setState({loading:false,CancelId:''});
            // toast(error.response.data.message, { type: "error" });
        })
    }

    GetLocation = () => {
         var pos;
         var OurThis = this;
        if (navigator.geolocation) {
             navigator.geolocation.getCurrentPosition(function(position) {
               pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            OurThis.setState({location:pos});
        });
        }else{
            toast('Unable to find location | Please search manually ', { type: "error" });
        }
      
    }
    
    getCompletedJobs = () => {
        // console.log('getCompletedJobs');
        // Check if the user is using an iOS device
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        // Check if the user is using Safari
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
        
        if (isIOS && isSafari && iOSVersion >= 17) {
                // console.log('Is iOS:', isIOS);
                // console.log('Is Safari:', isSafari);
                // console.log('iOS Version gcj:', iOSVersion);
                const token = localStorage.getItem('api_token');
                const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/sitter/closed-jobs`;
                // Include your headers in the options object
                const headers = {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                };

                // Create an AbortController and signal for aborting the request
                const abortController = new AbortController();
                const abortSignal = abortController.signal;

                // Set a timeout for the request (in milliseconds)
                const timeout = 2500;

                // Use setTimeout to trigger the abort after the specified timeout
                const timeoutId = setTimeout(() => {
                    abortController.abort();
                }, timeout);

                // Make the fetch request with the specified method (GET)
            setTimeout(() => {
                fetch(url, {
                    method: 'GET',
                    headers: headers,
                    signal: abortSignal
                    // You can include additional options if needed
                })
                .then((res) => {
                    clearTimeout(timeoutId);
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.json(); // Assuming the response is in JSON format
                })
                .then((res) => {
                    // Handle the data
                    this.setState({CompletedJobs:res.data,loading:false});
                    // console.log('iOS Version: gcj');
                }).catch((error) => {
                    this.setState({loading:false});
                    // toast(error, { type: "error" });
                    //Retry the request
                    this.getCompletedJobs();
                });
            }, 850);
        } else{
            instance.get('/sitter/closed-jobs').then((res) => {
                this.setState({CompletedJobs:res.data.data,loading:false});
                // console.log('getcompletedobs',res.data.data);

            }).catch((error) => {
                console.error(error)
                this.setState({loading:false});
                toast(error.message, { type: "error" });
            })
        }
}



    getJobPosting = () => {

        let  data = this.state.location;
        if(data.lat == '' || data.lng == ''){
            
        }else{
            // Check if the user is using an iOS device
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

            // Check if the user is using Safari
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
            .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
            
            if (isIOS && isSafari && iOSVersion >= 17) {
                    // console.log('Is iOS:', isIOS);
                    // console.log('Is Safari:', isSafari);
                    // console.log('iOS Version ifc gjp:', iOSVersion);
                    const token = localStorage.getItem('api_token');
                    const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/sitter/job-postings`;

                    // Include your headers in the options object
                    const headers = {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    };

                    // Create an AbortController and signal for aborting the request
                    const abortController = new AbortController();
                    const abortSignal = abortController.signal;

                    // Set a timeout for the request (in milliseconds)
                    const timeout = 2500;

                    // Use setTimeout to trigger the abort after the specified timeout
                    const timeoutId = setTimeout(() => {
                        abortController.abort();
                    }, timeout);
                    // Make the fetch request with the specified method (GET)
                setTimeout(() => {
                    fetch(url, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data),
                        signal: abortSignal
                        // You can include additional options if needed
                    })
                    .then((res) => {
                        clearTimeout(timeoutId);
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        if(isConnected === false && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined){
                            // Check for a successful status code (200 to 299, or 304)
                            if (res.status >= 200 && res.status <= 299) {
                                if(isConnected === false){
                                    // console.log('calling sse ',isConnected);
                                    setTimeout(() => {
                                        this.listenForSSE();
                                    }, 700);
                                }
                            }
                            // isConnected = true;
                        }
                        return res.json(); // Assuming the response is in JSON format
                    })
                    .then((res) => {
                        // Handle the data
                        SSETimerInMinnets = moment(res.server_time,).diff(moment(res.last_bid_time),'minute');
                        SSETimerInMiliSeconds = moment(res.server_time,).diff(moment(res.last_bid_time),'milliseconds');
                        let  TimerInMinnets = moment(res.server_time,).diff(moment(res.last_bid_time),'minute');
                        let  TimerInMiliSeconds = moment(res.server_time,).diff(moment(res.last_bid_time),'milliseconds');
                        let  timmer = TimerInMiliSeconds;
                        SSEtimmer = TimerInMiliSeconds;
                        // console.log(timmer)
                        let bid_status = res.bid_status
                        let Canbid ;
                        if((TimerInMinnets < TimerLimit) && (bid_status !== "Rejected" && bid_status !== "Cancelled")){
                            timmer = (TimerLimit * 60*1000 ) - TimerInMiliSeconds  ;
                            // timmer = 10000;
                            Canbid=false;
                        }else{
                            timmer = 0;
                            Canbid=true;
                        }
                        // if(this._isMounted){
                            this.setState({ GetPosts: res.data, loading: false, last_bid_time: res.last_bid_time, server_time: res.server_time, timer: timmer, CanBid: Canbid });        
                        // }
                        // if(isConnected === false && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined){
                        //     // Check for a successful status code (200 to 299, or 304)
                        //     if (res.status >= 200 && res.status <= 299) {
                        //         this.listenForSSE();
                        //     }
                        //     // isConnected = true;
                        // }
                    })
                    .catch((error) => {
                        this.setState({loading:false});
                        // toast(error.message, { type: "error" });
                        //Retry the request
                        this.getJobPosting();
                    });
                }, 700);
            } else{
                instance.post('/sitter/job-postings',data).then((res) => {
                    //console.log(res.data.data);
                    SSETimerInMinnets = moment(res.data.server_time,).diff(moment(res.data.last_bid_time),'minute');
                    SSETimerInMiliSeconds = moment(res.data.server_time,).diff(moment(res.data.last_bid_time),'milliseconds');
                    let  TimerInMinnets = moment(res.data.server_time,).diff(moment(res.data.last_bid_time),'minute');
                    let  TimerInMiliSeconds = moment(res.data.server_time,).diff(moment(res.data.last_bid_time),'milliseconds');
                    let  timmer = TimerInMiliSeconds;
                    SSEtimmer = TimerInMiliSeconds;
                    // console.log(timmer)
                    let bid_status = res.data.bid_status
                    let Canbid ;
                    if((TimerInMinnets < TimerLimit) && (bid_status !== "Rejected" && bid_status !== "Cancelled")){
                        timmer = (TimerLimit * 60*1000 ) - TimerInMiliSeconds  ;
                        // timmer = 10000;
                        Canbid=false;
                    }else{
                        timmer = 0;
                        Canbid=true;
                    }
                    // if(this._isMounted){
                        this.setState({ GetPosts: res.data.data, loading: false, last_bid_time: res.data.last_bid_time, server_time: res.data.server_time, timer: timmer, CanBid: Canbid });        
                    // }
                    if(!isConnected && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined){
                        // if(isConnected !== true){
                            this.listenForSSE();
                        // }
                    }
                }).catch((error) => {
                    // console.error(error)
                    this.setState({loading:false});
                    toast(error.response.data.message, { type: "error" });
                })
            }
        }
    }

    getProfileDetails = () => {
         // Check if the user is using an iOS device
         const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

         // Check if the user is using Safari
         const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
         
         // Log the information
        //  console.log('Is iOS:', isIOS);
        //  console.log('Is Safari:', isSafari);
         
         const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
         .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
         // You can also get the iOS version
        //  console.log('iOS Version:', iOSVersion);
         
         // Check if the last visited page was the login page
        //  let source = this.props.location.state?.source;
         // if (isIOS && isSafari && iOSVersion >= 17 && source === "login") {
         if (isIOS && isSafari && iOSVersion >= 17) {
            // console.log('Is iOS:', isIOS);
            // console.log('Is Safari gpd:', isSafari);
            // console.log('iOS Version ifc gpd:', iOSVersion);
            const token = localStorage.getItem('api_token');
            const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/get-sitter-profile`;

            // Include your headers in the options object
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            };

            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            const abortSignal = abortController.signal;

            // Set a timeout for the request (in milliseconds)
            const timeout = 2000;

            // Use setTimeout to trigger the abort after the specified timeout
            const timeoutId = setTimeout(() => {
                abortController.abort();
            }, timeout);

            // Make the fetch request with the specified method (GET)
            fetch(url, {
                method: 'GET',
                headers: headers,
                signal: abortSignal
                // You can include additional options if needed
            })
            .then((res) => {
                clearTimeout(timeoutId);
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json(); // Assuming the response is in JSON format
            })
            .then((data) => {
            // Handle the data
                this.setState({
                    wallet_balance: data.data.wallet_balance,
                    Onboarding: data.data.onboarding === true ? false : true,
                });
                ProfileService.setUserProfile(data);
                // console.log(`iOS-${iOSVersion} ifc gpd`);
            })
            .catch((error) => {
                // toast(error.message, { type: 'error' });
                //Retry the request
                this.getProfileDetails();
            });
         } else{

            instance.get('/get-sitter-profile').then((res) => {
                this.setState({wallet_balance:res.data.data.wallet_balance})
                //console.log('wallet_balance',res.data.data.wallet_balance)
                ProfileService.setUserProfile(res.data.data);
                // ios-17.0 and safari-17 version bug patch
                // Check if the user is using an iOS device
                // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

                // Check if the user is using Safari
                // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                
                // Log the information
                
                // const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
                // .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
                // You can also get the iOS version
                
                // Check if the last visited page was the login page
                // let source = this.props.location.state?.source;
                // console.log('Source:', source); // 'login' if coming from login
                // if (isIOS && isSafari && iOSVersion >= 17) {
                //     console.log('Is iOS:', isIOS);
                //     console.log('Is Safari:', isSafari);
                //     console.log('iOS Version:', iOSVersion);
                //     source = ""
                //     console.log('Source value after change:', source);
                //     // this.handleUpcomingClick();
                //     setTimeout(() => {
                //         this.getJobPosting();
                //     }, 400);
                // }
                
                // if (isIOS && isSafari && iOSVersion >= 17 && source === "login") {
                //     console.log('Is iOS:', isIOS);
                //     console.log('Is Safari:', isSafari);
                //     console.log('iOS Version:', iOSVersion);
                //     source = ""
                //     console.log('Source value after change:', source);
                //     setTimeout(() => {
                //         window.location.reload(true);
                //         console.log(`iOS-${iOSVersion}`);
                //     }, 500);
                // }
                
                this.setState({Onboarding:(res.data.data.onboarding == true) ? false : true })

            }).catch((error) => {
                toast(error.response.data.message, { type: "error" });
            })
        }
    }

    getOnboardinglink = () => {
        // let Link
        this.setState({linkloading:true});
        var win =  window.open('about:blank', '_blank');
        instance.get('/get-onboarding-url-web').then((res) => {
        // console.log('Onboarding Link', res.data.data.url);
        this.setState({linkloading:false});

            win.location.href = res.data.data.url;
            win.focus();
           
       }).catch((error) => {
        this.setState({linkloading:false});

           toast(error.response.data.message, { type: "error" });

    })  
    }

    logout = () => {
        if(ongoingSSEConnections){
            ongoingSSEConnections.forEach(existingController => {
                // console.log('Aborting SSE connection:', existingController);
                existingController.abort();
            });
            controller.abort();
            controller = null;
            controller = new AbortController();
        }
        this.setState({authHeaders:null}); 
        localStorage.clear();
        isConnected = false;
        counter=0;
        this.props.history.push('/');
        toast('Logged out successfully',{ type:'success' });            
    }

    render() {
        //console.log('isconnected inside render ',isConnected);
        let activities = [
            /*{name:'Ongoing jobs',count:this.state.RunningJobs.length},*/
            {name:'All job posts',count:this.state.GetPosts.length},
            {name:'Current jobs',count:this.state.RunningJobs.length},
            {name:'Upcoming jobs',count:this.state.UpCommingJobs.length},
            {name:'Completed jobs',count:this.state.CompletedJobs.length}
        ]
        if(shouldUpdate){
            shouldUpdate=false;
            this.getJobPosting();
            this.getUpcomingJobs();
            this.getCompletedJobs();
            this.getRunningJobs();
            this.getProfileDetails();
        }

        //job posts sorted as per their start date
        let sortedJobs = this.state.GetPosts.sort((a, b) => Date.parse(new Date(a.start_date.split("/").reverse().join("-"))) - Date.parse(new Date(b.start_date.split("/").reverse().join("-"))));
        let PostsAfterFilter = sortedJobs.filter((value) => {
            // let postsAfterSorting;          
            if(this.state.Distance == 0){
                return value;
            }
            if(Math.floor(this.state.Distance) >= value.distance){
                return value;
            }
        });     

        return (
            <>
    <section className={`dashboard ${this.state.loading ?'loader' : ''} `}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-3 sidebar">
                <SidebarOne  type="sitter" payments={<div className="earnings">
                    <small className="d-block">Total earnings</small>
                    <span>$ {this.state.wallet_balance.toFixed(2)}</span>
                    <button disabled={(this.state.wallet_balance == 0)} onClick={(e) => { this.HandleModalOpen(e,'withdaw') }} className="withdraw-button blue-grad">Withdraw</button>
            </div>}  button={<>
                <Link to='/sitter/profile' className="view-profile-button" title='Profile' style={{fontSize:"18px"}}><i className="fa fa-user" style={{fontSize:"13px"}}></i></Link>
                <Link to='/' replace onClick={this.logout} className="view-profile-button logoutbtn" title='Logout' style={{marginLeft:"5px", fontSize:"18px"}}><i className="fa fa-sign-out-alt" style={{fontSize:"13px"}}></i></Link>
            </>} activities={activities} ></SidebarOne>
            </div>
                <div className="col-12 col-lg-9 main-dashboard-content">
                    <div onClick={this.HandleCloseBanner} hidden={!this.state.BannerOpen} className="top-poster">
                        <img src={ParentPoster} alt=""/>
                                <a><i className="fa fa-times"></i></a>
                    </div>
                    <div className="col-12 navbar">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                                <button  onClick={(event) => this.HandleFirstTab(event,4)} 
                                className={`nav-link  home-tab ${(this.state.FirstTabs == 4) ? ' active' : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="false"><i className="home"></i> <span>Home</span></button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button  onClick={(event) => this.HandleFirstTab(event,1)} 
                                className={`nav-link  dashboard-tab ${(this.state.FirstTabs == 1) ? ' active' : ''}`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="true"><i className="dashboard"></i> <span>Dashboard</span></button>
                            </li>
                            <li className="nav-item " role="presentation">
                                <button   className={`nav-link  jobs-tab ${(this.state.FirstTabs == 2) ? ' active' : ''}`} onClick={(event) => this.HandleFirstTab(event,2)} id="jobs-tab" data-bs-toggle="tab" data-bs-target="#jobs" type="button" role="tab" aria-controls="jobs" aria-selected="false"><i className="breifcase"></i><span>Jobs</span></button>
                            </li>
                            <li className="nav-item " role="presentation">
                                {/* <button className={`nav-link  chats-tab ${(this.state.FirstTabs == 3) ? ' active' : ''}`} onClick={(event) => this.HandleFirstTab(event,3)} id="chats-tab" data-bs-toggle="tab" data-bs-target="#chats" type="button" role="tab" aria-controls="chats" aria-selected="false"><i className="chat"></i><span>Chats</span></button> */}
                            </li>
                        </ul>
                    </div>
                     <div className="col-12 main-content">
                       <div className="tab-content" id="myTabContent"> 
                        <div className={`tab-pane fade jobs-content ${(this.state.FirstTabs == 4) ? 'show active' : ''}`} id="home " role="tabpanel" aria-labelledby="home-tab">
                             {/* Changing view inside sitter dashboard from here*/}

                             {/* <ul className="nav nav-tabs job-tabbar" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${(this.state.SecondTabs == 1) ? 'active' : ''}`} onClick={(e) => this.HandleSecondTab(e,1)} id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="true">Upcoming Jobs</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${(this.state.SecondTabs == 3) ? 'active' : ''}`} id="active-posting-tab" onClick={(e) => this.HandleSecondTab(e,3)} data-bs-toggle="tab" data-bs-target="#active-posting" type="button" role="tab" aria-controls="active-posting" aria-selected="false">Completed Jobs</button>
                                    </li>
                                </ul> */}
                                <div className="col-12">
                                <ul className="navi nav-tabs job-tabbar" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${(this.state.SecondTabs == 1) ? 'active' : ''}`} onClick={(e) => this.HandleSecondTab(e,1)} id="current-posting-tab"  data-bs-toggle="tab" data-bs-target="#current-posting" type="button" role="tab" aria-controls="current-posting" aria-selected="false">Current Jobs</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${(this.state.SecondTabs == 3) ? 'active' : ''}`} onClick={(e) => this.HandleSecondTab(e,3)} id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="true">Upcoming Jobs</button>
                                    </li>
                                </ul>
                                </div>
                                {/* over here */}
                                    <div className="tab-content" id="myTabContent">
                                        <div className={`tab-pane fade  ${(this.state.SecondTabs == 1) ? 'show active' : ''}`}  id="current-posting" role="tabpanel" aria-labelledby="current-posting-tab">
                                            {/* <!-- List of jobs --> */}
                                            {(this.state.RunningJobs.length == 0) ? <div className="row justify-content-left">   
                                            <div className="col-12 col-md-9 empty-screen-placeholder image-container">   
                                                <img alt='no jobs img' src={(Emptyjobs)} />
                                                <div className="image-overlay"></div>
                                                <h5 className="text-grey pt-4">No current jobs at this time</h5>   
                                            </div>
                                            </div> : 
                                            <div className="row ongoing-jobs job-list">
                                                <div className="scrolling">
                                                {this.state.RunningJobs.map((value) => {
                                                    let jobPostStartDuration = value.job_startedAt;
                                                    let now = moment(new Date());
                                                    let duration = moment.duration(now.diff(jobPostStartDuration))
                                                    let minutes = Math.ceil(duration.asMinutes());
                                                    let jobStarted = moment.utc(jobPostStartDuration).subtract(4, 'hours').format('DD-MM-YYYY hh:mm:ss');
                                                return (
                                                <div key={value.job_id} className="col-12 col-lg-8 job-list-item">
                                                        <div className="card">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <span className="date"><i className="fa fa-calendar-week"></i>
                                                                    {moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}  </span>
                                                                    <br/>
                                                                    <span className="date"><i className="fa fa-clock"></i>
                                                                    {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}


                                                                    {/* <span className="date"><i className="fa fa-clock"></i>{value.start_time} - 
                                                                    {value.end_time}</span> */}
                                                                    </span>
                                                                </div>
                                                                
                                                            </div>
                                                            <span className="h5 sitter-name"> <div className="avatar-image"><img src={value.image} alt=""/></div> 
                                                            <h4 className="sitter-name">{value.first_name} {value.last_name}</h4>
                                                            </span>
                                                            <ul>
                                                                <li>
                                                                    <h5><i className="fa fa-circle text-blue"></i>Start : {moment(value.start_time,'hh:mm:ss').format('hh:mm A')}</h5>
                                                                </li>
                                                                <li><i className="line"></i></li>
                                                                {/* <li>
                                                                    <h5><i className="fa fa-circle text-pink"></i>End : {moment(value.end_time,'hh:mm:ss').format('hh:mm A')}</h5>
                                                                </li> */}
                                                                <li><h5 className="text-pink"><i className="fa fa-hourglass-start text-blue"></i>Sitting started at : {jobStarted}</h5></li>
                                                            </ul>
                                                            {/* <a className="chat-button"><i className="chat"></i>Chat</a> */}
                                                        </div>
                                                </div>);
                                                })}
                                                </div>
                                                    </div>}
                                                
                                        </div>

                               
                                    <div className={`tab-pane fade  ${(this.state.SecondTabs == 3) ? 'show active' : ''}`}  id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                                        {/* <!-- List of jobs --> */}   
                                        {(this.state.UpCommingJobs.length == 0) ? <div className="row justify-content-left">
                                        <div className="col-12 col-md-9 empty-screen-placeholder image-container">   
                                            <img alt='no upcoming jobs' src={(EmptyUpcomingJobs)} />
                                            <div className="image-overlay"></div>
                                            <h5 className="text-grey pt-4">You don't have any upcoming jobs</h5>   
                                        </div>
                                        </div> : 
                                        
                                        <div className="scrolling">
                                            {this.state.UpCommingJobs.map((value,index) => {
                                                return ( 
                                                <div key={value.job_id} className="col-12 col-lg-8 job-list-item">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span className="date"><i className="fa fa-calendar-week"></i>{moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}</span>
                                                            <br/>
                                                            <span className="date"><i className="fa fa-clock"></i> {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}</span>
                                                        </div>
                                                        <div className="col-6" >
                                                             <a type="button" onClick={(e) => this.handleAllowStartJob(e,value.start_date,value.start_time,value.job_id)} className="end-sitting float-end" data-bs-toggle="modal" data-bs-target="#add-tip">Start sitting <i className="toggle-on"></i></a>
                                                          </div>
                                                    </div>
                                                    <span className="h5 sitter-name"> <div className="avatar-image">
                                                        <img src={value.image} alt=""/></div> {value.first_name} {value.last_name}</span>
                                                    <small className="text-blue">Description</small>
                                                    <p>
                                                        {value.job_details}
                                                    </p>
                                                    <p>
                                                        {value.allergies}
                                                    </p>
                                                    {/* <a className="chat-button"><i className="chat"></i>Chat</a> */}
                                                    <br/>
                                                        <small className="alert alert-danger">You can clock in from 20 minutes before the start time  {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} up to 60 minutes after the start time</small>
                                                </div>
                                            </div>);
                                            })}
                                            </div>
                                            }
                                            </div>
                                                
                                            </div>
                                        </div>
                                <div className={`tab-pane fade dashboard-content ${(this.state.FirstTabs == 1) ? 'show active' : ''}`} id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                            <div className='scroller'>
                                {/* <!-- No jobs yet --> */}
                                <div className="row ongoing-jobs job-list">
                                                {/* {(this.state.InfoVideo == false) ? <></> : <>                                            */}
                                                 <div className="col-12 col-lg-8 video-card">
                                                <div className="card pink-grad text-center">
                                                    <h4 className="text-white">Get started with babysitting safety 
                                                    {/* <span  onClick={this.HandleCloseInfoVideo} className='fa fa-times pull-right' >     </span>*/} </h4>  
                                                    <div className="video">
                                                    <video ref="vidRef" controls width='100%' >
                                                         <source src={Introvideo} type="video/mp4" />
                                                         <source src={Introvideo} type="video/ogg"/>
                                                            Your browser does not support the video tag.
                                                      </video>
                                                         <i onClick={() => { this.refs.vidRef.play()}}  className="pause-button"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-8 job-list-item ">
                                            <div className="heading my-3 text-center">
                                            <h4 className="text-grey d-inline-block">
                                                {(this.state.timer == 0) ? '' :
                                               <>
                                                You can not apply to any job for  <i className="far fa-clock"></i>
                                               <Countdown className="text-pink"
                                                zeroPadTime={2}
                                                // now={moment(this.state.server_time).utc()._d}
                                                // onTick={() => {this.setState({timmer:this.state.timer+1})}}
                                                onComplete={(e) => this.HandleTimmerDone(e)}
                                                date={moment(this.state.server_time) + this.state.timer} /></> }
                                                 
                                               
                                            </h4>
                                             </div> 
                                                <div className="heading my-3">
                                                    <h3 className="text-grey d-inline-block">{PostsAfterFilter.length} jobs near you</h3>
                                                    <select onChange={this.HandleDistance} className="distance-select float-end d-inline-block" name="distance" id="distance">
                                                        <option value="0"> All Jobs</option>
                                                       
                                                        <option value="10"> with in 10 Miles</option>
                                                        <option value="5"> with in 5 Miles</option>
                                                        <option value="1"> with in 1 Miles</option>
                                                    </select> 
                                                    <div><span style={{paddingLeft: "2px"}}></span></div>
                                               <div className='card location'><small className='text-pink'>If location filter is not working, check the location permission settings on your device and ensure that location permission for browser and google maps is turned on. </small></div>
                                                </div>
                                                <div >
                                                {PostsAfterFilter.map((value, index) => {
                                                     this.state.setSelectedChild_id = typeof value.setSelectedChild_id ==="object"? this.setStatevalue.setSelectedChild_id : JSON.parse(value.setSelectedChild_id);
                                                     // this.state.setSelectedChild_id = value.setSelectedChild_id;
                                                     this.state.setSelectedChild_dob = typeof value.setSelectedChild_dob ==="object" ? value.setSelectedChild_dob : JSON.parse(value.setSelectedChild_dob);
                                                    let setSelectedChild_idCount = this.state.setSelectedChild_id.length;

                                                    //child DOB data for posts

                                                    let childDobData = this.state.setSelectedChild_dob.map((dob,index)=> {
                                                        let age = moment().diff(dob, 'years');
                                                            var agegroup,name;
                                                        
                                                            
                                                            if (age >= 0 && age < 1) {
                                                                agegroup = "0-12 months";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 1 && age < 2) {
                                                                agegroup = "1-2 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 2 && age < 3) {
                                                                agegroup = "2-3 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 3 && age < 4) {
                                                                agegroup = "3-4 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 4 && age < 5) {
                                                                agegroup = "4-5 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 5 && age < 6) {
                                                                agegroup = "5-6 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 6 && age < 7) {
                                                                agegroup = "6-7 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 7 && age < 8) {
                                                                agegroup = "7-8 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 8 && age < 9) {
                                                                agegroup = "8-9 yrs";
                                                                name = agegroup;
                                                            
                                                            } else if (age >= 9 && age < 10) {
                                                                agegroup = "9-10 yrs";
                                                                name = agegroup;
                                                            
                                                            } else {
                                                                agegroup = "0-12 months";
                                                                name = agegroup;
                                                        }   
                                                            return (<span key={index} className="text-black"> | {agegroup} | </span>);  
                                                    });
                                                
                                                    return (
                                                    
                                                    <div key={value.job_id} className="card mb-4">
                                                        <div style={{display:'contents'}}  onClick={(e)=> this.HandleRedirect(e,'/sitter/job/details',{job_id:value.job_id})}> 
                                                    <div className="row" >
                                                        <div className="col-12" >
                                                            <span className="date text-black">{setSelectedChild_idCount} children
                                                            {childDobData}                                                              
                                                         <span> </span>Pet(s): {(value.pets) ? value.pets_details : 'No'} </span>
                                                            <br/>
                                                                <span className="date">
                                                                    <i className="fa fa-calendar-week"></i>
                                                                    {moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}  
                                                                </span>
                                                        <br/>
                                                            <span className="date"><i className="fa fa-clock"></i>
                                                                {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="h5 sitter-name text-blue m-0 pb-3"><div className="avatar-image"><img src={value.image} alt=""/></div>
                                                    {`${value.first_name}  ${value.last_name}`}</div>
                                                    <span className="h5 sitter-name text-blue m-0 pb-1"></span>
                                                    {/* <span className="pb-2"><i className="fa fa-user pe-1 text-blue"> </i>
                                                    Religion : {value.religion},    
                                                    </span> */}
                                                    <span className="pb-2"><i className="fa fa-school pe-1 text-blue"> </i>
                                                    Location : {value.job_location}   
                                                    </span>
                                                    <p>
                                                    Description : {value.job_details}
                                                    </p>
                                                    </div>
                                                    {/* <a onClick={(e) => this.GotoUserChat(value)} className="chat-button">
                                                    <i className="chat"></i>Chat</a> */}
                                                </div>);
                                                })}
                                                </div>
                                                
                                            </div>
                                        </div>
                                        </div>
                                </div>

                                

                                {/* <!-- List of jobs --> */}

                                

                            
                            <div className={`tab-pane fade jobs-content ${(this.state.FirstTabs == 2) ? 'show active' : ''}`}  id="jobs" role="tabpanel" aria-labelledby="jobs-tab">
                                <div className="col-12">
                                <ul className="navi nav-tabs job-tabbar" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${(this.state.SecondTabs == 1) ? 'active' : ''}`} onClick={(e) => this.HandleSecondTab(e,1)} id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="true">Upcoming Jobs</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${(this.state.SecondTabs == 3) ? 'active' : ''}`} id="active-posting-tab" onClick={(e) => this.HandleSecondTab(e,3)} data-bs-toggle="tab" data-bs-target="#active-posting" type="button" role="tab" aria-controls="active-posting" aria-selected="false">Completed Jobs</button>
                                    </li>
                                </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className={`tab-pane fade  ${(this.state.SecondTabs == 1) ? 'show active' : ''}`}  id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                                        {/* <!-- List of jobs --> */}

                                        {(this.state.UpCommingJobs.length == 0) ? <div className="row justify-content-left">
                                    <div className="col-12 col-md-9 empty-screen-placeholder image-container">   
                                        <img alt='no upcoming jobs' src={(EmptyUpcomingJobs)} />
                                        <div className="image-overlay"></div>
                                        <h5 className="text-grey pt-4">You don't have any upcoming jobs</h5>   
                                    </div>
                                    </div> : 
                                        <div className="row ongoing-jobs job-list">
                                            <div className="scrolling">
                                            {this.state.UpCommingJobs.map((value) => {
                                                return ( 
                                                <div key={value.job_id} className="col-12 col-lg-8 job-list-item">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span className="date"><i className="fa fa-calendar-week"></i>{moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}</span><br/>
                                                            <span className="date"><i className="fa fa-clock"></i> {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}</span>
                                                        </div>
                                                        <div className="col-6" >
                                                             <a type="button" onClick={(e) => this.handleAllowStartJob(e,value.start_date,value.start_time,value.job_id)} className="end-sitting float-end" data-bstoggle="modal" data-bs-target="#add-tip">Start sitting <i className="toggle-on"></i></a>
                                                          </div>
                                                    </div>
                                                    <span className="h5 sitter-name"> <div className="avatar-image">
                                                        <img src={value.image} alt=""/></div> {value.first_name} {value.last_name}</span>
                                                    <small className="text-blue">Description</small>
                                                    <p>
                                                        {value.job_details}
                                                    </p>
                                                    <p>
                                                        {value.allergies}
                                                    </p>
                                                    {/* <a className="chat-button"><i className="chat"></i>Chat</a> */}
                                                    <br/>
                                                        <small className="alert alert-danger">You can clock in from 20 minutes before the start time {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} up to 60 minutes after the start time</small>
                                                </div>
                                            </div>);
                                            })}
                                            </div>
                                        </div>}
                                    </div>
                                    

                                   
                                    <div className={`tab-pane fade  ${(this.state.SecondTabs == 3) ? 'show active' : ''}`}  id="active-posting" role="tabpanel" aria-labelledby="active-posting-tab">
                                    {(this.state.CompletedJobs.length == 0) ? <div className="row justify-content-left">
                                    
                                    <div className="col-12 col-md-9 empty-screen-placeholder image-container">   
                                        <img alt='no completed jobs' src={(EmptyCompletedJobs)} />
                                        <div className="image-overlay"></div>
                                        <h5 className="text-grey pt-4">You haven't completed any jobs yet</h5><br/><br/>  
                                    </div>
                                    </div> : 
                                    <div className="scrolling">
                                    {this.state.CompletedJobs.map((value,index) => {
                                        let jobStarted = moment.utc(value.job_startedAt).subtract(4, 'hours').format('MM-DD-YYYY hh:mm:ss')
                                        let jobEnded = moment.utc(value.job_endedAt).subtract(4, 'hours').format('MM-DD-YYYY hh:mm:ss')
                                    return (
                                        <div key={value.job_id} className="col-12 col-lg-8 job-list-item">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-9 col-md-9 col-lg-9">
                                                        <span className="date"><i className="fa fa-calendar-week"></i>
                                                        {moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}  </span>
                                                        <br/>
                                                        <span className="date"><i className="fa fa-clock"></i>
                                                        {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}
                                                        </span>
                                                        <br/>
                                                        <span className="date"><i className="fa fa-hourglass-start text-blue"></i> Sitting started at : {jobStarted}</span>
                                                        <br/>
                                                        <span className="date"><i className="fa fa-hourglass-start text-blue"></i> Sitting ended at : {jobEnded}</span>
                                                        {/* <br/>*/}
                                                        {/* <span className="date"><i className="fa fa-dollar-sign text-blue"></i> Total amount : {value.total_amount}</span> */}
                                                    </div>
                                                    
                                                </div>
                                                <span className="h5 sitter-name"> <div className="avatar-image"><img src={value.image} alt=""/></div> 
                                                {value.first_name} {value.last_name}
                                                </span>
                                                <small className="text-blue">Description</small>
                                                <p>
                                                   {value.job_details}
                                                </p>
                                                <div className="text-right " >
                                                {
                                                (value.review_added == 0) 
                                                ? 
                                                <a onClick={(e) => {this.HandleModalOpen(e,'ReviewModal',value.job_id)}}   className="text-blue "> <i className="fa fa-star" ></i> Rate Parent  </a>
                                                 : 
                                                <div className='text-blue text-right'>
                                               <span className='mr-4 text-right' >You rated parent </span> 
                                                <StarRatings
                                                        rating={value.rating}
                                                        starDimension="14px"
                                                        starRatedColor='#2986E3 '

                                                        starSpacing="3px"/></div>}
                                                </div>
                                                
                                            </div>
                                        </div>)
                                        })}
                                        </div>}
                                    </div>
                                    
                                </div>
                            </div>


                            <div className={`tab-pane fade chats-content ${(this.state.FirstTabs == 3) ? 'show active' : ''}`} id="chats" role="tabpanel" aria-labelledby="chats-tab">

                            {/* <Chat 
                                toid={this.state.tomessageid} 
                                formid={this.state.frommessageid} 
                                jobid={this.state.messagejobid}
                                //SocketClient={this.state.SocketClient} 
                                /> */}
                            
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad " className="cancel-job-modal " show={this.state.CancelModal} onHide={(e)=> this.HandleModalClose(e,'CancelModal')}>        
        
            <Modal.Body>
                <div className="col-12 text-center">
                        <h3 className="text-white pt-4">Are you sure you want to cancel?</h3>
                        <p className="text-white">You will be charged $10 if you cancel this job</p>
                </div>
            </Modal.Body>   
                <Modal.Footer className="justify-content-center" >
                <button onClick={(e)=> this.HandleModalClose(e,'CancelModal')} type="button" className="btn btn-primary small-btn">No, don't cancel</button> &nbsp;&nbsp;&nbsp;
                <button onClick={(e) => this.HandleCloseJob(e)} type="button" className="btn btn-white small-btn text-blue">Yes, cancel this job</button>
             </Modal.Footer>                                     
       
    </Modal>           
    <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName=" " className="cancel-job-modal " show={this.state.TimmerModal} onHide={(e)=> this.HandleModalClose(e,'TimmerModal')}>            
        <Modal.Body>
            <div className="col-12 text-center">
                {/* <h3 className="pt-4">Are you sure you want to cancel?</h3> */}
                        <span className="text-dark">{(this.state.timer == 0) ? '' :
                                               <>
                                                You can't send proposals to jobs for another <span></span>
                                               <Countdown className="text-pink"
                                                zeroPadTime={2}
                                                // now={moment(this.state.server_time).utc()._d}
                                                // onTick={() => {this.setState({timmer:this.state.timer+1})}}
                                                onComplete={(e) => this.HandleTimmerDone(e)}
                                                date={moment(this.state.server_time) + this.state.timer} /> minute(s)</> }</span>
                </div>
        </Modal.Body>   
        <Modal.Footer className="justify-content-center" >
            <button onClick={(e)=> this.HandleModalClose(e,'TimmerModal')} type="button" className="btn btn-primary small-btn">Okay </button> 
        </Modal.Footer>                                     
    </Modal>     
      <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName=" " className="cancel-job-modal " show={this.state.StartJob} onHide={(e)=> this.HandleModalClose(e,'StartJob')}>        
        
            <Modal.Body>
                <div className="col-12 text-center">
                        <h3 className="pt-4">Are you sure you want to start sitting</h3>
                        <p className="text-dark">Please Enter Otp to start sitting.</p>
                       <input maxLength={'4'} max={'9999'} value={this.state.startjobotp} onChange={this.HandleJobOtp} type={'number'} placeholder="Please enter Otp " /> 
                </div>
            </Modal.Body>   

                <Modal.Footer className="justify-content-center" >
                <button onClick={(e)=> this.HandleModalClose(e,'StartJob')} type="button" className="btn btn-primary small-btn">No </button> 
                <button onClick={(e)=> this.HandleStartJob(e)} type="button" className="btn btn-primary small-btn">Yes </button> 
             </Modal.Footer>                                     
       
      </Modal>  

      <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName=" " className="cancel-job-modal " show={this.state.ReviewModal} onHide={(e)=> this.HandleModalClose(e,'ReviewModal')}>        
             <Modal.Header> <div className="text-center" >
             <h3 className="text-blue "> Rate Parent</h3><p >(For this job)</p></div>  </Modal.Header>                             
            <Modal.Body>
                <div className="col-12 text-center">
                        
                       <StarRatings 
                       rating={this.state.rating}
                       starRatedColor='#2986E3'
                       starHoverColor='#2986E3'
                       changeRating={(newrating,name) => {this.setState({rating:newrating})}}
                     />
                     <div className="col-12 py-2">
                                <label className="text-blue">
                                    Want to add something more? (optional)
                                </label>
                                <textarea  onChange={(e)=> {this.setState({ReviewText:e.target.value})}}  value={this.state.ReviewText}  className="form-control" name="about_sitter" id="about_sitter" rows="5" placeholder="(Include what you like the most)">
                                    {this.state.about}
                                </textarea>
                                <label className='text-pink' style={{textAlign:'justify'}}>
                                    When writing a negative review, you may want to consult with your Rabbi to ensure that the information you are sharing is in accordance with Halacha.
                                </label>
                    </div>
                </div>
            </Modal.Body>   
                <Modal.Footer className="justify-content-center" >
                <button onClick={(e)=> this.HandleAddReview(e)} type="button" className="btn btn-primary small-btn">Submit </button> 
                {/* <button onClick={(e)=> this.HandleStartJob(e)} type="button" className="btn btn-primary small-btn">Yes </button>  */}
             </Modal.Footer>                                     
       
      </Modal>     

         <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad " className="cancel-job-modal " show={this.state.withdaw}>        
        
                <Modal.Body>
                    <div className="col-12 text-center">
                            <h3 className="text-white pt-4">Are you sure to withdraw your earnings ?</h3>
                            <p className="text-white"> Total Earnings : { this.state.wallet_balance.toFixed(2) } </p>
                    </div>
                </Modal.Body>   
                    <Modal.Footer className="justify-content-center" >
                    <button onClick={(e)=> this.HandleModalClose(e,'withdaw')} type="button" className="btn btn-primary small-btn">No </button> 
                <button onClick={(e)=> this.HandleWithdaw(e)} type="button" className="btn btn-primary small-btn">Yes </button> 
     
                </Modal.Footer>                                     
   
  </Modal>  

  <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad " className="cancel-job-modal " show={this.state.Onboarding}>        
        
                <Modal.Body>
                    <div className="col-12 text-center">
                            <h3 className="text-white pt-4">You don’t have a payment method.</h3>
                            <p className="text-white">Please add a payment method to continue</p>
                    </div>
                    {/* Container for the iframe */}
                    <div id="iframe-container"></div>
                </Modal.Body>   
                    <Modal.Footer className="justify-content-center" >
                        <a onClick={this.getOnboardinglink} disabled={this.state.linkloading}
                                className="btn btn-white small-btn text-blue" id='addBank'>
                                { (this.state.linkloading) ? 'Please Wait...' : 'Add Bank' }
                        </a>
                    </Modal.Footer>                                     
   
  </Modal>                                          

    <div className="modal fade cancel-job-modal" id="cancel-job" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered justify-content-center">
            <div className="modal-content blue-grad">
                <form action="#" className="bordered">
                    <div className="modal-body">
                        <div className="col-12 text-center">
                            <h3 className="text-white pt-4">Are you sure you want to cancel?</h3>
                            <p className="text-white">You will be charged $10 if you cancel this job</p>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center ">
                        <button onClick={(e)=> this.HandleModalClose(e,'cancelmodal')} type="button" className="btn btn-primary small-btn">No, don't cancel</button> &nbsp;&nbsp;&nbsp;
                        <button  type="button" className="btn btn-white small-btn text-blue">Yes, cancel this job</button>
                    </div>
                </form>
            </div>
        </div>
    </div>


            </>
        );
    }
}

SitterDashboard.propTypes = {

};

export default SitterDashboard;

