import React, { Component } from "react";
import Layoutone from "../../layouts/Layoutone";
import Api from "../../context/Api";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
class CheckOtp extends Component {


  constructor(props) {
    super(props);

    let Query = this.props.location.search;
    let UserType = new URLSearchParams(Query).get('usertype');
    let OtpToken = new URLSearchParams(Query).get('otp-token');
    this.state = {
      usertype: UserType ?? null,
      otp:'',
      email:window.localStorage.getItem('email'),
      token:OtpToken,
      error:'',
      success:false,
      loading:false
    }
    //console.log(OtpToken);


  }

handleOtp = e => {this.setState({otp:e.target.value})}

handleSubmit = e => {

    if(this.state.otp == ''){
        this.setState({
            error:'Please enter the OTP.'
        })
    }else{
    let data = {
      otp_token : this.state.token,
      otp : this.state.otp
    }
    
    this.setState({
        loading:true
    })
    Api.post('/auth/match-otp',data).then((response)=> {
        this.setState({success:true,error:'',loading:false})
        //console.log(response.data.message);
        toast(response.data.message,{type:'success'});
        window.localStorage.setItem('otp_token',this.state.token);
        switch (this.state.usertype) {
          case '1':
              this.props.history.push(`/signup-parent?token=${this.state.token}`);
            break;
          case '2':
              this.props.history.push(`/signup-sitter?token=${this.state.token}`);
            break;
          case 'reset-pass':
              this.props.history.push(`/reset-password?token=${this.state.token}`);
            break;
          default:
            this.props.history.push(`/login`);
            break;
        }

        // if(this.state.usertype == '1')
        // this.props.history.push(`/check-otp/${response.data.data.otp_token}`);
      }).catch((e)=>{
        // let message = e.response.data.message;
        //console.log(e.response.data);
        toast(e.response.data.message,{type:'error'});
        this.setState({error:e.response.data.message,loading:false})
      })
    }

}

handleResentotp = e => {
  let data = {otp_token : this.state.token}
  this.setState({loading:true})
  Api.post('/auth/resend-otp',data).then((response)=> {
    this.setState({success:true,error:'',loading:false})
    toast('Otp resent successfully on your email',{type:'success'});
  }).catch((e)=>{
    this.setState({error:e.response.data.message,loading:false})
  })
}

  render() {
    return (
      <Layoutone MyClass="hero-login">
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-10 col-md-9">
            <h2>Enter One Time Password</h2>
            <h5>
              Please enter the OTP (One Time Password) sent to your email, (<strong>{this.state.email}</strong>) 
            </h5>
            <br/>
            <p>( Check your email spam folder if you are unable to receive OTP email. )</p>
            <form

              className="login-form needs-validation form-control"
              noValidate
            
            >
              <input
                onChange={this.handleOtp}
                type="text"
                maxLength="6"
                className="form-control"
                name="otp"
                placeholder="Please enter the OTP"
                required
              />
              <small className="text-danger " >  {this.state.error} </small>
              <button disabled={this.state.loading} type="button" onClick={this.handleSubmit} className="btn-white small-btn">
              { (this.state.loading == true) ? 'loading . . . .  ' : 'Verify OTP' }
              </button>
            </form>
            <div className="col-12 text-center back-btn">
              <a disabled={this.state.loading}  onClick={this.handleResentotp} className="pb-4">
                Resend
              </a>
              <Link to='/login' >
                <i className="fa fa-chevron-left"> </i> Back to login
              </Link>
            </div>
          </div>
        </div>
      </Layoutone>
    );
  }
}

export default CheckOtp;
