import React, { Component } from "react";
import Layoutone from "../../../layouts/Layoutone";
// import CheckMailImage from "../../assets/images/check-mail (1).png";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useParams, withRouter } from "react-router";
import Api from "../../../context/Api";
import { toast } from "react-toastify";

class CheckMail extends Component {
  constructor(props) {
    super(props);

    let Query = this.props.location.search;
    let UserType = new URLSearchParams(Query).get("usertype");
    this.state = {
      usertype: UserType ?? null,
      email: "",
      token: "",
      error: "",
      success: false,
      loading: false,
    };
  }
  handleEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.email == "") {
      this.setState({
        error: "Please Enter your email.",
      });
      toast("Please Enter your email.", { type: "error" });
    } else {
      let data = {
        email: this.state.email,
        type:'registration'
      };

      this.setState({
        loading: true,
      });
      Api.post("/auth/email-verification", data)
        .then((response) => {
          
          window.localStorage.setItem("email", this.state.email);
          this.setState({ success: true, error: "", loading: false });
          //console.log(response.data.data);
          // toast(response.data.data.message,{type:'success'})
          toast("OTP successfully sent to your email", { type: "success" });
          this.props.history.push(
            `/check-otp?usertype=${this.state.usertype}&otp-token=${response.data.data.otp_token}`
          );
        })
        .catch((e) => {
          // let message = e.response.data.message;
          //console.log(e.response.data.message);
          toast(e.response.data.message, { type: "error" });

          this.setState({ error: "", loading: false });
        });
    }
  };

  render() {
    return (
      <Layoutone MyClass="hero-login forgot-password-page">
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-10 col-md-9">
            <h2>Enter email</h2>
            <h5>We will send a OTP (One Time Password) to verify your email</h5>
            <form
              onSubmit={this.handleSubmit}
              className="login-form needs-validation form-control"
              noValidate
            >
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="Email"
                required
                onChange={this.handleEmail}
              />
              <small className="text-danger "> {this.state.error} </small>

              <button
                disabled={this.state.loading}
                type="submit"
                className="btn-white small-btn"
              >
                {this.state.loading == true ? "loading . . . .  " : "Send OTP"}
              </button>
            </form>
            <div className="col-12 text-center back-btn">
              <Link to="/login">
                <i className="fa fa-chevron-left"> </i> Back to login
              </Link>
            </div>
          </div>
        </div>
      </Layoutone>
    );
  }
}

export default CheckMail;
