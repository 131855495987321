import  ProfilePicturePlaceholder from "../../assets/images/profile-picture (1).png";
import  Chatplaceholder from "../../assets/images/chats-empty.svg";
import React, { Component,createRef } from 'react';

import  io  from "socket.io-client";
import TokenService from "../../services/Token.Service";
import Moment from 'react-moment';
let client = '';

class Chat extends Component {
    chatContainer= createRef();

    constructor(props) {
        super(props);
        this.state = {
            ChatList: [],
            loader:true,
            JobId:'',
            ChatId:'',
            MessagesList:[],
            MessagesLoader:true,
            ChatName:'',
            UserImage:'',
            SenderId:'',
            ReceiverId:'',
            TextMessage:'',
            SelectedJobStatus:''
            }
            this.scrollToBottom = this.scrollToBottom.bind(this);
            this._handlePressEnter = this._handlePressEnter.bind(this);
            //this._handleSockect = this._handleSockect.bind(this);
        }
         
    
    componentWillMount() {
    }

    componentDidUpdate(preProps,PreState){
    }

    scrollToBottom = () => {
        if(this.state.JobId){
            const scroll = this.chatContainer.current.scrollHeight -this.chatContainer.current.clientHeight;     
            this.chatContainer.current.scrollTo(0, scroll+100);
      
        }
    }


    // _handleSockect = (e) => {

    //      client = io.connect('https://api.simplisitter.com:8000')
    //     var data = {
    //         refresh_token: TokenService.getLocalRefreshToken(),
    //         device_token: '',
    //         device_id: '',
    //         device_type: '3',
            
    //       };
    //     client.on('connect',()=> {
    //         client.emit("connect-user", data);
    //         client.on("connect-user-response", (res)=> {
    //       });  
    //       client.emit("get-chat-list", {"bearer_token": TokenService.getLocalAccessToken(),});
    //       client.on("chat-list", (res)=> {
    //         this.setState({ChatList:res,loader:false});});
    //         client.on('new-message',(res) => {
    //                 this.setState({MessagesList:[...this.state.MessagesList,res]});
    //         })
    //     })
    // }

    componentDidMount() {
      

        //this._handleSockect();
        // setInterval(()=> {
        //     if(this.state.loader){
        //         this._handleSockect();
        //     }
        // },6500)
    }


    componentWillUnmount() {

    }

    HandleSendMessages = (e) => {
        let data = {
            bearer_token: TokenService.getLocalAccessToken(),
            message_to: this.state.ReceiverId,
            message: this.state.TextMessage,
            job_id: this.state.JobId,
        };
        this.setState({TextMessage:''});
        //console.log('data',data);
        client.emit('send-message',data);
        client.on('chat-messages',(res) => {
           //console.log('chat-messages',res)
        })

        this.scrollToBottom()
        
    }

    _handlePressEnter = (e) => {
        //console.log(e.key);
        if(this.state.TextMessage){
            if(e.key === 'Enter'){
                this.HandleSendMessages(e);
            }
        }
    }

  

    HandleShowChatMessages = (event,Chat) => {

        let SenderId = ''
        let ReceiverId = ''

        this.setState({SelectedJobStatus:Chat.job_status})
        if(TokenService.getUserRole() === 1){
            SenderId = Chat.parent_id;
            ReceiverId = Chat.sitter_id;
        }else{
            SenderId = Chat.sitter_id;
            ReceiverId = Chat.parent_id;
        }

        this.setState({MessagesList:[],MessageLoader:true,SenderId:SenderId,ReceiverId:ReceiverId,JobId:Chat.job_id,ChatId:Chat.chat_id})
        this.setState({
            UserName:`${Chat.first_name} ${Chat.last_name}`,
            UserImage:(Chat.image === '') ? ProfilePicturePlaceholder : Chat.image,
        })

        client.emit('get-chat-messages',{chat_id:Chat.chat_id ,bearer_token: TokenService.getLocalAccessToken()})
        client.emit('mark-read',{chat_id:Chat.chat_id ,bearer_token: TokenService.getLocalAccessToken()})
        client.once("chat-messages", (res)=> {
            this.setState({MessagesList:res.reverse(),MessageLoader:false});
          //console.log('chat-messages',res)
          this.scrollToBottom();
      });
    }

    render() {
        return (
            <>
             <div className="row chats-design">
                <div  className={`col-12 col-md-6 chats-list ${(this.state.loader) ? 'loader-sm' : ''}`}>
                    <ul>
                        <li><h4 className="text-blue">All chats</h4></li>
                        {this.state.ChatList.map((chat)=> {
                            return (
                                <li className={`chat-item ${(this.state.ChatId === chat.chat_id ) ? 'chat-open' : ''}`}  onClick={(e) => this.HandleShowChatMessages(e,chat)} >
                                    <div className="profile-image">
                                        <img alt="Profile Avatar" src={(chat.image === '') ? ProfilePicturePlaceholder : chat.image} />
                                    </div>
                                    <div className="user-name" >
                                        <h5>{chat.first_name} {chat.last_name}  {(chat.hired)  ? '' : '' } </h5>
                                        <small>{chat.last_message.substring(0,55)} </small>
                                    </div>
                                    <div className="chat-count">
                                        {(chat.unread === 0) ? '' : <span className="message-count">{chat.unread}</span> }
                                        <span className={`tag job-${chat.job_status}`}>{chat.job_status}</span>
                                    </div>
                                </li>);
                                })}         
                     </ul>
                </div>
                <div className="col-12 col-md-6 chat-box">
                    {(this.state.JobId === '') ? <div className="col-12 empty-screen-placeholder">
                                                <img alt="No chat placeholder" src={(Chatplaceholder)} />
                                                <h5 className="text-grey pt-4">No chat started at this time.</h5>   
                                            </div> : <><div className="col-12 details-header blue-grad">
                                                <i className="fa fa-arrow-left"></i>
                                                <div className="profile-image">
                                                    <img alt="Message receiver profile avatar" src={this.state.UserImage} />
                                                </div>
                                                <span className="sender-name"> {this.state.UserName} </span>
                                                <a href="#" >View job details <i className="fa fa-external-link"></i></a>
                                            </div>
                                            <div ref={this.chatContainer} className={`col-12 chatting ${this.state.MessagesLoader ? 'loader-sm' : ''}`}>
                                                <ul>
                                                    
                                                    {this.state.MessagesList.map((value) => {
                                                    
                                                    if(value.sender_id === this.state.SenderId){
                                                        return (
                                                            <li className="sent">{value.message}
                                                            <span>   <Moment format="YYYY/MM/DD" >{value.createdAt}</Moment> </span>
                                                            </li>
                                                            )
                                                    }else{
                                                        return (
                                                            <li className="received">{value.message}
                                                            <span>   <Moment format="YYYY/MM/DD" >{value.createdAt}</Moment> </span>
                                                            </li>
                                                            )
                                                    }
                                                    })}

                                                </ul>
                                                
                                            </div>

                                            <div className="input-box">
                                                {(this.state.SelectedJobStatus === 'Completed' || this.state.SelectedJobStatus === 'Cancelled') 
                                                ?<> <p> You can't send message to this chat because this job no longer. </p>  </> 
                                                :<> 
                                                <input  onKeyDown={this._handlePressEnter}  disabled={(this.state.SelectedJobStatus === 'Completed')} value={this.state.TextMessage} onChange={(e) => this.setState({TextMessage:e.target.value})} type="text" />
                                                <button onClick={(e) => this.HandleSendMessages(e)} disabled={(this.state.TextMessage === '') ? true : false } className="send-button blue-grad"> <i className="fa fa-paper-plane"></i> </button></> }
                                               
                                            </div></> }
                                            
                                            

                                        </div>
            </div>   
            </>
        );
    }
}



export default Chat;