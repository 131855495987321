import React from 'react';
import ReactDOM from 'react-dom';
import AppContext from './context/appContext';
import { Route,HashRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/fontawesome/css/all.css';
import './assets/css/style.css';
import './assets/css/simmer.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/sass/style.scss';
import { ToastContainer } from 'react-toastify';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import IndexReducer from './store/reducers/IndexReducer';

import LogRocket from 'logrocket';
LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT);

const store = createStore(IndexReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());



if(window){
  window.Cache={};
}
ReactDOM.render(
  <HashRouter>
    <Provider store={store} >
    <AppContext>
      <Route component={App} />
      <ToastContainer hideProgressBar={true}  />
    </AppContext>
    </Provider>
  </HashRouter>,
  document.getElementById('root')
);
reportWebVitals();
