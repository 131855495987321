
import React from 'react'
import { Link } from 'react-router-dom';

const TermsSitter = () => {
    return (
        <div >
            <h3 ><center>EMPLOYMENT CONTRACT</center></h3>
<ol start="1" >
    <li>
        <p>
            
        SIMPLISITTER (“Company”) and ___________ (“Babysitter”) willingly enter into this contract to facilitate Babysitter’s provision of services to Company’s clients and/or subscribers.
        </p>
        <p>For the purposes of this Contract, “Subscriber” refers to any party who has contracted with Employer for babysitting services. </p>
    </li>
</ol>

<h3 ><center>Terms and Conditions</center></h3>
<ol start="1">
    <li>
        <p ><em><strong>Payment.</strong></em><em>.&nbsp;</em>Babysitter shall receive payment via the Company’s App once a week for fees earned in conjunction with any babysitting jobs performed during the previous week.  Babysitter acknowledges that Babysitter shall receive 93% of the funds tendered from the Parent to the Company.  Payment shall only be made from the Company directly to the Babysitter’s bank as per the banking information provided by the Babysitter.  Any errors in information that are the fault of the Babysitter shall not create any liability on the part of the Company.</p>
    </li>
</ol>
<ol start="2">
    <li>
        <p><em><strong>Hold Harmless. </strong></em> Babysitter hereby promises to indemnify and hold harmless Company for any damages – physical, property or economic – incurred during the course of the provision of babysitting services.  Company shall not be responsible for any instances of violence or abuse (physical, verbal or emotional) on the part of either the Babysitter or the Subscriber or any member of Subscriber’s household.</p>
    </li>
</ol>
<ol start="3">
    <li>
        <p ><em><strong>Termination.</strong></em><em>.&nbsp;</em> In the event Company receives any reports of misconduct or impropriety from the Company about the Babysitter, Company may – after proper due diligence – refuse to allow Babysitter to access and utilize the Company’s App.</p>
    </li>
</ol>
<ol start="4">
    <li>
        <p ><span ><em><strong>Non-Disclosure. </strong></em><strong>.&nbsp;</strong></span><span >
        Babysitter shall not share any information about either the Company and/or the Subscriber that could cause harm (financial or otherwise) to either the Company and/or the Subscriber.
            </span></p>
    </li>
</ol>
<ol start="5">
    <li>
        <p ><span ><em><strong> Non-Compete.  </strong></em><strong>.&nbsp;</strong></span><span >
        Babysitter shall not contract directly or independently with Subscribers to arrange for provision of babysitting services.
            </span></p>
    </li>
</ol>
<ol start="6">
    <li>
        <p ><span ><em><strong> Dispute Resolution. </strong></em><strong>.&nbsp;</strong></span><span >
        In the event of dispute arising from provision of services, either party shall have the right to demand resolution by way of mediation before resorting to formal litigation.
         </span></p>
    </li>
</ol>
<ul style={{ listStyle:'none'}}  >
    <li>
    <p ><strong>Contact Us. &nbsp;</strong><span >If you have any questions about these Terms, please email us at support@simplisitter.com</span></p>
    </li>
</ul>

	</div>
    )
}

export default TermsSitter;