
import React from 'react'
import { Link } from 'react-router-dom';

const Terms = () => {
    return (
        <div >
            <h3 ><center>TERMS OF USE</center></h3>
<ol>
    <li>
        <p ><em><strong>Contractual Relationship</strong></em>. These Terms of Use (&quot;Terms&quot;) govern your relationship with SIMPLISITTER and all services related thereto. PLEASE READ THESE TERMS CAREFULLY, AS THEY CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND SIMPLISITTER. By accessing or using the SIMPLISITTER&rsquo;s services, you confirm your agreement to be bound by these Terms.</p>
    </li>
    <li>
        <p ><strong>Availability</strong>. <span >These services are being provided &ldquo;as available&rdquo; There is no guarantee that babysitters and/or families will be available at any given time.&nbsp;</span></p>
    </li>
</ol>
<ol start="3">
    <li>
        <p ><em><strong>Payment</strong></em><em>.&nbsp;</em>In order to process payment for using our Services, you must provide a credit and/or debit card to be kept on file with Simplisitter. Upon requesting a babysitter, the family shall be charged .01. The remainder of the balance due shall be charged when the babysitting services have been completed. In the event the full payment is not able to be processed on account of some flaw or defect with the card on file, you shall have three (3) business days to make the proper payment. In the event proper payment is not made within such time, Simplisitter shall have the right to charge a $15 processing fee. In the event a card on file is declined, Simplisitter reserves the right not to provide future services until a different, valid card is placed on file.</p>
    </li>
</ol>
<ol start="4">
    <li>
        <p><em><strong>Cancellation</strong></em>. In the event a family cancels <u>more</u> than twenty-four hours in advance, there shall be no cancellation fee. In the event a family cancels <u>before</u> two hours in advance, there shall be a $15 cancellation fee. In the event a babysitter cancels <u>at any time</u> Simplisitter shall not be responsible to find or to fund a replacement babysitter.</p>
    </li>
</ol>
<ol start="5">
    <li>
        <p ><em><strong>Loyalty</strong></em><em>.&nbsp;</em>Families and sitters may not contract or make-up amongst themselves outside of the framework of the Simplisitter framework. Incidental communication and communication essential to the coordination and provision of services <u>may</u> be made between families and sitters but all payment arrangements and future reservations shall be made exclusively through Simplisitter.</p>
    </li>
</ol>
<ol start="6">
    <li>
        <p ><span ><em><strong>Transactions Are Between Users</strong></em><strong>.&nbsp;</strong></span><span >This Service may be used to help obtain or offer services provided by Users and to facilitate payment for such services. However, Users transact solely between themselves, and Simplisitter is not a party to any transactions between Users. Along these lines, Simplisitter hereby expressly disclaims, and you hereby expressly release Simplisitter and its affiliates from, any and all liability whatsoever for any controversies, claims, suits, injuries, loss, harm and/or damages arising from and/or in any way related to disputes, dealings, or interactions between you and any other Users or third parties.</span></p>
    </li>
</ol>
<p >Neither babysitters nor families are agents or employees of SIMPLISITTER.</p>
<ol start="7">
    <li>
        <p ><em><strong>References</strong></em>. SIMPLISITTER is a service designed to match-up families seeking a baby-sitter and baby-sitters seeking job(s). In this capacity SIMPLISITTER <strong>DOES NOT VOUCH FOR THE INTEGRITY OR MORAL CHARACTER</strong> of either the families or babysitters utilizing our services. SIMPLISITTER <strong>STRONGLY ENCOURAGES BOTH FAMILIES AND/OR BABYSITTERS TO UNDERTAKE THEIR OWN RESEARCH, SPEAK TO PARTIES&rsquo; REFERENCES AND TO DECIDE FOR THEMSELVES&nbsp;</strong>if a particular family and/or babysitter meets their criterion.</p>
    </li>
</ol>
<p>In this respect, Simplisitter may help the parties interface with one another via phone and/or Skype, Zoom, etc. so the parties <em>themselves</em> can ascertain if they feel comfortable working with one another. Simplisitter <em><strong>strongly suggests</strong></em><strong>&nbsp;</strong>that both families and baby-sitters speak with one another prior to committing to any particular babysitting jobs.</p>
<ol start="8">
    <li>
        <p ><em><strong>Assumption of the Risk</strong></em>. SIMPLISITTER does not guarantee the quality, suitability, safety, or ability of either the babysitters and/or the families. By using our services, you hereby agree that the entire risk arising out of the use of our services remains solely with you to the maximum extent permitted by law.</p>
    </li>
    <li>
        <p ><strong>Indemnity.&nbsp;</strong><span >Anyone using Simplisitter Services (i.e., families and/or babysitters) agree to indemnify and hold SIMPLISITTER and its affiliates and their officers, directors, employees, and agents harmless from and against any and all actions, claims, demands, losses, liabilities, costs, damages, and expenses (including attorneys&apos; fees), arising out of or in connection with your use of the Services or services.</span></p>
    </li>
</ol>
<ol start="10">
    <li>
        <p><em><strong>Hold Harmless.</strong></em> In the event of any civil or criminal lawsuits, damages (financial or otherwise), torts or contractual disputes or litigation arising from any family and/or any babysitter utilizing SIMPLISITTER, such parties agree to hold SIMPLISITTER harmless for any alleged involvement. SIMPLISITTER is simply a referral agency putting two potentially interested parties in contact with one another.</p>
    </li>
    <li>
        <p ><strong>Limitation of Liability.&nbsp;</strong><span >SIMPLISITTER shall not be liable for direct, indirect, special, punitive, or consequential damages including but not limited to lost profits, personal injury, or property damage arising from or connected to the provision of babysitting services.&nbsp;</span></p>
    </li>
</ol>
<ol start="12">
    <li>
        <p ><strong>Choice of Law.&nbsp;</strong><span >These Terms shall be governed by and construed in accordance with the laws of the State of New York. Wherever feasible, any dispute shall first be referred to mediation and/or arbitration before a mutually-agreeable tribunal.</span></p>
    </li>
</ol>
<ul style={{ listStyle:'none'}} >
    <li>
        <p ><strong>Contact Us. &nbsp;</strong><span >If you have any questions about these Terms, please email us at support@simplisitter.com</span></p>
    </li>
</ul>

	</div>
    )
}

export default Terms 