import React, { Component } from "react";
import Layoutone from "../../layouts/Layoutone";
import { Link } from "react-router-dom";
import Input from "../../components/Forms/inputs";
import Api from "../../context/Api";
import {toast} from 'react-toastify'
import { localeData } from "moment";
import TokenService from "../../services/Token.Service";
import instance from "../../context/AuthApi";




class onboardingfail extends Component {


  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      token: "",
      error: "",
      success: false,
      loading: false
    }
    
  }

  componentDidMount() {
  }

  render() {



    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h2>Your OnBoarding has failed</h2>
            <h3>Please try again by going back and add a payment method.</h3>
          </div>
          <Link to="/sitter/dashboard"  >Go BACK</Link>
        </div>
      </div>
    );
  }
}

export default onboardingfail;

