
import React,{useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo-1.png";  
import SVG from "../../assets/svg/faq.svg";  
import '../../assets/css/faqstyle.css';



const Faq = () => {

    const [expanded, setexpanded] = useState([true,false,false,false,false]);
    
    function open(e,index){
        let Expend = [false,false,false,false,false];

        let CurrentState = Expend[index] 
        if(expanded[index] == true){
            Expend[index] = false;
        }else{
            Expend[index] = true;

        }

        
        setexpanded(Expend);
        //console.log(index,e);
      }


    let faqs = [
        {
            qns : 'Can I use this if I don’t have a smart phone?' ,
            ans : 'Sure, we have a website for this reason, and you can use your computer to post or accept jobs. If you don’t have access to the app/website at the time of the session, you can request from the parent/babysitter to login from their device using your login information.'
        },
        {
            qns : 'When will I get paid for the jobs that I did?',
            ans: 'Payroll will take place every Friday, and you will get paid for the jobs you did that week.'
        },
        {
            qns :'How do I clock in as a babysitter?',
            ans: 'From the babysitter’s login page, press “start sitting”. The babysitter will need to enter the 4 digit password from the parent in order to begin the session.'
        },
        {
            qns :'How does transportation work?',
            ans: 'Sitters will have the option of checking off whether they will need transportation or not on their profile page.'
        },
        {
            qns :'Why is my location filter not working when I search the job posts?',
            ans: 'If location filter is not working, check the location permission settings on your device and ensure that location permission for google maps and browser is turned on.'
        },
        {
            qns :'What should I do if no babysitters responded to my job post?',
            ans: 'If it’s already past the time that you originally posted your job for, repost the job with a later start time so more babysitters can see it.'
        }
    ]


    return (<>
    <header>
        <div className="container">
            <Link to={`/`}>
                <img className="logo-full" src={Logo} alt=" "/>
            </Link>
        </div>
    </header>
    <section className="hero hero-login blue-grad">
        <div className="container pb-5">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <div className="col-12 text-center">
                        <img className='img-responsive' height={200} src={SVG} alt=" "/>
                    </div>
                    <h2 className="text-center">Frequently Asked Questions</h2>
                    <div className="accordion">
                        {faqs.map((value,index)=> {
                            return (
                            <div key={index} className="accordion-item">
                            <button  onClick={(e)=> open(e,index)}  aria-expanded={expanded[index]}><span className="accordion-title">{value.qns}</span><span className="icon" aria-hidden={!expanded[index]}></span></button>
                            <div className="accordion-content">
                                <p> {value.ans} </p> 
                            </div>
                        </div>)
                        })}

                    </div>
                </div>
            </div>
        </div>
    </section>
        </>)
}

export default Faq 