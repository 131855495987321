import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Personal extends PureComponent {
    render() {
        return (
            <div className={`tab-pane fade pill-personal-details ${(this.props.active == true) ? 'active show' :  '' }`} id="pill-personal-details" role="tabpanel" aria-labelledby="pill-personal-details-tab">
        <form action="#" className="edit-personal-details bordered">
            <div className="row px-2">


                <div className="col-12 col-md-7 upload-profile-picture">
                    <img src="assets/images/avatar.png" alt=""/>
                    <span className="h4 text-blue">Upload photo <span className="text-grey">(optional)</span></span>
                    <input type="file" name="profile_pic" id="profile_pic"/>
                </div>
                <div className="col-6 col-md-6 ">
                    <label htmlFor="f_name">First name</label>
                    <input type="text" className="form-control" name="f_name" id="f_name" placeholder="First name" required/>
                </div>
                <div className="col-6 col-md-6 ">
                    <label htmlFor="l_name">Last name</label>
                    <input type="text" className="form-control" name="l_name" id="l_name" placeholder="Last name" required/>
                </div>
                <div className="col-col-12 col-md-6 ">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" name="email" id="email" placeholder="Email" required/>
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="address">Address</label>
                    <input type="text" className="form-control" name="address" id="address" placeholder="Address" required/>
                </div>
                <div className="col-12 col-md-6 ">
                    <label htmlFor="religion">Religion</label>
                    <input type="text" className="form-control" name="religion" id="religion" placeholder="Religion" required/>
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="spouse_name">Spouse name (optional)</label>
                    <input type="text" className="form-control" name="spouse_name" id="spouse_name" placeholder="Spouse name (optional)"/>
                </div>
                <div className="col-12">
                    <div className="col-12 col-md-6 pt-3">
                        <h5 className="text-grey">Enter password to save changes</h5>
                        <label htmlFor="spouse_name">Password</label>
                        <input type="password" className="form-control" name="password" id="password" placeholder="password"/>
                    </div>
                </div>
                <div className="col-12 action-buttons">
                    <button className="btn-blue float-end blue-grad" type="submit">Save changes</button>
                    <a href="#" className="text-blue float-end">Don't save</a>
                </div>
            </div>
        </form>
    </div>
        );
    }
}

Personal.propTypes = {

};

export default Personal;