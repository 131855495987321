import React, { Component } from "react";
import Layoutone from "../layouts/Layoutone";
import { Link } from "react-router-dom";

class Home extends Component {
  render() {
    let nbsp = "\u00A0";
    return (
      <Layoutone>
        <div className="col-12 col-md-6">
          <h3>Babysitting Made Simple</h3>
        </div>
          <Link className="col-12 col-md-6" to="/check-email?usertype=1" > 
          <button className="btn-pink">
              I want to hire a sitter
          </button>
          </Link>
          <Link className="col-12 col-md-6" to="/check-email?usertype=2" > 
         <button className="btn-pink">
              I want to babysit
         </button>
          </Link>
        <h6><Link to='/faq' > FAQ</Link> </h6>
        <h5>
          Already have an account?
          {nbsp}{nbsp}<Link to='/login' className="text-yellow"> Log In</Link> 
        </h5>
        <div></div>
        <h6><Link to="/term-sitter"  target="_blank"> Terms of Use </Link>
        <span className="text-white-size">and our </span> <Link to="/privacy-policy"  target="_blank"> Privacy Policy</Link>
        </h6>
      </Layoutone>
    );
  }
}

export default Home;
