/* eslint-disable eqeqeq */
import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import Layouttwo from '../../../layouts/Layouttwo';
import { Modal } from 'react-bootstrap';
import AvatarPlaceholder from '../../../assets/images/avatar.png';
import S3 from 'react-aws-s3';
import Api from '../../../context/Api';
import { toast } from 'react-toastify';
import moment from 'moment'
import Cookies from 'js-cookie'
import ReactS3Client from '../../../services/S3bucket';
import { Link } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import shortid from 'shortid';


class SitterSignup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp_token:window.localStorage.getItem('otp_token'),
            firstname:'',
            lastname:'',
            parentname:'',
            password:'',
            phone_number: "",
            religion:null,
            address:'',
            city:'',
            state:'',
            zip_code:'',
            hourrate:'',
            profile_pic:AvatarPlaceholder,
            sitter_signature:AvatarPlaceholder,
            dob:'',
            school_name:[''],
            about:'',
            age:18,
            transportation:'',
            references:[{ f_name: "", l_name: "",country_code:'+1',phone:'' },{ f_name: "", l_name: "",country_code:'+1',phone:'' }],
            profile_url:AvatarPlaceholder,
            signature_url:AvatarPlaceholder,
            email:window.localStorage.getItem('email'),
            terms:'',
            loading:false,
            error:[],
            uploading:false,
            showSignatureModal: false,
            imageUrl: null,
        }
        this.sigCanvas = null;
        this.elementRef = React.createRef();
    }

    dataURLtoBlob = (dataURL) => {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        // const b64 = atob(parts[1]);
        const b64 = Buffer.from(parts[1], 'base64').toString('binary');
        let uInt8Array = new Uint8Array(b64.length);
        for (let i = 0; i < b64.length; i++) {
          uInt8Array[i] = b64.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
      }

    handleShowSignatureModal = () => {
        this.setState({ showSignatureModal: true });
      }
    
      handleCloseSignatureModal = () => {
        this.setState({ showSignatureModal: false });
      }
    
      handleClearSignature = () => {
        if (this.sigCanvas) {
          this.sigCanvas.clear();
        }
      }
    
      handleCreateSignature = () => {
        if (this.sigCanvas) {
          const signatureDataUrl = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
          // Do something with the signature data URL, e.g. save it to state
          const trimmedBlob = this.dataURLtoBlob(signatureDataUrl);
          const trimmedFile = new File([trimmedBlob], `signature_${shortid.generate()}.png`, { type: 'image/png' });

        //   console.log('Signature name :', trimmedFile.name);
          this.setState({ loading:true, imageUrl: signatureDataUrl });
          //let dataURL = signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png');
          //let file = dataURLtoFile(dataURL, 'signature.png');

        ReactS3Client.uploadFile(trimmedFile, trimmedFile.name)
           .then((res) => {
               this.setState({ loading: false, sitter_signature: res.location });
            })
           .catch((error) => {
            //    console.log(error);
           });
        }
        this.handleCloseSignatureModal();
      }

    handlePlus = (e,index) => {
        if(this.state.school_name.length < 6){
            const School = this.state.school_name;
            this.setState({ school_name: [...School,'']});
        }
    } 
    handleMinus = (e,index) => {
        if(this.state.school_name.length > 1){
            const School = this.state.school_name;
            const Remove = index;
            this.setState({ school_name: School.filter((item, index) => index !== Remove) });
           
        } 
    } 


    handleProfileImage = (e) => {
        var File = e.target.files[0];
        if(File !== undefined){
            if(File.type.match('image/jpeg')||File.type.match('image/jpg')||File.type.match('image/png')||File.type.match('image/gif')||File.type.match('image/webp')){
                var reader = new FileReader();
                var url = reader.readAsDataURL(File);
                this.setState({
                    uploading: true,
                    url: URL.createObjectURL(File),
                });
                ReactS3Client.uploadFile(File, File.name)
                .then((res) => {
                    this.setState({ uploading: false, profile_pic: res.location });
                    //console.log(res.location);
                })
                .catch((error) => {
                    //console.log(error);
                });
            }else{
                e.target.value = null;
                File = e.target.files[0];
                toast("The selected file is not an image or allowed image file type. Please select an image file.", { type: "error" });
            }
        }
    }

    // handleSitterSignature = (e) => {
        
    //     var File = e.target.files[0];
    //     if(File !== undefined){
    //         if(File.type.match('image/jpeg')||File.type.match('image/jpg')||File.type.match('image/png')){
    //             var reader = new FileReader();
    //             var url = reader.readAsDataURL(File);
    //             this.setState({
    //             uploading: true,
    //             url: URL.createObjectURL(File),
    //             });
    //             ReactS3Client.uploadFile(File, File.name)
    //             .then((res) => {
    //                 this.setState({ uploading: false, sitter_signature: res.location });
    //                 //console.log(res.location);
    //             })
    //             .catch((error) => {
    //                 //console.log(error);
    //             });
    //         }else{
    //             e.target.value = null;
    //             File = e.target.files[0];
    //             toast("File is not an image file or allowed image file type. Please create signature and try again.", { type: "error" });
    //         }
    //     }
    // }

    handleFirstname = (e) => {
        const firstNamePattern = /^[a-zA-Z0-9\s]+$/
        
        if(!firstNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.trim().length<2 || e.target.value.trim().length>40){
            if(e.target.value.trim().length === 0){
                this.setState({firstname: e.target.value, error: { firstname: "First name is required." } });
            } else if(!firstNamePattern.test(e.target.value)){
                this.setState({firstname: e.target.value, error: { firstname: "First name should consist of alphabets only." } });
            } else if(e.target.value.trim().length<2){
                this.setState({firstname: e.target.value, error: { firstname: "First name should be 2 characters or more." } });
            } else if(e.target.value.trim().length>40){
                this.setState({firstname: e.target.value, error: { firstname: "First name should be 40 characters or less than 40 characters." } });
            }
        }else {
            this.setState({ firstname: e.target.value, error: { firstname: "" } });
        }
    }
    handleLastname = (e) => {
        const lastNamePattern = /^[a-zA-Z0-9\s]+$/
        if(!lastNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length<2 || e.target.value.length>40){
            if(e.target.value.trim().length === 0){
                this.setState({lastname: e.target.value, error: { lastname: "Last name is required." } });
            } else if(!lastNamePattern.test(e.target.value)){
                this.setState({lastname: e.target.value, error: { lastname: "Last name should consist of alphabets only." } });
            } else if(e.target.value.trim().length<2){
                this.setState({lastname: e.target.value, error: { lastname: "Last name should be 2 characters or more." } });
            } else if(e.target.value.trim().length>40){
                this.setState({lastname: e.target.value, error: { lastname: "Last name should be 40 characters or less than 40 characters." } });
            }
        } else{
            this.setState({ error: { lastname: "" }, lastname: e.target.value });
        }
    }

    handleParentname = (e) => {
        const parentNamePattern = /^[a-zA-Z0-9\s]+$/

        if(!parentNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.trim().length<2 || e.target.value.trim().length>40){
            if(e.target.value.trim().length === 0){
                this.setState({parentname: e.target.value, error: { parentname: "Parent/Guardian name is required." } });
            } else if(!parentNamePattern.test(e.target.value)){
                this.setState({parentname: e.target.value, error: { parentname: "Parent/Guardian name should consist of alphabets only." } });
            } else if(e.target.value.trim().length<2){
                this.setState({parentname: e.target.value, error: { parentname: "Parent/Guardian name should be 2 characters or more." } });
            } else if(e.target.value.trim().length>40){
                this.setState({parentname: e.target.value, error: { parentname: "Parent/Guardian name should be 40 characters or less than 40 characters." } });
            }
        }else if(e.target.value.trim().length>1 && e.target.value.trim().length<41){
            this.setState({ parentname: e.target.value, error: { parentname: "" } });
        }
    }
    handlePassword = (e) => {
        const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/
        if(!passwordPattern.test(e.target.value) || e.target.value.length<6) {
        this.setState({ error: { password:"\nPassword must be at least 6 characters in length with minimum 1 uppercase, 1 lowercase, 1 symbol and 1 number characters." } ,password: e.target.value })
        } else{
        this.setState({ password: e.target.value, error: { password: "" }} );
        }
    }
    //handleReligion = (e) => {this.setState({religion:e.target.value})}
    handleHourrate = (e) => {
        const hRatepattern=/^[0-9][0-9]*$/
        if(e.target.value.length===0 || !hRatepattern.test(e.target.value) || e.target.value.length>3 || e.target.value.length<1 || e.target.value < 1 || e.target.value > 999){
            if(!hRatepattern.test(e.target.value)){
                this.setState({hourrate: e.target.value, error: { hourlyRate: "Hourly rate should consist of numbers only." } });
            }if(e.target.value.length===0){
                this.setState({hourrate: e.target.value, error: { hourlyRate: "Hourly rate is required." } });
            }if(e.target.value<1 || e.target.value>999){
                this.setState({ hourrate: e.target.value, error: { hourlyRate: "Hourly rate should be between $1 to $999." } });
            }if(e.target.value.length>3 || e.target.value.length<1){
                this.setState({ hourrate: e.target.value, error: { hourlyRate: "Hourly rate is required and can be upto 3 digits only." } });
            }   
        } else{
                this.setState({hourrate:e.target.value, error: { hourlyRate: "" }})
            }
    }
    handlePhoneNumber = (e) => {
        const PhoneNumberPattern = /^[2-9][0-9]*$/
        if(!PhoneNumberPattern.test(e.target.value) || e.target.value.length === 0 || e.target.value.length>11){
          if(e.target.value.length === 0){
            this.setState({phone_number: e.target.value, error: { phone_number: "Phone number is required." } });
          } else if(!PhoneNumberPattern.test(e.target.value)){
            this.setState({phone_number: e.target.value, error: { phone_number: "Phone number should consist of 10 numbers only." } });
          } else if(e.target.value.length<10){
            this.setState({phone_number: e.target.value, error: { phone_number: "Phone number should consist of 10 numbers." } });
          } else if(e.target.value.length>11){
            this.setState({phone_number: e.target.value, error: { phone_number: "Phone number should consist of 10 numbers." } });
          }
        } else{
          this.setState({ error: { phone_number: "" }, phone_number: e.target.value });
        }
      };
    handleAddress = (e) => {
        if( e.target.value.trim().length===0 || e.target.value.trim().length<5 || e.target.value.trim().length>250){
        if(e.target.value.trim().length===0)  {
          this.setState({address: e.target.value, error: { address: "Address is required." } });
          return null;
        } else if(e.target.value.trim().length<5){
          this.setState({address: e.target.value, error: { address: "Address should be 5 characters or more." } });
          return null;
        } else if(e.target.value.length.trim()>250){
          this.setState({address: e.target.value, error: { address: "Address should be 250 characters or less than 250 characters." } });
          return null;
        }
      } else{
        this.setState({ address: e.target.value, error: { address: "" } });
      }
    }
    handleCity = (e) => {
        const cityPattern = /^[a-zA-Z0-9\s]+$/
        if( e.target.value.trim().length===0 || !cityPattern.test(e.target.value) || e.target.value.trim().length<2 || e.target.value.trim().length>40){
            if(e.target.value.trim()===0){
                this.setState({city: e.target.value , error: { city: "City name is required." } });
            } else if(!cityPattern.test(e.target.value)){
                this.setState({city: e.target.value , error: { city: "City name should consist of alphabets only." } });
            } else if(e.target.value.trim().length<2){
                this.setState({city: e.target.value , error: { city: "City name should be 2 characters or more." } });
            } else if(e.target.value.trim().length>40){
                this.setState({ city: e.target.value , error: { city: "City name should be 40 characters or less than 40 characters." } });
            }
        } else{
            this.setState({ city: e.target.value , error: { city: "" } });
        }
    };
    handleState = (e) => {
        const statePattern = /^[a-zA-Z0-9\s]+$/
        if(e.target.value.trim().length===0 || !statePattern.test(e.target.value) || e.target.value.trim().length<2 || e.target.value.trim().length>40){
            if(e.target.value.trim().length===0){
            this.setState({ state: e.target.value, error: { addressState: "State name is required." } });
            }else if(!statePattern.test(e.target.value)){
            this.setState({ state: e.target.value, error: { addressState: "State name should consist of alphabets only." } });
            } else if(e.target.value.trim().length<2){
            this.setState({ state: e.target.value , error: { addressState: "State name should be 2 characters or more." } });
            } else if(e.target.value.trim().length>40){
            this.setState({ state: e.target.value, error: { addressState: "State name should be 40 characters or less than 40 characters." } });
            }
        } else{
      this.setState({ state: e.target.value , error: { state: "" } });
        }
    };
    
    handleZipCode = (e) => {
        const zipPattern = /^[0-9][0-9]*$/
    //console.log('match or not ',zipPattern.test(e.target.value));
        if(e.target.value.length===0 || !zipPattern.test(e.target.value) || e.target.value.length<5 || e.target.value.length>5){
            if(e.target.value.length===0){
                this.setState({zip_code: e.target.value, error: { zip: "Zip code is required." } });
            }if(!zipPattern.test(e.target.value)){
                this.setState({zip_code: e.target.value, error: { zip: "Zip code should consist of numbers only." } });
            } else if(e.target.value.length<5 || e.target.value.length>5){
                this.setState({ zip_code: e.target.value, error: { zip: "Zip code should consist of 5 numbers only." } });
            }
        } else{
        this.setState({ zip_code: e.target.value, error: { zip: "" } });
        }
    };
    handleLanguage = (e) => {
        this.setState({language:e.target.value })
    }
    handleSchoolname = (e,index) => {
        const Schools = [...this.state.school_name];
        const schoolNamePattern = /^[a-zA-Z0-9\s]+$/
        if(!schoolNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.trim() === "" || e.target.value === undefined || e.target.value.length < 2 || e.target.value.length > 40){
            Schools[index] = e.target.value;
            this.setState({school_name:Schools, error: { school: "Schoolname is required." } });
            return null;
        }
        Schools[index] = e.target.value;
        this.setState({school_name:Schools, error: { school: "" } });
        
    }
    handleAbout = (e) => {
        // e.target.value = e.target.value.trim()
        // let aboutPattern = /^(?! ).*[^ ]$/
        // console.log('about before state is: ',e.target.value.length)
        if(e.target.value.trim().length === 0 || e.target.value.trim() === "" || e.target.value === undefined || e.target.value.length < 5 || e.target.value.length > 600){
            this.setState({about:e.target.value, error: { about: "Introduction about yourself is required." } })
            return null;
        }
        this.setState({about:e.target.value, error: { about: ""} })
    }       
    handledob = (e) => {
    
        let age = moment().diff(e.target.value,'years');

        if (e.target.validity.valueMissing || e.target.value.length===0 || e.target.value.length === "" || e.target.value.length === undefined) {
            e.target.setCustomValidity('Please provide your birthdate.');
            this.setState({dob:e.target.value, age:age, error: { dob: "DOB is required." } });   
        } else if(e.target.validity.rangeUnderflow || e.target.value < moment().subtract(60,'years').format('YYYY-MM-DD')) {
            e.target.setCustomValidity('You are too old to be a sitter.');
            this.setState({dob:e.target.value, age:age, error: { dob: "You are too old to be a sitter." } });   
            return null;
        } else if(e.target.validity.rangeOverflow || e.target.value > moment().subtract(13,'years').format('YYYY-MM-DD')){
            e.target.setCustomValidity('You are too young to be a sitter.');
            this.setState({dob:e.target.value, age:age, error: { dob: "You are too young to be a sitter." } });   
            return null;
        } else {
            e.target.setCustomValidity('');
            this.setState({dob:e.target.value, age:age, error: { dob: "" } });  
        }
        //console.log(e.target.value,age);
        //this.setState({dob:e.target.value,age:age});
    
    }

    handleReferences = (e,index) => {
        const References = [...this.state.references];
        
        if (e.target.name == 'f_name') {
            const firstNamePattern = /^[a-zA-Z0-9\s]+$/
            if(!firstNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length === "" || e.target.value.length === undefined){
                if(!firstNamePattern.test(e.target.value)){
                    References[index]['f_name'] = e.target.value;
                    this.setState({references: References, error: { refFname: "Reference first name can only have alphabets." } });
                    return null;
                }
                References[index]['f_name'] = e.target.value;
                this.setState({references: References, error: { refFname: "Reference first name is required." } });
                return null;
            }
                References[index]['f_name'] = e.target.value.trim();
                this.setState({ references: References, error: { refFname: "" }});
            //console.log(this.state.child_age);
        } else if (e.target.name == 'l_name') {
            const lastNamePattern = /^[a-zA-Z0-9\s]+$/
            if(!lastNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length === "" || e.target.value.length === undefined){
                if(!lastNamePattern.test(e.target.value)){
                    References[index]['l_name'] = e.target.value;
                    this.setState({references: References, error: { refFname: "Reference last name can only have alphabets." } });
                    return null;
                }
                References[index]['l_name'] = e.target.value.trim();
                this.setState({references: References, error: { refLname: "Reference last name is required." } });
                return null;
            }
                References[index]['l_name'] = e.target.value.trim();
                this.setState({ references: References, error: { refLname: "" }});
        } else if(e.target.name == 'phone'){
            let phonePattern = /^[2-9][0-9]*$/
            if(e.target.value.length === 0 || e.target.value.length === "" || e.target.value.length === undefined  || e.target.value.length < 10){
                References[index]['phone'] = e.target.value;
                this.setState({references: References, error: { refPhone: "Reference phone detail is required." } });
                return null;
            } else if(!phonePattern.test(e.target.value)){
                References[index]['phone'] = e.target.value;
                this.setState({references: References, error: { refPhone: "Reference phone detail should be a number." } });
                return null;
            }
            References[index]['phone'] = e.target.value.trim();
            this.setState({ references: References, error: { refPhone: "" }});
        }
    }

    handleTransportation = (e) => {
        if(e.target.value.length === 0 || e.target.value === "" || e.target.value === undefined ||  (e.target.value !== 'I drive my own car.' &&  e.target.value !== 'I can take care of my own transportation.' &&  e.target.value !== 'I need one-way transportation.' &&  e.target.value !== 'I need two-way transportation.')){
            this.setState({transportation : e.target.value, error: { transportation: "Select a transportation option." } });
            return null;
        }else{
            this.setState({transportation : e.target.value, error: { transportation: "" }});
        }
    }

    termsAccepted = (e) => {
        const  {checked }= e.target;
        if(checked){
            this.setState({ terms: "yes", error: { terms: "" } });
        }else{
            this.setState({ terms: "no", error: { terms: "In order to use our services you need to agree to our Terms of Use." } });
        }
    }

    handleSubmit =(e) => {
        e.preventDefault();
        
      if(this.state.firstname.trim().length === 0 || this.state.firstname.trim().length<2 || this.state.firstname.trim().length>40 || this.state.firstname === "" || this.state.firstname === undefined){
            this.setState({ error: { firstname: "Please enter first name" } });
            toast("Please enter first name", { type: "error" });
            return null;
      }
      if(this.state.lastname.trim().length === 0 || this.state.lastname.trim().length<2 || this.state.lastname.trim().length>40 || this.state.lastname === "" || this.state.lastname === undefined){
        this.setState({ error: { lastname: "Please enter last name" } });
        toast("Please enter last name", { type: "error" });
        return null;
      }
      if(this.state.phone_number.length === 0 || this.state.phone_number === "" || this.state.phone_number === undefined){
        this.setState({ error: { phone_number: "Please enter phone number" } });
        toast("Please enter phone number", { type: "error" });
        return null;
      }
      if(this.state.password || this.state.password.length === 0 || this.state.password || this.state.password === ""){
        const pwdCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/
        if(!pwdCheck.test(this.state.password)){
          this.setState({ error: { password: "Please enter a password as per password pattern instructions" } });
          toast("Please enter a password as per password pattern instructions", { type: "error" });
          return null;
        }
        else if(this.state.password.length === 0 || this.state.password === ""){
          this.setState({ error: { password: "Please enter a password" } });
          toast("Please enter a password and follow password pattern instructions.", { type: "error" });
          return null;
        }
      }
      if(this.state.address.trim().length === 0 || this.state.address.trim().length<2 || this.state.address.trim().length>40 || this.state.address === "" || this.state.address === undefined){
        this.setState({ error: { address: "Please provide an address" } });
        toast("Please provide an address.", { type: "error" });
        return null;
      }
      if(this.state.state.trim().length === 0 || this.state.state.trim().length<2 || this.state.state.trim().length>40 || this.state.state === "" || this.state.state === undefined){
        this.setState({ error: { addressState: "Please provide a state" } });
        toast("Please provide a state.", { type: "error" });
        return null;
      }
      if(this.state.city.trim().length === 0 || this.state.city.trim().length<2 || this.state.city.trim().length>40 || this.state.city === "" || this.state.city === undefined){
        this.setState({ error: { city: "Please provide a city" } });
        toast("Please provide a city.", { type: "error" });
        return null;
      }
      if(this.state.zip_code || this.state.zip_code.length === 0 || this.state.zip_code === "" || this.state.zip_code === undefined || this.state.zip_code || this.state.zip_code.length<5 || this.state.zip_code.length>5){
        const zipPattern = /^[0-9][0-9]*$/
        //console.log('match or not ',zipPattern.test(this.state.zip_code));
          if(!zipPattern.test(this.state.zip_code)){
              this.setState({zip_code: this.state.zip_code, error: { zip: "Zip code is required and should consist of 5 numbers only." } });
              toast("Zip code should consist of 5 numbers only.", { type: "error" });
              return null;
          }
          if(this.state.zip_code.length===0){
            this.setState({zip_code: this.state.zip_code, error: { zip: "Zip code is required." } });
            toast("Please enter a Zip code.", { type: "error" });
            return null;
          }
          if(this.state.zip_code.length<5 || this.state.zip_code.length>5){
            this.setState({ zip_code: this.state.zip_code, error: { zip: "Zip code should consist of 5 numbers only." } });
            toast("Please provide a 5 number Zip code.", { type: "error" });
            return null;
          }
      }
    if(this.state.hourrate.length === 0 || this.state.hourrate.length>3 || this.state.hourrate.length<1 || this.state.hourrate < 1 || this.state.hourrate > 999){
            if(this.state.hourrate.length === 0){
                this.setState({error: { hourlyRate: "Hourly rate is required." } });
                toast("Please provide your hourly rate.", { type: "error" });
            }if(this.state.hourrate<1 || this.state.hourrate>999){
                this.setState({error: { hourlyRate: "Hourly rate should be between $1 to $999." } });
                toast("Your hourly rate should be between $1 to $999", { type: "error" });
            }if(this.state.hourrate.length>3 || this.state.hourrate.length<1){
                this.setState({error: { hourlyRate: "Hourly rate is required and can be upto 3 digits only." } });
                toast("Your hourly rate is required and it should be between $1 to $999", { type: "error" });
            }   
    }
    if (this.state.dob.length === 0 || this.state.dob.length === "" || this.state.dob.length === undefined || this.state.dob < moment().subtract(60,'years').format('YYYY-MM-DD') || this.state.dob > moment().subtract(13,'years').format('YYYY-MM-DD')) {
        if(this.state.dob.length === 0 || this.state.dob.length === "" || this.state.dob.length === undefined){
            this.setState({ error: { dob: "DOB is required." } });   
            toast("Please provide your DOB.", { type: "error" });
            return null;
        } else if(this.state.dob < moment().subtract(60,'years').format('YYYY-MM-DD')) {
            this.setState({ error: { dob: "You are too old to be a sitter." } });   
            toast("You are too old to be a sitter.", { type: "error" });
            return null;
        } else if(this.state.dob > moment().subtract(13,'years').format('YYYY-MM-DD')){
            this.setState({error: { dob: "You are too young to be a sitter." } });   
            toast("You are too young to be a sitter.", { type: "error" });
            return null;
        }
    }
    let schoolCount=0; //child error counter
      if(this.state.school_name.length >= 0){
        if(this.state.school_name.length < 1){
            this.setState({ error: { school: "School name is required." } });
            toast("Please provide a school name.", { type: "error" });
            return null;
        } else{
          this.state.school_name.forEach((value, index)=>{
            if(this.state.school_name[index].trim().length===0 || this.state.school_name[index]===undefined || this.state.school_name[index]===""){
              this.setState({ error: { school: "School name is required." } });
              schoolCount++;
              toast("Please provide a school name", { type: "error" });
            } });
        }
      }
      if(schoolCount>0){
        return null;
      }
    let refCount = 0; //ref error counter
    if(this.state.references.length >= 0){
        if(this.state.references.length < 2){
            toast("Please fill both the references.", { type: "error" });
            return null;
        } else {
            this.state.references.forEach((value, index)=>{
                if(this.state.references[index].f_name.trim().length===0 || this.state.references[index].f_name===undefined || this.state.references[index].f_name===""){
                    refCount++;
                    this.setState({ error: { refFname: "Reference first name is required." } }) 
                    toast("Please provide first name of your reference", { type: "error" });
                }
                else if(this.state.references[index].l_name.trim().length===0 || this.state.references[index].l_name===undefined || this.state.references[index].l_name===""){
                    refCount++;
                    this.setState({ error: { refLname: "Reference last name is required." } }); 
                    toast("Please provide last name of your reference", { type: "error" });
                }
                else if(this.state.references[index].phone.length===0 || this.state.references[index].phone===undefined || this.state.references[index].phone===""){
                    refCount++;
                    this.setState({ error: { refPhone: "Reference phone detail is required." } }); 
                    toast("Please provide phone detail of your reference", { type: "error" });
                }
            });
        }
    }
    if(refCount>0){
        return null;
    }
    if(this.state.transportation.trim().length === 0 || this.state.transportation === "" || this.state.transportation === undefined || (this.state.transportation != "I drive my own car." && this.state.transportation != "I can take care of my own transportation." && this.state.transportation != "I need one-way transportation." && this.state.transportation != "I need two-way transportation.")){
            this.setState({error: { transportation: "Select a transportation option." } });
            toast("Select a mode of transportation", { type: "error" });
            return null;
    }
    if(this.state.about || this.state.about.trim().length === 0 || this.state.about === "" || this.state.about === undefined || this.state.about.trim().length < 5 || this.state.about.trim().length > 600){
        // console.log('about before submit: ',this.state.about.length);
        if(this.state.about.trim().length === 0 || this.state.about === "" || this.state.about === undefined || this.state.about.trim().length < 5 || this.state.about.trim().length > 600){
            this.setState({error: { about: "Introduction about yourself is required." } });
            toast("Introduction about yourself is required.", { type: "error" });
            return null;
        }
    }
    if(this.state.age >= 13 && this.state.age <= 17 ){
        if(!this.state.imageUrl || !this.state.sitter_signature || this.state.sitter_signature.length===0 || this.state.sitter_signature.length==="" || this.state.sitter_signature.length===undefined){
            this.setState({error: { signature: "Parent signature is required." } });
            toast("Parent signature is required", { type: "error" });
            return null;
        }
        if(this.state.parentname.trim().length===0 || this.state.parentname.length==="" || this.state.parentname===undefined){
            this.setState({error: { parentname: "Parent/Guardian name is required." } });
            toast("Parent/Guardian name is required", { type: 'error'})
            return null;
        }
    }
    if (this.state.terms !== 'yes' || this.state.terms === 'no' || this.state.terms === '') {
        this.setState({ error: { terms: "Please accept the Terms of Use policy." } });
        toast("Please accept the Terms of Use policy", { type: "error" });
        return null;
    }


        let data = {
        
            otp_token:this.state.otp_token,
            first_name:this.state.firstname.trim(),
            last_name:this.state.lastname.trim(),
            phone_number:this.state.phone_number.trim(),
            parent_name: this.state.parentname.trim(),
            address:this.state.address.trim(),
            city: this.state.city.trim(),
            state: this.state.state.trim(),
            zip_code: this.state.zip_code.trim(),
            hourly_rate:this.state.hourrate.trim(),
            password:this.state.password,
            device_id:"wy3te6ge374y38y3uf834y3u89ryu48f",
            device_type:"1",
            device_token:"q2w3e4er5t6y7u8i9o0m,m",
            religion:this.state.religion,
            image:(this.state.profile_pic == AvatarPlaceholder) ? '' : this.state.profile_pic  ,
            signature:(this.state.sitter_signature == AvatarPlaceholder) ? '' : this.state.sitter_signature, 
            details:this.state.about.trim(),
            references:this.state.references,
            schools:this.state.school_name,
            dob:this.state.dob,
            transportation: this.state.transportation,    
            terms: this.state.terms    
        }
        //console.log(data);
        this.setState({loading:true});

        Api.post('/auth/register/sitter',data).then((response) => {
            toast(response.data.message,{type:'success'});
            //console.log(response);
            this.setState({loading:false});
            Cookies.set('isLoggedIn',true);
            Cookies.set('role',true);
            this.props.history.push(`/login`);
        }).catch((e) => {
            this.setState({loading:false});
            toast(e.response.data.message,{type:'error'});
            // this.props.history.push(`/`);
            //console.error(e)
        })

    };


    componentDidMount() {
        this.dob.addEventListener('invalid', this.handledob);
    }
    render() {
        return (
            <>
            <Layouttwo myclass="parent-registration" ViewName="Sitter's registration" >
                <form onSubmit={this.handleSubmit} className="registration needs-validation form-control" >
                        <div className="row">
                            <div className="col-12 col-md-6 form-floating">
                            <input title="First name should be 2 characters or more." 
                                minLength={2} 
                                maxLength={40} 
                                pattern="^[a-zA-Z0-9\s]+$"
                            //     onKeyDown={(e) => {
                            // var x = e.key;
                            // if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                            //             e.preventDefault();
                            //         }
                            //     }}
                                onChange={this.handleFirstname} value={this.state.firstname} 
                                type="text" className="form-control" name="f_name" id="f_name" placeholder="First name" 
                                required />
                                <label htmlFor="f_name">First name</label>
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.firstname ?? ""}{" "}
                                </small>
                            </div>
                            <div className="col-col-12 col-md-6 form-floating">
                            <input title="Last name should be 2 characters or more." minLength={2} maxLength={40} pattern="^[a-zA-Z0-9\s]+$"
                                // onKeyDown={(e) => {
                                //     var x = e.key;
                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                //         e.preventDefault();
                                //     }
                                // }}
                                onChange={this.handleLastname} value={this.state.lastname} type="text" className="form-control" name="l_name" id="l_name" placeholder="Last name" required />
                                <label htmlFor="l_name">Last name</label>
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.lastname ?? ""}{" "}
                                </small>
                            </div>
                            <div className="col-col-12 col-md-6 form-floating">
                                <input readOnly type="email" className="form-control" name="email" id="email" placeholder="Email" value={this.state.email} required />
                                <label htmlFor="email">Email</label>

                            </div>
                            <div className="col-12 col-md-6 form-floating">
                                <input  title="Use 6 or more characters with a mix of letters, numbers & symbols. Example: Abcde$1" type="password" minLength={6} maxLength={15}  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$" onChange={this.handlePassword} value={this.state.password}  className="form-control" name="password" id="password" placeholder="Password" required />
                                <label htmlFor="password">Password</label>
                                <small className="text-pink">Password must be at least 6 characters in length with minimum 1 uppercase, 1 lowercase, 1 symbol from @#$%^&-+= and 1 number characters.</small>
                                <small className="text-danger">
                                    <br></br>{""}
                                    {this.state.error.password ?? ""}{" "}
                                </small>
                            </div>
                            <div className='col-12 col-md-12 form-floating'>
                            <div className='row'>
                                <div className="col-4 col-sm-4 col-md-4 ref_ccode">
                                <select
                                    title='Country Code'
                                    name="country_code"
                                    className="c_code"
                                >
                                    {/* <option defaultValue={(value.country_code == '+91') } value="+91">+91 </option> */}
                                    <option defaultValue={'+1'} value="+1">+1</option>
                                </select>
                                </div>
                                <div className="col-8 col-md-8 col-lg-6 col-xl-6 form-floating">
                                <input
                                    title="Please enter exactly 10 digits with correct area code."
                                    inputMode="numeric"
                                    pattern="^[2-9][0-9]*$"
                                    maxLength={10}
                                    minLength={10}
                                    onChange={(e) =>
                                    this.handlePhoneNumber(e)
                                    }
                                    type="text"
                                    value={this.state.phone_number}
                                    className="form-control"
                                    name="phone_number"
                                    id="phone_number"
                                    placeholder="Phone number"
                                    required={true}
                                />
                                <label htmlFor="phone_number">Your Phone Number (10 digits)</label>
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.phone_number ?? ""}{" "}
                                </small>
                                </div>
                            </div>
                            </div>
                            {/* <div className="col-12 col-md-6 form-floating">
                            <input title="Please enter a religion without digits." minLength={2} maxLength={15} pattern="\^[a-zA-Z\s]+$\"
                                 onKeyDown={(e) => {
                                    var x = e.key;
                                    if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={this.handleReligion} value={this.state.religion} type="text" className="form-control" name="religion" id="religion" placeholder="Religion" required />
                                <label htmlFor="religion">Religion</label>

                            </div> */}
            <div className="col-9 form-floating">
              <input
                // title="Please do not start address with a whitespace."
                minLength={5}
                maxLength={250}
                // pattern="^/S.*"
                onChange={this.handleAddress}
                type="text"
                className="form-control"
                name="address"
                id="address"
                placeholder="Address"
                required
              />
              <label htmlFor="address">Address</label>
              <small className="text-danger">
                {" "}
                {this.state.error.address ?? ""}{" "}
              </small>
            </div>
            <div className="col-4 form-floating">
              <input
                title="Please enter a city name without digits and not more than one space between two words."
                minLength={2}
                maxLength={40}
                pattern="^[a-zA-Z0-9\s]+$"
                // onKeyDown={(e) => {
                //     var x = e.key;
                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                //         e.preventDefault();
                //     }
                // }}
                onChange={this.handleCity}
                type="text"
                className="form-control"
                name="city"
                id="city"
                placeholder="City"
                required
              />
              <label htmlFor="city">City</label>
              <small className="text-danger">
                {" "}
                {this.state.error.city ?? ""}{" "}
              </small>
            </div>
            <div className="col-4 form-floating">
              <input
                title="Please enter a state name without digits and not more than one space between two words."
                pattern="^[a-zA-Z0-9\s]+$"
                minLength={2}
                maxLength={40}
                // onKeyDown={(e) => {
                //     var x = e.key;
                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                //         e.preventDefault();
                //     }
                // }}                
                onChange={this.handleState}
                type="text"
                className="form-control"
                name="state"
                id="state"
                placeholder="State"
                required
              />
              <label htmlFor="state">State</label>
              <small className="text-danger">
                {" "}
                {this.state.error.addressState ?? ""}{" "}
              </small>
            </div>
            <div className="col-4 form-floating">
              <input
              required="required"
                title="Please enter 5 digits only."
                inputMode="numeric"
                minLength={5}
                maxLength={5}
                pattern="^[0-9][0-9]*$"
                onChange={this.handleZipCode}
                type="text"
                className="form-control"
                name="zipCode"
                id="zipCode"
                placeholder="Zip Code"
              />
              <label htmlFor="zipCode">Zip Code</label>
              <small className="text-danger">
                {" "}
                {this.state.error.zip ?? ""}{" "}
              </small>
            </div>

                            <div className="col-12 col-md-6 form-floating">
                                <input inputMode="numeric" title="Please enter digits only and hourly rate should be $1 or more." minLength={1} maxLength={3} min={1} max={999} pattern="^[1-9][0-9]*$" onChange={this.handleHourrate} value={this.state.hourrate} type="text" className="form-control" name="h_rate" id="h_rate" placeholder="Hourly rate" required />
                                <label htmlFor="h_rate">Hourly rate ($)</label>
                                <small className="text-pink">(You will receive 93% of it)</small>
                                <div></div>
                                <small className='text-pink'>Payment will be sent every Friday for the jobs done each week</small>
                                <div></div>
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.hourlyRate ?? ""}{" "}
                                </small>
                            </div>
                            <div className="col-12 pt-2 ">
                                <h4 className="text-blue">Transportation</h4>
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                    <ul className="list-item spacing">
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I drive my own car."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-car"
                                            name="transportation"
                                            value="I drive my own car."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-car">
                                            I drive my own car.
                                        </label>
                                    </li>
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I can take care of my own transportation."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-own"
                                            name="transportation"
                                            value="I can take care of my own transportation."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-own">
                                            I can take care of my own transportation.
                                        </label>
                                    </li>
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I need one-way transportation."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-one"
                                            name="transportation"
                                            value="I need one-way transportation."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-one">
                                            I need one-way transportation.
                                        </label>
                                    </li>
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I need two-way transportation."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-two"
                                            name="transportation"
                                            value="I need two-way transportation."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-two">
                                            I need two-way transportation.
                                        </label>
                                    </li>
                                    </ul>
                                    <small className="text-danger">
                                        {" "}
                                        {this.state.error.transportation ?? ""}{" "}
                                    </small>
                                </div>
                            </div>     
                            
                            <div className="col-12 upload-profile-picture">
                                <img src={this.state.profile_pic} alt=""/>
                                <span className="h4 text-blue">Upload photo <span className="text-grey">(optional)</span></span>
                                <input onChange={this.handleProfileImage} type="file"  accept="image/jpg, image/png, image/jpeg" name="profile_pic" id="profile_pic" />
                                <div><small className="text-pink" >Only jpg, jpeg, gif, webp and png image file formats are allowed.</small></div>
                            </div>
                            <div className="col-12 col-md-7 pt-3">
                                <h4 className="text-blue">
                                    Birthday
                                </h4>
                                <input title="Please enter appropriate birthdate." ref={elem => this.dob = elem} max={moment().subtract(13,'years').format('YYYY-MM-DD')} autoComplete="off" type="date" name="dob" onChange={this.handledob}  min={moment().subtract(60,'years').format('YYYY-MM-DD')} id="dob" required/>
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.dob ?? ""}{" "}
                                </small>
                            </div>
                            <div className="col-12 pt-2">
                                <h4 className="text-blue m-0">
                                    School Attended (attends)
                                </h4>
                            </div>
                            <div className="col-12 my-2">
                                { this.state.school_name.map((value,index) => {
                                    return (
                                    <div key={index} className='row'>
                                    <div className="col-10" >
                                    <input title="Please provide a school name." minLength={2} maxLength={40}
                                        onChange={event => this.handleSchoolname(event, index)} type="text" className="form-control" name="schools_attended" 
                                        value={this.state.school_name[index]} pattern="^[a-zA-Z0-9\s]+$" id="schools_attended" placeholder=" School name " required />
                                    </div>
                                    <div className="col-1" >
                                        <button onClick={event => this.handleMinus(event,index)} className="number-control" id="count-minus" type="button"><i className="fa fa-minus-circle"></i></button> 
                                    </div>
                                    <div className="col-1" >
                                    <button onClick={event => this.handlePlus(event,index)} className="number-control" id="count-plus" type="button"><i className="fa fa-plus-circle"></i></button>    
                                    </div>  
                                </div>);
                                }) }
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.school ?? ""}{" "}
                                </small>
                            </div>
                            <div className="col-12">
                                <span className="h4 text-blue ">References</span>
                                <div className="row references py-2">
                                {this.state.references.map((value,index)=> {
                                        return (<div key={index}>
                                        <div className="col-12">
                                        <h5 className="text-grey mb-0">Reference {index+1}</h5>
                                    </div>
                                    <div className="col-12 col-md-6 form-floating">
                                                <input title="Please enter a proper reference first name and do not use digits." pattern="^[a-zA-Z0-9\s]+$" minLength={2} maxLength={40}
                                                // onKeyDown={(e) => {
                                                //     var x = e.key;
                                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                //         e.preventDefault();
                                                //     }
                                                // }}   
                                                onChange={event => this.handleReferences(event, index)} type="text" className="form-control" name="f_name" id={"ref"+(index+1)+"f_name"} placeholder="First name" required />
                                        <label htmlFor={"ref"+(index+1)+"f_name"}>First name</label>
                                    </div>
                                    <div className="col-12 col-md-6 form-floating">
                                                <input title="Please enter a proper reference last name and do not use digits." pattern="^[a-zA-Z0-9\s]+$" minLength={2} maxLength={40}
                                                // onKeyDown={(e) => {
                                                //     var x = e.key;
                                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                //         e.preventDefault();
                                                //     }
                                                // }}   
                                                onChange={event => this.handleReferences(event, index)} type="text" className="form-control" name="l_name" id={"ref"+(index+1)+"l_name"} placeholder="Last name" required />
                                        <label htmlFor={"ref"+(index+1)+"l_name"}>Last name</label>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-3 col-4 ref_ccode">
                                        <select onChange={event => this.handleReferences(event,index)} name="country_code"  className="c_code"> 
                                            <option value="+1">+1</option>
                                            {/* <option value="+91">+91</option> */}
                                        </select>
                                    </div>
                                    <div className="col-xs-6 col-sm-7 col-md-6 col-8 form-floating">
                                        <input  title="Please enter exactly 10 digits with correct area code." pattern="^[2-9][0-9]*$" inputMode="numeric" maxLength={10} minLength={10} onChange={event => this.handleReferences(event,index)} type="text" className="form-control" name="phone" id={"ref"+(index+1)+"_phone"} placeholder="Name" required />
                                        <label htmlFor={"ref"+(index+1)+"_phone"}>Number</label>
                                    </div>
                                        </div>);
                                    })}
                                    <small className="text-danger">
                                        {" "}
                                        {this.state.error.refFname ?? ""}{" "}
                                    </small>
                                    <small className="text-danger">
                                        {" "}
                                        {this.state.error.refLname ?? ""}{" "}
                                    </small>
                                    <small className="text-danger">
                                        {" "}
                                        {this.state.error.refPhone ?? ""}{" "}
                                    </small>
                                </div>
                            </div>
                            <div className="col-12 py-2">
                                <h4 className="text-blue">
                                    Tell parents a little bit about yourself.
                                </h4>
                                <textarea defaultValue={this.state.about} onChange={this.handleAbout} minLength={5} maxLength={600} className="form-control" name="about_sitter" id="about_sitter" rows="5" placeholder="(Include details about previous babysitting experience, your occupation, your personality, about your passion for children etc.)" required>
                                    
                                </textarea>
                                <small className="text-danger">
                                        {" "}
                                        {this.state.error.about ?? ""}{" "}
                                </small>
                            </div>
                            {(this.state.age >= 13 && this.state.age <= 17) ?  <><div className="col-12 pt-2">
                                <h4 className="text-blue">
                                    Parent’s signature
                                </h4>
                                <h6 className="text-pink">(if you are between the ages of 13-17)</h6>
                            </div>
                            <div className="col-12 py-2"onClick={this.handleShowSignatureModal}>
                                <div className="upload_sign text-blue text-center" style={{cursor:"pointer"}}>
                                    <i className="fa fa-signature"></i>
                                    <h6 >Parent's Signature</h6>
                                    {/* <small>max file size 500 kb</small> */}
                                    {/* <input onChange={this.handleSitterSignature} type="file" name="parent_sign"  accept="image/jpg, image/png, image/jpeg" id="parent_sign" required/> */}
                                    {/* <button >Create Signature</button> */}
                                </div>
                                <div><small className="text-pink">Only png image file format is allowed.</small></div>
                            </div>
                            { this.state.imageUrl ? <img src={this.state.imageUrl} alt="signature" className="signature" />
                                     : null}
                            <small className="text-danger">
                                {" "}
                                {this.state.error.signature ?? ""}{" "}
                            </small>
                            <div className="col-12 pt-2">
                                <h4 className="text-blue">
                                    Parent/Guardian printed name
                                </h4>
                            </div>
                            <div className="col-12 col-md-6 form-floating">
                                <input title="Parent/Guardian's name should be 2 characters or more." minLength={2} maxLength={40}
                                //onKeyDown={(e) => {
                                    // var x = e.key;
                                    // if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                    //         e.preventDefault();
                                    //     }
                                // }}
                                onChange={this.handleParentname} value={this.state.parentname} pattern="^[a-zA-Z0-9\s]+$" type="text" className="form-control" name="parent_name" id="parent_name" placeholder="Parent/Guardian name" required />
                                <label htmlFor="parent_name">Parent/Guardian</label>
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.parentname ?? ""}{" "}
                                </small>
                            </div>

                            </> : null }
                            <div className="col-12 py-3">
                                <label className="form-check-label" htmlFor="terms_of_use">
                                <input className="form-check-input m-1" name="terms_of_use" type="checkbox" value="" onChange={(e)=>this.termsAccepted(e)} id="terms_of_use" required />
                                    I agree to the  <Link to="/term-sitter"  target="_blank" className="text-blue"> Terms of Use </Link> 
                                <span>and to only contact Simplisitter families through <a className="text-blue" target="_blank" rel="noreferrer" href="https://simplisitter.com/#/" >simplisitter.com </a>
                                for any future babysitting jobs.</span>
                                </label>
                                <div></div>
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.terms ?? ""}{" "}
                                </small>
                                <p className="text-grey">
                                    For details on collection and use of your information, see our 
                                    <Link to="/privacy-policy"  target="_blank" className="text-blue"> Privacy Policy</Link>
                                </p>
                            </div>
                            <div className="col-12">
                            <button disabled={this.state.loading || this.state.uploading } className="btn-blue float-end blue-grad" type="submit">
                                    {(this.state.loading == true) ? 
                                    'Loading . . . .' :
                                     (this.state.uploading == true) ? 'Uploading file  . . . .'  : 'Register'  }
                                </button>
                            </div>
                        </div>
                    </form>
            </Layouttwo>

            <Modal show={this.state.showSignatureModal} onHide={this.handleCloseSignatureModal}>
            <Modal.Header closeButton>
                <Modal.Title>Parent's Signature</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <SignatureCanvas
                ref={(ref) => { this.sigCanvas = ref; }}
                penColor='black'
                canvasProps={{width: 400, height: 300, pixelratio: 2, className: 'sigCanvas'}}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-blue float-end blue-grad" variant="secondary" onClick={this.handleClearSignature}>
                Clear
                </button>
                <button className="btn-blue float-end blue-grad" variant="primary" onClick={this.handleCreateSignature}>
                Create Signature
                </button>
            </Modal.Footer>
            </Modal>
        </>
        );
    }
}

SitterSignup.propTypes = {

};

export default SitterSignup;