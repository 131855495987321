import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import instance from '../../context/AuthApi';
import { toast } from 'react-toastify';
import TokenService from '../../services/Token.Service';
import star from '../../components/Starts/star';

class Reviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Reviews:[],
            loading:true,
            UserId:TokenService.getUserId() 
        }
        this.getReviews = this.getReviews.bind(this);
    }


    componentDidMount() {
        this.getReviews();    
    }

    getReviews = () => {

        instance.get(`/user-reviews?user_id=${this.state.UserId}`).then((res) => {
            this.setState({Reviews:res.data.data,loading:false});
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
    })
    }

   




    render() {
        return (
            <section className={`dashboard ${(this.state.loading == true) ? 'loader' : ''}`}>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-8">
                    <div className="row settings-page-title p-4 pt-5">
                        <div className="col-12">
                            <Link to={`/${this.props.type}/dashboard`} className="text-grey back-button"><i className="fa fa-arrow-left"></i>Back
                            </Link>
                        </div>
                    </div>

{this.state.Reviews.map((value) => {
    return (<div className="col-12 sitters-list-item mb-2">
    <div className="row">
        <div className="col-2 col-md-1 profile-image">
            <img src={value.image} alt=""/>
        </div>
        <div className="col-10 col-md-11 d-grid">
            <h5 className="text-blue mb-1">{value.first_name} {value.last_name}</h5>

            <span> <star count={value.rating} /> </span>
            <small className="text-grey d-block pt-1">{value.review}</small>
        </div>
    </div>
</div>);
})}
                    
                    
                   
                </div>
            </div>
        </div>
    </section>
        );
    }
}

Reviews.propTypes = {

};

export default Reviews;