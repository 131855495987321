import React from "react";
import Logo from "../../assets/images/logo-1.png";  
import LogoBlue from "../../assets/images/logo-blue (1).png";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
function TopHeader({props,logotype}) {
  return (
    <header>
      <div className="container">
        <Link to={`/`}>
          <img className="logo-full" src={(logotype === 'blue') ? LogoBlue : Logo } alt=" " />
        </Link>
      </div>
    </header>
  );
}

TopHeader.propTypes = {
  logotype:PropTypes.string
}

export default TopHeader;
