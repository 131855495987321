import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <div className={`tab-pane fade pill-about-me ${(this.props.active == true) ? 'active show' :  '' }`} id="pill-about" role="tabpanel" aria-labelledby="pill-about-tab">
            <form action="#" className="edit-about-me bordered">
                <div className="row px-2">
                    <div className="col-12 pt-2">
                        <h5 className="text-blue">
                            What language do you speak at home?
                        </h5>
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <ul className="list-inline spacing">
                                <li className="list-inline-item">
                                    <input type="radio" className="form-check-input" id="validationFormCheck2" name="language" required />
                                    <label className="form-check-label" htmlFor="validationFormCheck2">English</label>
                                </li>
                                <li className="list-inline-item">
                                    <input type="radio" className="form-check-input" id="validationFormCheck3" name="language" required />
                                    <label className="form-check-label" htmlFor="validationFormCheck3">Other</label>
                                </li>
                                <li className="list-inline-item">
                                    <input type="text" className="form-control" name="language_name" id="language_name" placeholder="Language name" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <h5 className="text-blue">
                            Do you have any pets?
                        </h5>
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <ul className="list-inline spacing">
                                <li className="list-inline-item">
                                    <input type="radio" className="form-check-input" id="validationFormCheck2" name="language" required />
                                    <label className="form-check-label" htmlFor="validationFormCheck2">English</label>
                                </li>
                                <li className="list-inline-item">
                                    <input type="radio" className="form-check-input" id="validationFormCheck3" name="language" required />
                                    <label className="form-check-label" htmlFor="validationFormCheck3">Other</label>
                                </li>
                                <li className="list-inline-item">
                                    <input type="text" className="form-control" name="pets_name" id="pets_name" placeholder="List pets here" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 children-count py-1">
                        <span className="h5 text-blue pe-4">
                            Number of children 
                        </span>
                        <button className="number-control" id="count-minus" type="button"><i className="fa fa-minus-circle"></i></button>
                        <input type="number" name="children" id="children" className="number-of-children" required value="2" />
                        <button className="number-control" id="count-plus" type="button"><i className="fa fa-plus-circle"></i></button>

                    </div>
                    <div className="col-12 children-age pb-3">
                        <div className="row">
                            <div className="col-4 col-md-3 align-self-center">
                                <h5 htmlFor="" className="child-number m-0 text-grey" data-count="1">Child 1</h5>
                            </div>
                            <div className="col-8 col-md-4">
                                <select name="child-1-age" id="child-1-age">
                                    <option value="0">1 - 12 month</option>
                                    <option value="1">2-4 years</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-7 py-3">
                        <h5 className="text-blue">
                            Select amenities
                        </h5>
                        <select name="amenities" id="amenities">
                            <option value="wifi">Wi-fi</option>
                            <option value="geyser">Geyser</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-7 py-3 action-buttons">
                        <button className="btn-blue float-end blue-grad" type="submit">Save changes</button>
                        <a href="#" className="text-blue float-end">Don't save</a>
                    </div>
                </div>
            </form>
        </div>
        );
    }
}

export default About;