
import React from 'react'
import { Link } from 'react-router-dom';

const Terms = () => {
    return (
        <div >
            <h3 ><center>Privacy Policy</center></h3>

<ol start="1">
    <li>
        <p ><em><strong>Privacy/Non-Disclosure Agreement</strong></em>. Information shared with SIMPLISITTER should not be made public nor shall it be utilized other than the context of deciding whether or not to agree to work with a particular family and/or babysitter.</p>
    </li>
</ol>
	</div>
    )
}

export default Terms 