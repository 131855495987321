import React from 'react';

function Input({Type,onChange,value,placeholder,name,labelname,required =false}) {


    switch (Type) {
        case 'text':
            return (<div className="col-12 col-md-6 form-floating">
            <input onChange={onChange} value={value} type="text" className="form-control" name={name}  placeholder={placeholder} 
            required={required} />
           <label htmlFor={name}>{labelname}</label>
       </div>);
        break;
        case 'email':
            return (<div className="col-12 col-md-6 form-floating">
            <input onChange={onChange} value={value} type="email" className="form-control" name={name}  placeholder={placeholder} 
            required={required} />
           <label htmlFor={name}>{labelname}</label>
       </div>);
        break;
        case 'password':
            return (<div className="col-12 col-md-6 form-floating">
            <input onChange={onChange} value={value} type="password" className="form-control" name={name}  placeholder={placeholder} 
            required={required} />
           <label htmlFor={name}>{labelname}</label>
       </div>);
        break;
        case 'number':
            return (<div className="col-12 col-md-6 form-floating">
            <input onChange={onChange} value={value} type="number" className="form-control" name={name}  placeholder={placeholder} 
            required={required} />
           <label htmlFor={name}>{labelname}</label>
       </div>);
        break;
    
        default:
            return 'N/A';
        break;
    }

   
}

export default Input;