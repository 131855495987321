import React, { Component } from "react";
import Layoutone from "../../layouts/Layoutone";
import { Link } from "react-router-dom";
// import Input from "../../components/Forms/inputs";
import Api from "../../context/Api";
import {toast} from 'react-toastify'
// import { localeData } from "moment";
import TokenService from "../../services/Token.Service";
import LogRocket from 'logrocket';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      token: "",
      error: "",
      success: false,
      loading: false
    }
  }

  handleEmail = (e) => {this.setState({ email: e.target.value })};
  handlePassword = (e) => {this.setState({ password: e.target.value })};
  validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  }
  
  updateLogRocketUserIdentity = () => {
    const userId = TokenService.getUserId();
    const userName = TokenService.getUserFullname();
    const userEmail = TokenService.getUserEmail();
    
    if (userId) {
      LogRocket.identify(userId, {
        name: userName,
        email: userEmail,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const  data = {
      email:this.state.email,
      password:this.state.password,
      device_id:'demo--1',
      device_type:'demo--1',
      device_token:'q2w3e4er5t6y7u8i9o0',
    }
    this.setState({loading:true});

    if(this.state.email.length === 0 || this.state.email === ""){
      this.setState({loading:false});
      toast('Email is required',{type:'error'});
      return false;
    } else if(this.state.password.length === 0 || this.state.password === ""){
        this.setState({loading:false});
        toast('Password is required',{type:'error'});
        return false;
    } else{
      if (!this.validateEmail(this.state.email)) {
        // email is invalid
        this.setState({loading:false});
        toast('Invalid email format.',{type:'error'});
        return false;
      }
    }

    Api.post('auth/login',data).then((res)=>{
      //console.log(res)
      this.setState({loading:false,success:true})
      localStorage.setItem("isloggedin",true);
      localStorage.setItem("api_token",res.data.data.access_token);
      localStorage.setItem("role",res.data.data.role);
      localStorage.setItem("refresh_token",res.data.data.refresh_token);
      TokenService.setUser(res.data.data);
      // update logRocket user Identity after user login
      this.updateLogRocketUserIdentity();

      toast(res.data.message,{ type:'success' });
      switch (res.data.data.role) {
        case 1:
          this.props.history.push({
            pathname: '/parent/dashboard',
            state: { source: 'login' },
          });
        break;
      
        case 2:
          this.props.history.push({
            pathname: '/sitter/dashboard',
            state: { source: 'login' },
          });
          
          break;
        default:
          this.props.history.push('/');
          break;
      }

    }).catch((e) => {
      this.setState({loading:false,success:false})
      toast(e.response.data.message,{type:'error'});
    });
  }

  componentDidMount() {}

  render() {

    return (
      <Layoutone MyClass="hero-login image-containers" >
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-11 col-md-9">
            <h5>
              New to Simplisitter?
               <Link to="/" > Sign Up </Link> 
            </h5>
            <h2>Login to your account</h2>
            <form onSubmit={this.handleSubmit} className="login-form needs-validation form-control" noValidate>
              <input
                type="email"
                onChange={this.handleEmail}
                value={this.state.email}
                className="form-control"
                name="email"
                placeholder="eg. jonesmith@example.com"
                required
              />

              <input
                type="password"
                onChange={this.handlePassword}
                value={this.state.password}
                className="form-control"
                name="password"
                id="password"
                placeholder="Eg. Jone#2010"
                required
              />
              <div className="row">
                <div className="col-6">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="invalidCheck"
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Remember me
                  </label>
                </div>
                <div className="col-6 text-end">
                  <Link  to="/forgot-pass"  className="text-white">
                    Forgot password ?
                  </Link>
                </div>
              </div>
              <button disabled={this.state.loading} type="submit" className="btn-white small-btn">
                { (this.state.loading) ? 'loading . . .  ' : 'Login'  }
              </button>
            </form>
          </div>
          <h6><Link to="/term-sitter" target="_blank">  Terms of Use </Link>
          <span className="text-white-size">and our </span> <Link to="/privacy-policy"  target="_blank"> Privacy Policy</Link>
          </h6>
        </div>
      </Layoutone>
    );
  }
}

export default Login;

