import React from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import instance from '../../../context/AuthApi';
import {toast} from 'react-toastify'
import TokenService from '../../../services/Token.Service';
class CardSetupForm extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          loading:false,
          loader:true,
          cardslist:[]
        }

        this.GetCards = this.GetCards.bind(this);

        //console.log(props)
      
      }

    handlePayment = (e) => {
        const {stripe, elements} = this.props;
        
        stripe.confirmCardPayment(process.env.REACT_APP_STRIPE_PAYMENT_INTENT_CLIENT_SECRET_KEY, {
                                  payment_method: process.env.REACT_APP_STRIPE_PAYMENT_METHOD_ID
                    }).then(function(result) {
                if (result.error) {
    //console.log(result.error.message);
  } else {
    if (result.paymentIntent.status === 'succeeded') {
      // The payment is complete!
       //console.log(result);
    }
  }
});
    }

  handleSubmit = async (event) => {
    this.setState({loading:true});
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    const {stripe, elements} = this.props
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const Setiid = await instance.get('add-card').then((response) => {return response.data.data.setup_intent_id});
      //console.log(Setiid);
    const result = await stripe.confirmCardSetup(Setiid, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: TokenService.getUserName(),
        },
      }
    });

    //console.log(result);

    if (result.error) {
        toast(result.error.message,{type:'error'});
    this.setState({loading:false});
    } else {
        this.setState({loading:false});
        toast('Payment method added successfully',{type:'success'});
        if(this.state.cardslist.length == 0){
          instance.post('/set-default-card',{pm_id:result.setupIntent.payment_method}).then((res) => {
            this.GetCards();
          })
        }else{
          this.GetCards();
        }
        elements.getElement(CardElement).clear();
          
    }
    
  };

    componentDidMount(){
        this.GetCards();
    }


    GetCards = () => {
      this.setState({loader:true})
      instance.get('/get-cards')
        .then((response) => {
            //console.log(' Cards List',response);
          this.setState({ cardslist: response.data.data,loader:false });
        })
        .catch(() => {
          this.setState({loader:false})
        });
    }

    handleDeleteCard = (e,cardid) => {
       var r = window.confirm("Are you sure you want to delete this card? ");
       if(r){
         instance.post('/delete-card',{pm_id:cardid}).then((response) => {
            this.GetCards();  
        toast('Payment method removed successfully',{type:'success'});
        })
        .catch((error) => {
          toast(error.response.data.message,{type:'error'});

        });
         //console.log('delete',cardid)

       }else{
         //console.log('not delete',cardid)

       }
      
    } 
    handleSetDefaultCard = (e,cardid) => {
          instance.post('/set-default-card',{pm_id:cardid}).then((response) => {
            this.GetCards();  
        toast('Payment method set default successfully',{type:'success'});
        })
        .catch(() => {});

    } 

  render() {
    return (<>
      <form className="row" onSubmit={this.handleSubmit}>
        
      {(this.state.loading === false) ? <CardSection /> : <CardSection />}
        
        
        {/* <button type='submit' className="btn-blue  blue-grad" disabled={!this.props.stripe}>Save Card</button> */}
        <div className="col-4 mt-3" >
        <button type='submit' disabled={this.state.loading} className="btn-blue small-btn   blue-grad"   >
          {(this.state.loading === true) ? 'loading ... ' : 'Save Card'}
          </button>          
        </div>

        </form>

        <div className="row ">
                                <div className="col-12" >
                                    <span className="h4 text-blue mb-4">Cards List</span>
                                        <div className={`row ${(this.state.loader) ? 'loader-sm' : '' }`}  >
                                            {this.state.cardslist.map((value) => {
                                                return (<div key={value.pm_id}>
                                                <div className="card deb-card pink-grad m-2 col-12 col-md-6">
                                        <div className="row">
                                            <div className="col-10">
                                                <small>Card number</small>
                                                <h6>XXXX XXXX XXXX  {value.last4}</h6>
                                            </div>
                                            <div className="col-2">
                                                {/* <img src={(maestro)} alt=""/> */}
                                            </div>
                                            <div className="col-5">
                                                <small>Expiry Date</small>
                                                <h6>{value.exp_month}/{value.exp_year}</h6>
                                            </div>
                                            <div className="col-5">
                                                <small>CVV</small>
                                                <h6>XXX</h6>
                                            </div>
                                            <div className="col-12">
                                                {/* <small>Card holder name</small> */}
                                            </div>
                                            <div className="col-10">

                                            

                                                    
                                                    {(value.default === true) ? '' :
                                                    <button className='small-btn btn-white float-end' onClick={ (e) => this.handleSetDefaultCard(e,value.pm_id)}  >
                                                      Set as default</button> }

                                            </div>
                                        </div>
                                        {(value.default === true) ? <i className="fa fa-check-circle"></i> :
                                                    <button  className="delete" onClick={(e) => this.handleDeleteCard(e,value.pm_id) } ><i className="fa fa-trash"></i></button> }
                                      
                                        
                                    </div>

                                    
                                                
                                                </div>
                                                
                                                
                                                
                                                
                                                )
                                            })}
                                        </div>

                                </div>
                            </div>





    </>);
  }
}

export default function InjectedCardSetupForm() {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CardSetupForm   stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}