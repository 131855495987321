class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.refresh_token;
    }

    getUserId() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.user_id;
    }

    getUserName() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.first_name;
    }

    getUserFullname() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.first_name + ' ' + user?.last_name ;
    }

    getUserEmail() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.email;
    }

    getUserRole() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.role;
    }

    getUserImage() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.image;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.api_token;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("user"));
      user.api_token = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    }
  
    setUser(user) {
      //console.log(JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("user");
    }
  }
  
  export default new TokenService();