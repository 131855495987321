import React from 'react';
import TopHeader from '../components/header/header';
import BabyAndMom from '../assets/images/children(0).png';
import GoogleApple from '../components/Social/GoogleApple';

function Layoutone({props,children,MyClass}) {
  
    return (
        <>
        <TopHeader/>
          <section className={MyClass + ' hero blue-grad '}>
            <div className="container ">
              <div className="row">
                <div className="col-12 col-md-6 hero-right-img order-md-2 image-container">
                  <img src={BabyAndMom} alt="" />
                  <div className="image-overlay"></div> 
                </div>
                <div className="col-12 col-md-6 hero-left-content text-center text-md-start">
                  {children}
                  {/* <GoogleApple/> */}
                </div>
              </div>
            </div>
          </section>
        </>
    );
}

export default Layoutone;