import React, { Component, createContext } from "react";
import { withRouter } from "react-router-dom";
export const MainContext = createContext();

class AppContext extends Component {
  state = {
    authLoading: true,
    isLogin: false,
    userData: {}
  };
  componentDidMount() {
    if (localStorage.useJWT) {
      this.setState({
        isLogin: true,
        authLoading: false,
      })
    } else {
      this.setState({
        isLogin: false,
        authLoading: false,
      })
    }
  }
  logOut = () => {
    localStorage.clear();
    this.setState({
      isLogin: false,
      authLoading: false,
    })
  }
  userDataSave = (data) => {
    this.setState({
      userData: data,
      isLogin: true
    })
  }
  render() {
    return (
      <MainContext.Provider
        value={{
          ...this.state,
          userDataSave: this.userDataSave,
          logOut: this.logOut
        }}
      >
        {this.props.children}
      </MainContext.Provider>
    );
  }
}

export default withRouter(AppContext);

export const withContext = (Components) => (props) => {
  return (
    <MainContext.Consumer>
      {(value) => <Components context={value} {...props} />}
    </MainContext.Consumer>
  );
};
