const initialState = {
    count: 
    {staff:0,
        parents:0,
        doctors:0,
        parchase:0},
    title: "My counter",

}

const IndexReducer = (state = initialState, action) => {

     

    switch (action.type) {

        case 'INCREMENT':

            return {
                ...state,
                count: state.count + action.payload
            }

        case 'DECREMENT':
            return {
                ...state,
                count: state.count - action.payload
            }

        case 'SetCounters':
            return {
                ...state,
                count:action.payload
            }

        default:
            return state;
    }

}

export default IndexReducer