import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { toast } from 'react-toastify';
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import instance from '../../../context/AuthApi';
import { Modal } from 'react-bootstrap';

class JobPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StartDate: moment.utc()._d,
            EndDate:moment.utc()._d,
            StartTime:moment().format('HH:mm'),
            EndTime:moment().add(1,'hour').format('HH:mm'),
            location:{lat:'',long:'',place:''},
            allergies:'',
            job_details:'',
            late:1,
            lat:'1.3434',
            lng:'3.345345',
            search:'',
            loading:false,
            cardslist:[],
            child_age: [{f_name: "", dob: "", name: ""}],
            selectedChild_id:[],
            setSelectedChild_id:[],
            selectedChild_dob:[],
            setSelectedChild_dob:[],
            AddCardModel:false
        }
        // console.log(moment.utc()._d)
            this.SavePost = this.SavePost.bind(this);
            this.SetLocation = this.SetLocation.bind(this);
    }

    HandleDateRange = (e) => {
        //console.log(e);
        const [start, end] = e;
        this.setState({
            StartDate:start,
            EndDate:end,
        })
    } 



    handleInputText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
      };


    HandleStartTime = (e) => {
        this.setState({StartTime:e.target.value})
    }

    HandleEndTime = (e) => {
        this.setState({EndTime:e.target.value})
    }

    // OnSubmit = (e) => {
    //     e.preventDefault();

    //     let a = moment(this.state.StartDate);
    //     let b = moment(this.state.EndDate);
    //     let time = b.diff(a,'day');
    //     let Fromtime = moment(this.state.StartTime,'HH:mm');
    //     let ToTime = moment(this.state.EndTime,'HH:mm');
    //     let DiffofTime = ToTime.diff(Fromtime,'hour');
        
    //     if(time == 0){

    //         if(DiffofTime < 1){
    //                    toast(`End time must be at least one hour greater than start time` ,{type:'error'})
    //         }else{
    //             let CurrentDiff = Fromtime.diff(moment(),'minutes');
    //             console.log(CurrentDiff); 
    //             if(CurrentDiff >= 0){

    //                 this.SavePost();
    //             }else{
    //                 toast(`Start time cannot be in past` ,{type:'error'})

    //             }
    //         }
    //     }else{
    //         this.SavePost();
    //     }
    // };
    OnSubmit = (e) => {
        e.preventDefault();

        let a = moment(this.state.StartDate);
        let b = moment(this.state.EndDate);
        let today = moment().toDate();
        let time = b.diff(a,'day');
        let Fromtime = moment(this.state.StartTime,'HH:mm');
        let ToTime = moment(this.state.EndTime,'HH:mm');
        let DiffofTime = ToTime.diff(Fromtime,'hour');
        let childCheckedStatus = this.state.setSelectedChild_id;

        if(time == 0){
            if(DiffofTime < 1){
                       toast(`End time must be at least one hour greater than start time` ,{type:'error'})
            }else{
                let CurrentDiff = Fromtime.diff(moment(),'minutes');
                
                if(CurrentDiff >= 0){
                    //console.log(childCheckedStatus);
                    if(childCheckedStatus.length===0){
                        toast(`No child selected. Select atleast one child.` ,{type:'error'})
                    }
                    else if(childCheckedStatus.length!==0){
                        this.SavePost();
                    }
                    
                }
                // else if(CurrentDiff >= 0 ){
                //     if(childCheckedStatus.length===0){
                //         toast(`No child selected. Select atleast one child.` ,{type:'error'})
                //     }
                //     else if(childCheckedStatus.length!==0){
                //         this.SavePost();
                //     }
                // }
                else{
                    if(today<a){
                        if(childCheckedStatus.length===0){
                        toast(`No child selected. Select atleast one child.` ,{type:'error'})
                    }
                    else if(childCheckedStatus.length!==0){
                        this.SavePost();
                    }
                    }
                    else{
                        toast(`Start time cannot be in past` ,{type:'error'})
                    }

                }
            }
        }else{
            //console.log(childCheckedStatus);
            
                let CurrentDiff = Fromtime.diff(moment(),'minutes');
                //console.log(CurrentDiff); 
                if(CurrentDiff >= 0){
                    //console.log(childCheckedStatus);
                    if(childCheckedStatus.length===0){
                        toast(`No child selected. Select atleast one child.` ,{type:'error'})
                    }
                    else if(childCheckedStatus.length!==0){
                        this.SavePost();
                    }
                    
                }
                // else if(CurrentDiff >= 0 ){
                //     if(childCheckedStatus.length===0){
                //         toast(`No child selected. Select atleast one child.` ,{type:'error'})
                //     }
                //     else if(childCheckedStatus.length!==0){
                //         this.SavePost();
                //     }
                // }
                else{
                    if(today<a){
                        if(childCheckedStatus.length===0){
                        toast(`No child selected. Select atleast one child.` ,{type:'error'})
                    }
                    else if(childCheckedStatus.length!==0){
                        this.SavePost();
                    }
                    }
                    else{
                        toast(`Start time cannot be in past` ,{type:'error'})
                    }
                }
            

            // if(childCheckedStatus.length===0){
            //     toast(`No child selected. Select atleast one child.` ,{type:'error'})
            // }else if(childCheckedStatus.length!==0){
            //     this.SavePost();
            // }
        }
    };

    HandleIsLate = (e) => {
        //console.log(e.target.value);
        this.setState({late:(e.target.value == 'yes' ) ? 1 : 0})
    }

    handleChildSelect = (e,index) =>{
        const  {value, checked }= e.target;
        const Childage = [...this.state.child_age];
        const child_dob =  Childage[index]['dob'];
        //console.log(`${value} is ${checked}`);
        //console.log(`${child_dob} is ${checked}`);
        if(checked){
            //for selected child id
            this.setState({selectedChild_id : [...this.state.selectedChild_id, value]});
            this.setState({setSelectedChild_id : [...this.state.selectedChild_id, value]});
            //for selected child dob
            this.setState({selectedChild_dob: [...this.state.selectedChild_dob, child_dob]});
            this.setState({setSelectedChild_dob : [...this.state.selectedChild_dob, child_dob]});
            

        }else{
            //for selected child id
            this.setState({selectedChild_id : this.state.selectedChild_id.filter((e)=>e !== value)});
            this.setState({setSelectedChild_id : this.state.selectedChild_id.filter((e)=>e !== value)});
            //for selected child dob
            this.setState({selectedChild_dob: this.state.selectedChild_dob.filter((e)=>e !== child_dob)});
            this.setState({setSelectedChild_dob : this.state.selectedChild_dob.filter((e)=>e !== child_dob)});
        }
    }

    SavePost = ()=> {

        if(this.state.location.lat === '' || this.state.location.long === ''){
            toast(`Please select your location` ,{type:'error'})
        }else{


        this.setState({loading:true})
        let data  = {
            start_date:moment(this.state.StartDate).format('YYYY-MM-DD'),
            end_date:moment(this.state.EndDate).format('YYYY-MM-DD'),
            start_time:this.state.StartTime,
            end_time:this.state.EndTime,
            late:this.state.late,
            lat:this.state.location.lat,
            lng:this.state.location.lng,
            allergies:this.state.allergies,
            job_details:this.state.job_details,
            location:this.state.location.place,
            setSelectedChild_id:this.state.setSelectedChild_id,
            setSelectedChild_dob:this.state.setSelectedChild_dob,
           }
    
              console.info(data)
              instance.post('/parent/job',data).then((res) => {
                  toast('You have successfully posted a new Job', { type: "success" });
                    this.setState({loading:false})
                  this.props.history.push({pathname:'/parent/dashboard',state:{firsttab:2,secondtab:2}});
              }).catch((error) => {
                  console.error(error)
                  toast(error.response.data.message, { type: "error" });
                  this.setState({loading:false})
                })
        }
    }


    handleCurrentLocation = () => {

        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
        Geocode.setLanguage("en");
        Geocode.enableDebug();

        var address = '';
        var lat = '';
        var lng = '';
         
        var OurThis = this;

        if (navigator.geolocation) {
            navigator.permissions
              .query({ name: "geolocation" })
              .then((result) => {
                if (result.state === "prompt") {
                    toast("Please allow location permission to search you current location.", { type: "warning" })
                } else if (result.state === "denied") {
                  //If denied then show instructions to enable location 
                    toast("Please allow location permission to search you current location.", { type: "error" })
                }
            })
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
           
            Geocode.fromLatLng(pos.lat,pos.lng).then(
                (response) => {
                   address = response.results[0].formatted_address;
                   lat = response.results[0].geometry.location.lat;
                   lng = response.results[0].geometry.location.lng;
                  OurThis.setState({location:{lat:lat,lng:lng,place:address},search:address})
                  //console.log('dd',address);
                },
                (error) => {
                  //console.error(error);
                }
              );
         
            }) 
        }else{
            toast('Unable to find location | Please search manually ', { type: "error" });
        }

    }
    handleLocation = (place) => {
        if(place !== undefined || place.geometry !== undefined || place.formatted_address !== undefined || place.geometry !== "" || place.formatted_address !== "" || place !== "" || place !== " " ){
            this.setState({location:{lat:place.geometry.location.lat(),lng:place.geometry.location.lng(),place:place.formatted_address},search:place.formatted_address});
        }
        console.log(place);
        console.log(place.geometry.location.lat());
        console.log(place.geometry.location.lng());
    }

    SetLocation = (lat,lng,address) => {
        this.setState({location:{lat:lat,lng:lng,place:address}})

    }

    componentDidMount() {

        instance.get('/get-cards')
        .then((response) => {
            //console.log(' Cards List',response);
            let CardsCount = response.data.data.length
            if(CardsCount == 0){
                this.setState({AddCardModel:true});
            }
        })
        .catch(() => {});   

        instance.get('/get-parent-profile').then((res) => {
            //console.log(res.data.data.children);
            
            this.setState({
                loading:false,
                userdetails:res.data.data,
                child_age:res.data.data.children,
            })
            


        }).catch((er) => {
            //console.log(er);
            this.setState({loading:false});

        });

    }



    render() {
        return (
            <div>
                <header>
                    <div className="container">
                        <div className="row page-title">
                            <div className="col-12"><Link to="/parent/dashboard" className="text-black"><i className="fa fa-arrow-left"></i></Link><i className="fa fa-briefcase"></i>
                                <h2 className="text-blue">Post a job</h2>
                            </div>
                        </div>
                        {/* <!-- <img className="logo-full" src=" assets/images/logo-blue (1).png " alt=" "> --> */}
                    </div>
                </header>
                <section className="hero parent-registration post-job">
                    <div className="container ">
                        <div className="row">
                            <div className="col-xm-4 col-sm-12 col-md-5 col-12    sticky-md-top px-4">
                                <h4 className="text-grey">When do you need a sitter?</h4>
                                <DatePicker
                                //  selected={null}
                                 onChange={this.HandleDateRange}
                                 startDate={this.state.StartDate}
                                 endDate={this.state.EndDate}
                                 dateFormat="yyyy/MM/dd"
                                 maxDate={moment().add(3,'month').utc()._d}
                                 minDate={moment.utc()._d}
                                 selectsRange
                                //  selectsStart
                                //  selectsEnd
                                 inline
                                 monthsShown={1}
                                />
                               
                            </div>
                            <div className="col-xm-4 col-sm-12 col-md-7 col-12" >
                                <h5 className="text-black px-3">Do you want to edit your profile details? <Link to={'/parent/profile'} className="text-blue">Click here to Edit Profile Details </Link></h5>

                                <form onSubmit={this.OnSubmit} className="job-post registration needs-validation form-control" >
                                    <div className="row job-date">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                            <label htmlFor="start_date" className="text-blue">Date : </label>
                                            <input type="text" disabled  value={ moment(this.state.StartDate).format('DD MMMM, YYYY')}    name="start_date" id="start_date" className="form-control"  />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                            <label htmlFor="end_date" className="text-black">to : </label>
                                            <input type="text" disabled value={moment(this.state.EndDate).format('DD MMMM, YYYY')}  id="end_date" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row job-time">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                            <label htmlFor="start_time" className="text-blue">Time : </label>
                                            <input type="time" onChange={this.HandleStartTime}  value={this.state.StartTime} name="start_time" id="start_time" className="form-control" />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                            <label htmlFor="end_time" className="text-black">to : </label>
                                            <input type="time" onChange={this.HandleEndTime} value={this.state.EndTime} name="end_time" id="end_time" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-12 py-3 arriving-status">
                                        <input checked={this.state.late == 1}  className="form-check-input" onChange={this.HandleIsLate} name="be_late" type="checkbox" value="yes" id="on_time"  />
                                        <label className="form-check-label text-black" htmlFor="on_time">
                                        I plan to be more or less on time
                                        </label>
                                        <br />
                                        <input checked={this.state.late == 0}  className="form-check-input" onChange={this.HandleIsLate} name="be_late" type="checkbox" value="no" id="be_late"  />
                                        <label className="form-check-label text-black" htmlFor="be_late">
                                            I may be late
                                        </label>
                                    </div>

                                    <div className="col-12 search-location">
                                        <h4 className="text-blue">Location</h4>
                                        <i className="fa fa-search"></i>
                                        <Autocomplete required onChange={this.handleInputText} name="search" value={this.state.search} options={{types:["geocode","establishment"], componentRestrictions:{country:["us"]}}}  onPlaceSelected={this.handleLocation} type="search" apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} />
                                        {/* <input type="search" onChange={this.handleInputText} name="search" id="job_location" placeholder="Search location" /> */}
                                        {/* <small className="text-pink">e.g. 6 Holland Lane, Monsey, NY 10952, USA</small><br></br> */}
                                        <a onClick={this.handleCurrentLocation} className="text-blue"><i className="fa fa-crosshairs"></i> Use my current location</a>
                                    </div>

                                    <div className="col-12 children-age pt-3">
                                        <h4 className="text-blue">Which of your children will be present?</h4>
                                        {this.state.child_age.map((value, index) => {
                                            return (moment().diff(value.dob, 'years') < 10 ? (
                                                <div key={value.child_id}>
                                                <div>
                                                <div className="col align-self-center">
                                            
                                                <h5 htmlFor="" className="child-number m-0 " data-count="1">
                                                    Child {index + 1}
                                                </h5>
                                                </div>
                                                <div className="row">
                                                <div className="col-5 ">
                                                    {/* <h4 className="text-blue">Child name</h4> */}
                                                    
                                                    <li className="list-inline-item" key={index}>
                                                    <input
                                                        type="checkbox"
                                                        id={`custom-checkbox-${index}`}
                                                        name={`custom-checkbox-${index}`}
                                                        value={value.child_id}
                                                        onChange={(e) => this.handleChildSelect(e,index)}
                                                    />
                                                    <label className="form-check-label text-black" htmlFor={`custom-checkbox-${index}`}>{value.f_name}</label>
                                                    </li>
                                                </div>
                                               
                                                <div className="col-4">
                                                    {moment().diff(value.dob, 'years') === 0 ? (<label className="form-check-label text-black"  >{"0-12 months"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 1 ? (<label className="form-check-label text-black"  >{"1-2 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 2 ? (<label className="form-check-label text-black"  >{"2-3 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 3 ? (<label className="form-check-label text-black"  >{"3-4 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 4 ? (<label className="form-check-label text-black"  >{"4-5 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 5 ? (<label className="form-check-label text-black"  >{"5-6 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 6 ? (<label className="form-check-label text-black"  >{"6-7 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 7 ? (<label className="form-check-label text-black"  >{"7-8 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 8 ? (<label className="form-check-label text-black"  >{"8-9 yrs"}</label>) : ''}
                                                    {moment().diff(value.dob, 'years') === 9 ? (<label className="form-check-label text-black"  >{"9-10 yrs"}</label>) : ''}
                                                    {value.dob === '' ? (<label className="form-check-label text-black" placeholder="Age group" required >{"0-12 months"}</label>) : ''}
                                                </div>
                                                </div>
                                            </div></div>) : '');}
                                        )}
                                        </div>

                                    <div className="col-12 pt-3">
                                        <h4 className="text-blue">
                                            Are there any allergies or health conditions that sitters should be aware of?
                                        </h4>
                                        <textarea title="Please provide the description for allergies or health conditions." onChange={this.handleInputText} className="form-control" name="allergies" id="about_sitter" rows="5" placeholder="Include details that will give babysitters a better idea of the health conditions or allergies that they would have to take care of." pattern="[a-z]{1,15}" required="required"></textarea>
                                    </div>

                                    <div className="col-12 pt-3">
                                        <h4 className="text-blue">
                                            Describe what this role entails
                                        </h4>
                                        <textarea title="Please provide the details regarding the job." className="form-control" onChange={this.handleInputText} name="job_details" id="about_sitter" rows="5" placeholder="Include details that will give babysitters a better idea of the job, such as if the children will be awake or sleeping etc."  required="required"></textarea>
                                    </div>

                                    <div className="col-12 text-center py-3">
                                        <button className="btn-blue float-end blue-grad" disabled={this.state.loading} type="submit">
                                            {(this.state.loading) ? 'posting.....' : 'Post Job'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>


                <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad " className="cancel-job-modal " show={this.state.AddCardModel}>        
        
                <Modal.Body>
                    <div className="col-12 text-center">
                            <h3 className="text-white pt-4">You don’t have a payment method.</h3>
                            <p className="text-white">Please add a payment method for posting jobs.</p>
                    </div>
                </Modal.Body>   
                    <Modal.Footer className="justify-content-center" >
                    <a onClick={()=> { 
                        this.props.history.push(
                            {pathname:'/parent/profile',state:{activetab:4}}); }} 
                            className="btn btn-white small-btn text-blue">
                                Add Payment
                    </a>
                </Modal.Footer>                                     
   
  </Modal>  

            </div>
        );
    }
}


JobPost.propTypes = {

};

export default JobPost;