import React from 'react';
import TopHeader from '../components/header/header';
import BabyAndMom from '../assets/images/children(0).png';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import GoogleApple from '../components/Social/GoogleApple';


function Layouttwo({props,children,ViewName,myclass}) {
    return (
        <>
        <TopHeader logotype='blue' />
        <section className={myclass + ' hero'}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6 app-screenshot p-5">
                    <img src={BabyAndMom} alt=""/>
                    <div className="col-12 py-4 reg-page-badges">
                    {/* <GoogleApple/> */}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <h5 className="back-btn ps-3">
                        <Link className='text-grey' to="/login" >
                                <i className="fa fa-arrow-left"></i>
                                Back to Login
                        </Link>
                    </h5>
                    {/* <span className="h4 text-pink ps-3">{ViewName}</span><span className="h6">
                            <Link to="/login" >
                        <a  className="text-pink float-end pe-3">
                            Login
                            </a>
                            </Link>
                    </span> */}
                   {children}
                </div>
            </div>
            
        </div>
    </section>
        </>
    );
}
Layouttwo.propTypes = {
    children: PropTypes.element.isRequired,
    ViewName: PropTypes.string.isRequired,
    myclass:PropTypes.string.isRequired
}

export default Layouttwo;