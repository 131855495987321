import React, { Component } from 'react';
import LayoutDash from '../../layouts/DashLayout';
import Personal from '../../components/Profile/Section.js/Parent/Personal';
import About from '../../components/Profile/Section.js/Parent/About';
import ImgAvatar from '../../assets/images/avatar.png'
import maestro from '../../assets/images/maestro.png'
import { toast } from 'react-toastify'
import Api from '../../context/Api';
import instance from '../../context/AuthApi';
import TokenService from '../../services/Token.Service';
import Select from "react-select";
import { ChildAgeGroups, ChildAgeGroupsInYrs } from "../../Constants/ChildAgeGroups";
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import S3bucket from '../../services/S3bucket';
import CardSetupForm from '../Payment/Stripe/CardSetupForm'
import { Link } from 'react-router-dom';
import './CreditCard.css';
import { toHaveAccessibleDescription } from '@testing-library/jest-dom/dist/matchers';

// when stripe is in test mode or in live mode use respective env variable
const stripePromise = loadStripe(process.env.REACT_APP_LIVE_STRIPE_KEY);


class ParentProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activetab: (this.props.location?.state?.activetab) ? this.props.location.state.activetab : 1,
            userdetails: {},
            firstname: "",
            lastname: "",
            cardslist: [],
            religion: null,
            spousename: "",
            phone_number: "",
            address: "",
            city: '',
            state: '',
            zip_code: '',
            profile_pic: ImgAvatar,
            language: "English",
            ispet: false,
            pets_details: '',
            pets_status: false,
            amenities: [],
            getAmenities: [],
            references: [
                { f_name: "", l_name: "", country_code: "+1", phone: "", reference_id: '' },
                { f_name: "", l_name: "", country_code: "+1", phone: "", reference_id: '' },
            ],
            url: ImgAvatar,
            email: '',
            child_age: [{ f_name: "", dob: "", name: "" }],
            loading: true,
            error: [],
            uploading: false,
            current_password: '',
            password: '',
            new_password: '',
            form1Enable: false,
            form2Enable: false,
            form3Enable: false,
        }



        this.handleUpdateCardList = this.handleUpdateCardList.bind(this);
    }
    componentDidMount() {
        instance.get('/get-parent-profile').then((res) => {
            //console.log(res.data.data);

            this.setState({
                loading: false,
                userdetails: res.data.data,
                image: res.data.data.image,
                url: res.data.data.image,
                profile_pic: res.data.data.image,
                firstname: res.data.data.first_name,
                lastname: res.data.data.last_name,
                email: res.data.data.email,
                phone_number: res.data.data.phone_number,
                address: res.data.data.address,
                city: res.data.data.city,
                state: res.data.data.state,
                religion: res.data.data.religion,
                spousename: res.data.data.spouse_name,
                zip_code: res.data.data.zip_code,
                child_age: res.data.data.children,
                // amenities:res.data.data.amenities,
                amenities: res.data.data.amenities.map((value, index) => { return { value: value.amenity_id, label: value.amenity_name } }),
                references: res.data.data.references,
                language: res.data.data.language,
                pets_status: res.data.data.pets_status,
                pets_details: res.data.data.pets_details,
            });



        }).catch((er) => {
            //console.log(er);
            this.setState({ loading: false });

        })


        Api.get("/amenities")
            .then((response) => { this.setState({ getAmenities: response.data.data }) })
            .catch(() => { })

        instance.get('/get-cards')
            .then((response) => {
                //console.log(' Cards List',response);
                this.setState({ cardslist: response.data.data });
            })
            .catch(() => { });

        // add-card

    }




    handleUpdateCardList = () =>{
        instance.get('/get-cards')
            .then((response) => {
                //console.log(' Cards List',response);
                this.setState({ cardslist: response.data.data });
            })
            .catch(() => { });

    }
    handlePlus = (e) => {
        if (this.state.child_age.length < 8) {
            const element = {
                f_name: "",
                dob: "",
                name: ""
            };
            this.setState({ child_age: [...this.state.child_age, element] });
        }
    };
    handleMinus = (e) => {
        if (this.state.child_age.length > 1) {
            const Age = this.state.child_age;
            const lastAge = Age.length - 1;
            this.setState({
                child_age: Age.filter((item, index) => index !== lastAge),
            });
        }
    };

    handleChildInput = (e, index) => {
        const Childage = [...this.state.child_age];

        if (e.target.name == 'f_name') {
            Childage[index]['f_name'] = e.target.value;
            this.setState({ child_age: Childage, form2Enable: true });
            //console.log(this.state.child_age);
        } else if (e.target.name == 'dob') {

            Childage[index]['dob'] = e.target.value;

            if (e.target.validity.valueMissing) {
                e.target.setCustomValidity('Please provide a birthdate of your child.');
            } else if (e.target.validity.rangeUnderflow) {
                e.target.setCustomValidity('Child is too old for babysitting.');
            } else if (e.target.validity.rangeOverflow) {
                e.target.setCustomValidity('Child is too young for babysitting.');
            } else {
                e.target.setCustomValidity('');
            }

            this.setState({ child_age: Childage, form2Enable: true });
            let age = moment().diff(e.target.value, 'years');


            if (age >= 0 && age < 1) {
                Childage[index]['name'] = "0-12 months";
                this.setState({ child_age: Childage });
            } else if (age >= 1 && age < 2) {
                Childage[index]['name'] = "1-2 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 2 && age < 3) {
                Childage[index]['name'] = "2-3 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 3 && age < 4) {
                Childage[index]['name'] = "3-4 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 4 && age < 5) {
                Childage[index]['name'] = "4-5 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 5 && age < 6) {
                Childage[index]['name'] = "5-6 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 6 && age < 7) {
                Childage[index]['name'] = "6-7 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 7 && age < 8) {
                Childage[index]['name'] = "7-8 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 8 && age < 9) {
                Childage[index]['name'] = "8-9 yrs";
                this.setState({ child_age: Childage });
            } else if (age >= 9 && age < 10) {
                Childage[index]['name'] = "9-10 yrs";
                this.setState({ child_age: Childage });
            } else {
                Childage[index]['name'] = "0-12 months";
                this.setState({ child_age: Childage });
            }

        } else if (e.target.name == 'child-name') {
            //let age = moment().diff(e.target.value, 'years');
            Childage[index]['name'] = e.target.value;
            //console.log(ChildAgeGroups[index]);
            this.setState({ child_age: Childage });
        }
    };

    handleProfileImage = (e) => {
        var File = e.target.files[0];
        if (File !== undefined) {
            if (File.type.match('image/jpeg') || File.type.match('image/jpg') || File.type.match('image/png') || File.type.match('image/gif') || File.type.match('image/webp')) {
                var reader = new FileReader();
                var url = reader.readAsDataURL(File);
                this.setState({
                    uploading: true,
                    url: URL.createObjectURL(File),
                    form1Enable: true,
                });
                S3bucket.uploadFile(File, File.name)
                    .then((res) => {
                        this.setState({ uploading: false, profile_pic: res.location });
                        //console.log(res.location);
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            } else {
                e.target.value = null;
                File = e.target.files[0];
                toast("The selected file is not an image or allowed image file type. Please select an image file.", { type: "error" });
            }
        }
    };

    handleAmenities = (selectedOptions) => {
        //console.log(selectedOptions);
        if (selectedOptions.length > 0) {
            this.setState({ amenities: selectedOptions, form2Enable: true });
        }
    };

    handleReferences = (e, index) => {
        // console.log(index,e.target.value);
        const References = [...this.state.references];
        if (e.target.name == 'f_name') {
            References[index]['f_name'] = e.target.value;
            this.setState({ references: References, form3Enable: true });
            //console.log(this.state.child_age);
        } else if (e.target.name == 'l_name') {
            References[index]['l_name'] = e.target.value;
            this.setState({ references: References, form3Enable: true });
        } else if (e.target.name == 'phone') {
            References[index]['phone'] = e.target.value;
            this.setState({ references: References, form3Enable: true });
        }
        //this.setState({ references: References, form3Enable:true });
    };

    handleInputText = (e) => {
        this.setState({ [e.target.name]: e.target.value, form1Enable: true });
    };

    handleIspet = (e) => {
        // if(e.target.value=='No'){
        //     console.log('petStatus',this.state.pets_status);
        //     this.state.pets_status=false;
        //     this.setState({ pets_status: false, form2Enable:true, pets_details: null});
        //     console.log('petStatus',this.state.pets_status);
        // }
        //this.setState({ pets_status: ((e.target.value == 'No') ? false : true),pets_details:'', form2Enable:true, pets_details:''});
        let status = '';
        if (e.target.value == 'Yes') {
            status = true;
        }
        if (e.target.value == 'No') {
            status = false;
            this.setState({ pets_details: '', form2Enable: true });
        }
        this.setState({ pets_status: status });
        // this.setState({ pets_status: status });
        //console.log('isPet',e.target.value);
        // console.log('petStatus',this.state.pets_status);
    };

    handlePetDetails = (e) => {
        //console.log('petDetails: ',e.target.value);
        //  this.setState({ pets_status: (e.target.value != '') ? true : false});
        if (e.target.value != '') {
            this.setState({ pets_status: true });
            //console.log('petStatus: ',this.state.pets_status);
        }
        this.setState({ pets_details: e.target.value, form2Enable: true });
    };


    handleLanguage = (e) => {
        if (e.target.value.trim() === "") {
            this.setState({ form2Enable: false });
        } else if (this.state.language == 'English') {
            //console.log('LanguageiSEng: ',this.state.language);
            this.setState({ form2Enable: false });
        } else {
            this.setState({ form2Enable: true });
        }

        this.setState({ language: e.target.value });
    };

    handleSubmitPersonal = (e) => {
        e.preventDefault();
        let data = {
            first_name: this.state.firstname.trim(),
            last_name: this.state.lastname.trim(),
            phone_number: this.state.phone_number.trim(),
            address: this.state.address.trim(),
            religion: this.state.religion,
            city: this.state.city.trim(),
            state: this.state.state.trim(),
            zip_code: this.state.zip_code.trim(),
            spouse_name: this.state.spousename.trim(),
            image: this.state.profile_pic
        }
        instance.post('/update-personal-details', data).then((res) => {
            this.setState({
                image: res.data.data.image,
                url: res.data.data.image,
                profile_pic: res.data.data.image,
                firstname: res.data.data.first_name,
                lastname: res.data.data.last_name,
                email: res.data.data.email,
                phone_number: res.data.data.phone_number,
                address: res.data.data.address,
                city: res.data.data.city,
                state: res.data.data.state,
                religion: res.data.data.religion,
                spousename: res.data.data.UserDetail.spouse,
                zip_code: res.data.data.zip_code,
            })
            toast('Your profile details Updated Successfully', { type: "success" });

        }).catch((error) => {
            // console.error(error)
            toast(error.response.data.message, { type: "error" });

        })
        this.setState({ form1Enable: false });

    };


    handleSubmitExtra = (e) => {
        e.preventDefault();

        let data = {
            language: this.state.language.trim(),
            pets_status: this.state.pets_status,
            pets_details: this.state.pets_details.trim(),
            children: this.state.child_age,
            amenities: this.state.amenities.map((value) => { return value.value }),
        }

        if(data.pets_status === true && data.pets_details === "") {
            toast("Please provide the pet details", { type: "error" });
            return null;
        }

        if(data.language.trim() === "") {
            data.language = 'English';
            this.setState({ language: 'English' });
        }
        //console.info(data)
        instance.post('/update-parent-profile', data).then((res) => {
            //console.info(res)

            // this.setState({
            //     language:res.data.data.image,
            //     pets_status: res.data.data.image,
            // profile_pic: res.data.data.image,
            // pets_details: res.data.data.first_name,
            // lastname: res.data.data.last_name,
            // email:res.data.data.email,
            // address:res.data.data.address,
            // city:res.data.data.city,
            // state:res.data.data.state,
            // religion:res.data.data.religion,
            // spousename:res.data.data.spouse_name,
            // zip_code:res.data.data.zip_code,
            // })
            toast('Your profile details Updated Successfully', { type: "success" });

        }).catch((error) => {
            //console.error(error)
            // toast(error., { type: "error" });

        })
        this.setState({ form2Enable: false });
    };

    handleSubmitReferences = (e) => {
        e.preventDefault();
        let data = { references: this.state.references }
        //console.info(data)
        instance.post('/update-references', data).then((res) => {
            toast('Your References Updated Successfully', { type: "success" });
        }).catch((error) => {
            //console.error(error)
        })
        this.setState({ form3Enable: false });
        //console.log('form3: ',this.state.form3Enable);
    };

    handleSubmitPassword = (e) => {
        e.preventDefault();
        if (this.state.password == this.state.new_password) {
            let data = { password: this.state.current_password, new_password: this.state.new_password }
            //console.info(data)
            instance.post('/change-password', data).then((res) => {
                this.setState({
                    password: '', new_password: '', current_password: ''
                })
                toast('Your Password Updated Successfully', { type: "success" });
            }).catch((error) => {
                //console.log(error.response)
                toast(error.response.data.message, { type: "error" });
                // this.setState({current_password:''
                // })
            })
        } else {
            toast('Confirm password is not same | Please enter same password ', { type: "error" });
            this.setState({
                password: '', new_password: '', current_password: ''
            })
        }

    };




    handleTabs = (e, key) => { this.setState({ activetab: key }) }
    render() {
        return (<div className={(this.state.loading === true) ? "loader" : ''} >
            <header>
                <div className="container">
                    <div className="row settings-page-title">
                        <div className="col-12">
                            <Link to='/parent/dashboard' className="text-grey back-button"><i className="fa fa-arrow-left"></i>Back to dashboard
                            </Link>
                        </div>
                    </div>

                </div>
            </header>
            <section >
                <div className="container settings-pills">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="col-12 blue-grad sidebar">
                                <div className="basic-details">
                                    <div className="col-12 heading">
                                        <i className="fa fa-user"></i><span className="h5">My profile</span>
                                    </div>
                                    <div className="row">
                                        <div className="col-5 profile-pic">
                                            <img src={(this.state.image == '') ? ImgAvatar : this.state.image} alt="" />
                                        </div>
                                        <div className="col-7 logout">
                                            {/* <small className="verified"><i className="fa fa-check"></i>Background verified</small> */}
                                            {/* <small className="not-verified"><i className="fa fa-exclamation"></i>Background not verified</small> */}
                                            <h4> {TokenService.getUserName()} </h4>
                                        </div>
                                    </div>
                                    <div className="row pills-navbar">
                                        <div className="col-12 pt-4">
                                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                {/* <!-- personal detail nav pill --> */}

                                                <button onClick={e => this.handleTabs(e, 1)} className={`nav-link ${this.state.activetab == 1 ? 'active' : ''}`} id="pill-personal-details-tab" data-bs-toggle="pill" data-bs-target="#pill-personal-details" type="button" role="tab" aria-controls="pill-personal-details" aria-selected="true">Personal details <small className="sub-title">Name, Email, Spouse’s name, Address
                                                    {/* , Religion */}
                                                </small></button>

                                                {/* <!-- About me nav pill --> */}
                                                <button onClick={e => this.handleTabs(e, 2)} className={`nav-link ${this.state.activetab == 2 ? 'active' : ''}`} id="pill-about-tab" data-bs-toggle="pill" data-bs-target="#pill-about" type="button" role="tab" aria-controls="pill-about" aria-selected="false">
                                                    Extra Details<small className="sub-title">Language, Pets, Amenities, Children</small></button>

                                                {/* <!-- References nav pill --> */}
                                                <button onClick={e => this.handleTabs(e, 3)} className={`nav-link ${this.state.activetab == 3 ? 'active' : ''}`} id="pill-references-tab" data-bs-toggle="pill" data-bs-target="#pill-references" type="button" role="tab" aria-controls="pill-references" aria-selected="false">References (2 required)</button>

                                                {/* <!-- Payments nav pill --> */}
                                                <button onClick={e => this.handleTabs(e, 4)} className={`nav-link ${this.state.activetab == 4 ? 'active' : ''}`} id="pill-payments-tab" data-bs-toggle="pill" data-bs-target="#pill-payments" type="button" role="tab" aria-controls="pill-payments" aria-selected="false">Payments</button>

                                                {/* <!-- Password nav pill --> */}
                                                {/* <button onClick={ e => this.handleTabs(e,5) } className={`nav-link ${this.state.activetab == 5 ? 'active' : ''}`} id="pill-password-tab" data-bs-toggle="pill" data-bs-target="#pill-password" type="button" role="tab" aria-controls="pill-password" aria-selected="false">Change password</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 pills-content">
                            <div className="tab-content" id="v-pills-tabContent">

                                {/* <!-- personal detail content pane--> */}
                                <div className={`tab-pane fade  pill-personal-details ${this.state.activetab == 1 ? 'show active' : ''}`} id="pill-personal-details" role="tabpanel" aria-labelledby="pill-personal-details-tab">

                                    <form onSubmit={this.handleSubmitPersonal} className="edit-personal-details bordered">
                                        <div className="row px-2">
                                            <div className="col-12 col-md-7 upload-profile-picture">
                                                <input title="Upload your profile photo" placeholder='Upload your profile photo' onChange={this.handleProfileImage} type="file" accept="image/jpg, image/png, image/gif, image/jpeg, image/webp" name="profile_pic" id="profile_pic" />
                                                <img src={(this.state.url === '') ? ImgAvatar : this.state.url} alt="Profile avatar" />
                                                <span className="h4 text-blue">Upload photo <span className="text-grey">(optional)</span></span>
                                                <div><small className="text-pink" >Only jpg, jpeg, gif, webp and png image file formats are allowed.</small></div>
                                            </div>
                                            <div className="col-6 col-md-6 ">
                                                <label htmlFor="f_name">First name</label>
                                                <input type="text" title="First name should be 2 characters or more." minLength={2} maxLength={40}
                                                    // onKeyDown={(e) => {
                                                    //     var x = e.key;
                                                    //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                    //         e.preventDefault();
                                                    //     }
                                                    // }}
                                                    pattern="^[a-zA-Z0-9\s]+$" onChange={this.handleInputText} value={this.state.firstname} className="form-control" name="firstname" id="f_name" placeholder="First name" required />
                                                <small>  </small>
                                            </div>
                                            <div className="col-6 col-md-6 ">
                                                <label htmlFor="l_name">Last name</label>
                                                <input type="text" title="Last name should be 2 characters or more." minLength={2} maxLength={40}
                                                    // onKeyDown={(e) => {
                                                    //     var x = e.key;
                                                    //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                    //         e.preventDefault();
                                                    //     }
                                                    // }}
                                                    pattern="^[a-zA-Z0-9\s]+$" onChange={this.handleInputText} value={this.state.lastname} className="form-control" name="lastname" id="l_name" placeholder="lastname" required />
                                            </div>
                                            <div className="col-col-12 col-md-7 ">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" disabled defaultValue={this.state.userdetails.email} className="form-control" name="email" id="email" placeholder="Email" required />
                                            </div>
                                            <div className='col-12 col-md-9'>
                                                <div className='row'>
                                                    <div className="col-4 col-sm-4 col-md-4">
                                                        <label htmlFor="country_code">Country Code</label>
                                                        <select
                                                            title='Country Code'
                                                            name="country_code"
                                                            className="c_code"
                                                        >
                                                            {/* <option defaultValue={(value.country_code == '+91') } value="+91">+91 </option> */}
                                                            <option defaultValue={'+1'} value="+1">+1</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-8 col-md-8 col-lg-6 col-xl-6">
                                                        <label htmlFor="phone_number">Your Phone Number (10 digits)</label>
                                                        <input
                                                            title="Please enter exactly 10 digits with correct area code."
                                                            inputMode="numeric"
                                                            pattern="^[2-9][0-9]*$"
                                                            maxLength={10}
                                                            minLength={10}
                                                            onChange={(e) =>
                                                                this.handleInputText(e)
                                                            }
                                                            type="text"
                                                            value={this.state.phone_number}
                                                            className="form-control"
                                                            name="phone_number"
                                                            id="phone_number"
                                                            placeholder="Phone number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="address">Address</label>
                                                <input type="text" minLength={5} maxLength={250} onChange={this.handleInputText} value={this.state.address} className="form-control" name="address" id="address" placeholder="Address" required />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="address">City</label>
                                                <input type="text" title="Please enter a city name without digits and not more than one space between two words." minLength={2} maxLength={40}
                                                    // onKeyDown={(e) => {
                                                    //     var x = e.key;
                                                    //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                    //         e.preventDefault();
                                                    //     }
                                                    // }}
                                                    pattern="^[a-zA-Z0-9\s]+$" onChange={this.handleInputText} value={this.state.city} className="form-control" name="city" id="city" placeholder="City Name" required />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="address">State</label>
                                                <input type="text" title="Please enter a state name without digits and not more than one space between two words."
                                                    // onKeyDown={(e) => {
                                                    //     var x = e.key;
                                                    //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                    //         e.preventDefault();
                                                    //     }
                                                    // }}
                                                    pattern="^[a-zA-Z0-9\s]+$" minLength={2} maxLength={40} onChange={this.handleInputText} value={this.state.state} className="form-control" name="state" id="state" placeholder="State Name" required />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="address">ZipCode</label>
                                                <input type="text" title="Please enter 5 digits only." inputMode="numeric" minLength={5} maxLength={5} pattern="^[0-9][0-9]*$" onChange={this.handleInputText} value={this.state.zip_code} className="form-control" name="zip_code" id="zip_code" placeholder="Zip Code" required />
                                            </div>
                                            {/* <div className="col-12 col-md-6 ">
                                            <label htmlFor="religion">Religion</label>
                                                <input type="text" title="Please enter a religion without digits." minLength={2} maxLength={15}
                                                    onKeyDown={(e) => {
                                                        var x = e.key;
                                                        if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                            e.preventDefault();
                                                        }
                                                    }}   
                                                    pattern="^[A-Za-z]+$" onChange={this.handleInputText} value={this.state.religion} className="form-control" name="religion" id="religion" placeholder="Religion" required />
                                        </div> */}
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="spouse_name">Spouse name (optional)</label>
                                                <input type="text" pattern="^[a-zA-Z0-9\s]+$" minLength={2} maxLength={20}
                                                    // onKeyDown={(e) => {
                                                    //     var x = e.key;
                                                    //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                    //         e.preventDefault();
                                                    //     }
                                                    // }}
                                                    onChange={this.handleInputText} value={this.state.spousename} className="form-control" name="spousename" id="spouse_name" placeholder="Spouse name (optional)" />
                                            </div>
                                            {/* <div className="col-12">
                                            <div className="col-12 col-md-6 pt-3">
                                                <h5 className="text-grey">Enter password to save changes</h5>
                                                <label htmlFor="spouse_name">Password</label>
                                                <input type="password" className="form-control" name="password" id="password" placeholder="password"/>
                                            </div>
                                        </div> */}
                                            <div className="col-12 action-buttons">
                                                <button disabled={!this.state.form1Enable || this.state.loading || this.state.uploading} className="btn-blue float-end blue-grad" type="submit">
                                                    {(this.state.loading == true) ?
                                                        'Loading . . . .' :
                                                        (this.state.uploading == true) ? 'Uploading file  . . . .' : 'Save changes'}</button>
                                                {/* <a href="#" className="text-blue float-end">Don't save</a> */}
                                            </div>
                                        </div>
                                    </form>

                                </div>

                                {/* <!-- About me content pane --> */}
                                <div className={`tab-pane fade  pill-about ${this.state.activetab == 2 ? 'show active' : ''}`} id="pill-about" role="tabpanel" aria-labelledby="pill-about-tab">

                                    <form onSubmit={this.handleSubmitExtra} className="edit-about-me bordered">
                                        <div className="row px-2">
                                            <div className="col-12 pt-2">
                                                <h5 className="text-blue">
                                                    What language do you speak at home?
                                                </h5>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <ul className="list-inline spacing">
                                                        <li className="list-inline-item">
                                                            <input type="radio" value="English" onChange={this.handleLanguage} checked={(this.state.language == 'English')} className="form-check-input" id="validationFormCheck2" name="language" required />
                                                            <label className="form-check-label" htmlFor="validationFormCheck2">English</label>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <input type="radio" value='' onChange={this.handleLanguage} checked={(this.state.language == 'English') ? false : true} className="form-check-input" id="validationFormCheck3" name="language" required />
                                                            <label className="form-check-label" htmlFor="validationFormCheck3">Other</label>
                                                        </li>
                                                        {/* {(this.state.userdetails.language == 'English')} */}


                                                        <li className="list-inline-item">
                                                            <input type="text" onChange={this.handleLanguage}
                                                                pattern="^[a-zA-Z0-9\s]+$" minLength={2} maxLength={40}
                                                                // onKeyDown={(e) => {
                                                                //     var x = e.key;
                                                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                                //         e.preventDefault();
                                                                //     }
                                                                // }}
                                                                className="form-control" name="language" value={(this.state.language == 'English') ? '' : this.state.language} id="language_name" placeholder="Language name" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <h5 className="text-blue">
                                                    Do you have any pets?
                                                </h5>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <ul className="list-inline spacing">
                                                        <li className="list-inline-item">
                                                            <input type="radio" onChange={this.handleIspet} checked={(this.state.pets_status == false)} className="form-check-input" id="validationFormCheck4" value="No" name="is_pet" required />
                                                            <label className="form-check-label" htmlFor="validationFormCheck4">No</label>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <input type="radio" onChange={this.handleIspet} checked={(this.state.pets_status == true)} className="form-check-input" id="validationFormCheck5" value="Yes" name="is_pet" required />
                                                            <label className="form-check-label" htmlFor="validationFormCheck5">Yes</label>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <input type="text" onChange={this.handlePetDetails} value={(this.state.pets_status == true) ? this.state.pets_details : ''} className="form-control" name="pets_name" id="pets_name" placeholder="List pets here" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-7 py-3">
                                                <h5 className="text-blue">Select amenities</h5>
                                                <Select
                                                    required={true}
                                                    onChange={this.handleAmenities}
                                                    isMulti
                                                    value={this.state.amenities}
                                                    options={this.state.getAmenities.map((value, index) => { return { value: value.amenity_id, label: value.name }; })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-12 children-count py-1">
                                                <span className="h5 text-blue pe-4">
                                                    Number of children
                                                </span>
                                                <button onClick={this.handleMinus} className="number-control" id="count-minus" type="button"><i className="fa fa-minus-circle"></i></button>
                                                <input type="number" name="children" id="children" className="number-of-children" defaultValue={this.state.child_age.length} />
                                                <button onClick={this.handlePlus} className="number-control" id="count-plus" type="button"><i className="fa fa-plus-circle"></i></button>
                                            </div>
                                            <div className="col-12 children-age pb-3">

                                                {this.state.child_age.map((value, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="col align-self-center">
                                                                <h6 htmlFor="" className="child-number m-0 " data-count="1">
                                                                    Child {index + 1}
                                                                </h6>
                                                            </div>
                                                            <div className="row">

                                                                <div className="col-3 ">
                                                                    {/* <h4 className="text-blue">Child name</h4> */}

                                                                    <li className="list-inline-item">
                                                                        <input
                                                                            type="text"
                                                                            name="f_name"
                                                                            id="f_name"
                                                                            className="form-control"
                                                                            placeholder="Child name"
                                                                            value={value.f_name}
                                                                            title="Child name should be 2 characters or more."
                                                                            minLength={2} maxLength={40} pattern="^[a-zA-Z0-9\s]+$"
                                                                            // onKeyDown={(e) => {
                                                                            //     var x = e.key;
                                                                            //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                                            //         e.preventDefault();
                                                                            //     }
                                                                            // }}
                                                                            onChange={(event) => this.handleChildInput(event, index)}
                                                                            required
                                                                        />
                                                                    </li>
                                                                </div>
                                                                <div className="col-5 ">
                                                                    {/* <h4 className="text-blue">Child date of birth</h4> */}

                                                                    <input
                                                                        type="date"
                                                                        name="dob"
                                                                        autoComplete="off"
                                                                        //key={index}
                                                                        title="Please enter appropriate birthdate of your child."
                                                                        className='form-control'
                                                                        value={value.dob}
                                                                        placeholder="DOB"
                                                                        onChange={(event) => this.handleChildInput(event, index)}
                                                                        max={moment().subtract(0, 'years').format('YYYY-MM-DD')}
                                                                        min={moment().subtract(10, 'years').add(1, 'days').format('YYYY-MM-DD')}
                                                                        required

                                                                    />
                                                                </div>
                                                                <div className="col-4">
                                                                    {moment().diff(value.dob, 'years') == 1 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="1-2 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 0 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="0-12 months" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 2 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="2-3 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 3 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="3-4 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 4 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="4-5 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 5 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="5-6 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 6 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="6-7 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 7 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="7-8 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 8 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="8-9 yrs" />) : ''}
                                                                    {moment().diff(value.dob, 'years') == 9 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="9-10 yrs" />) : ''}
                                                                    {value.dob == '' ? (<input type="text" readOnly name="name" placeholder="Age group" required value="0-12 months" />) : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            <div className="col-12 col-md-7 py-3 action-buttons">
                                                <button className="btn-blue float-end blue-grad" disabled={!this.state.form2Enable} type="submit">Save changes</button>
                                                {/* <a href="#" className="text-blue float-end">Don't save</a> */}
                                            </div>
                                        </div>
                                    </form>

                                </div>

                                {/* <!-- References content pane --> */}
                                <div className={`tab-pane fade  pill-references ${this.state.activetab == 3 ? 'show active' : ''}`} id="pill-references" role="tabpanel" aria-labelledby="pill-references-tab">

                                    <form onSubmit={this.handleSubmitReferences} className="references bordered">
                                        <div className="row px-2">
                                            <div className="col-12">
                                                <span className="h4 text-blue ">References</span>
                                                <div className="row references py-2">
                                                    {this.state.references.map((value, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className="col-12">
                                                                    <h5 className="text-grey mb-0">Reference {index + 1}</h5>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <label htmlFor={"ref" + (index + 1) + "f_name"}>First name</label>
                                                                    <input
                                                                        title="Please enter a proper reference first name and do not use digits."
                                                                        pattern="^[a-zA-Z0-9\s]+$"
                                                                        minLength={2}
                                                                        maxLength={40}
                                                                        // onKeyDown={(e) => {
                                                                        //     var x = e.key;
                                                                        //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                                        //         e.preventDefault();
                                                                        //     }
                                                                        // }}
                                                                        onChange={(event) =>
                                                                            this.handleReferences(event, index)
                                                                        }
                                                                        value={value.f_name}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="f_name"
                                                                        id={"ref" + (index + 1) + "f_name"}
                                                                        placeholder="First name"
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="col-12 col-md-6">
                                                                    <label htmlFor={"ref" + (index + 1) + "l_name"}>Last name</label>
                                                                    <input
                                                                        title="Please enter a proper reference last name and do not use digits."
                                                                        pattern="^[a-zA-Z0-9\s]+$"
                                                                        minLength={2}
                                                                        maxLength={40}
                                                                        // onKeyDown={(e) => {
                                                                        //     var x = e.key;
                                                                        //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                                        //         e.preventDefault();
                                                                        //     }
                                                                        // }}
                                                                        onChange={(event) =>
                                                                            this.handleReferences(event, index)
                                                                        }
                                                                        value={value.l_name}

                                                                        type="text"
                                                                        className="form-control"
                                                                        name="l_name"
                                                                        id={"ref" + (index + 1) + "l_name"}
                                                                        placeholder="Last name"
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className='row'>
                                                                <div className="col-3 col-md-3 ref_ccode">
                                                                    <label htmlFor="country_code">Country Code</label>
                                                                    <select
                                                                        onChange={(event) =>
                                                                            this.handleReferences(event, index)
                                                                        }
                                                                        name="country_code"
                                                                        className="c_code"
                                                                    >
                                                                        {/* <option defaultValue={(value.country_code == '+91') } value="+91">+91 </option> */}
                                                                        <option defaultValue={(value.country_code == '+1')} value="+1">+1</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-9 col-md-5 ">
                                                                    <label htmlFor={"ref" + (index + 1) + "_phone"}>Number (10 digits)</label>
                                                                    <input
                                                                        title="Please enter exactly 10 digits with correct area code."
                                                                        inputMode="numeric"
                                                                        pattern="^[2-9][0-9]*$"
                                                                        maxLength={10}
                                                                        minLength={10}
                                                                        onChange={(event) =>
                                                                            this.handleReferences(event, index)
                                                                        }
                                                                        type="text"
                                                                        value={value.phone}
                                                                        className="form-control"
                                                                        name="phone"
                                                                        id={"ref" + (index + 1) + "_phone"}
                                                                        placeholder="Reference phone number"
                                                                        required
                                                                    />

                                                                </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>

                                            <div className="col-12 py-3 action-buttons">
                                                <button className="btn-blue float-end blue-grad" disabled={!this.state.form3Enable} type="submit">Save changes</button>
                                                {/* <a href="#" className="text-blue float-end">Don't save</a> */}
                                            </div>
                                        </div>
                                    </form>

                                </div>

                                {/* <!-- Payments content pane --> */}
                                <div className={`tab-pane fade  pill-payments ${this.state.activetab == 4 ? 'show active' : ''}`} id="pill-payments" role="tabpanel" aria-labelledby="pill-payments-tab">

                                    <Elements stripe={stripePromise}>
                                        <CardSetupForm  ></CardSetupForm>
                                    </Elements>
                                </div>

                                {/* <!-- Password content pane --> */}
                                {/* <div className={`tab-pane fade  pill-payments ${this.state.activetab == 5 ? 'show active' : ''}`} id="pill-password" role="tabpanel" aria-labelledby="pill-payments-tab">

                                <form onSubmit={this.handleSubmitPassword} className="edit-personal-details bordered">
                                    <div className="row px-2">
                                        <div className="col-12 col-md-7 ">
                                            <label htmlFor="current_password">Current password</label>
                                            <input type="password" title="Please include at least 1 uppercase character, 1 lowercase character, 1 special character and 1 number." minLength={7} maxLength={15} pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[@#$%^&+=]).*$" value={this.state.current_password} onChange={this.handleInputText} className="form-control" name="current_password" id="current_password" placeholder="Password" required />
                                            <h6 className="text-pink" >
                                                If you don’t remember your current password, logout of your profile, and use the forgot password option to reset your password
                                            </h6>
                                        </div>
                                        <div className="col-12 col-md-7 ">
                                            <label htmlFor="new_password">New password</label>
                                            <input type="password" title="Please include at least 1 uppercase character, 1 lowercase character, 1 special character and 1 number." minLength={7} maxLength={15} pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[@#$%^&+=]).*$" value={this.state.password} className="form-control" onChange={this.handleInputText} name="password" id="new_password" placeholder="Password" required />
                                        </div>
                                        <div className="col-12 col-md-7 ">
                                            <label htmlFor="confirm_password">Confirm new password</label>
                                            <input type="password" title="Please include at least 1 uppercase character, 1 lowercase character, 1 special character and 1 number." minLength={7} maxLength={15} pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[@#$%^&+=]).*$" value={this.state.new_password} className="form-control" name="new_password" onChange={this.handleInputText} id="confirm_password" placeholder="Password" required />
                                        </div>
                                        <div className="col-12 col-md-7 py-3 action-buttons">
                                            <button className="btn-blue float-end blue-grad" type="submit">Save changes</button>
                                        </div>
                                    </div>
                                </form>

                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
        );
    }
}

export default ParentProfile;