import React, { Component } from "react";
import Layoutone from "../../layouts/Layoutone";
import { Link } from "react-router-dom";
import Api from "../../context/Api";
import { toast } from "react-toastify";

class ResetPass extends Component {

  
  constructor(props) {
    super(props);
    let Query = this.props.location.search;
    let UserType = new URLSearchParams(Query).get('usertype');
    let OtpToken = new URLSearchParams(Query).get('otp-token');
    this.state = {
      usertype: UserType ?? null,
      email:window.localStorage.getItem('email'),
      token:window.localStorage.getItem('otp_token'),
      error: [],
      password:'',
      confirmpassword:'',
      success:false,
      loading:false
    }
    //console.log(OtpToken);


  }

handlePassword = e => {
  const pwdCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
  if(!pwdCheck.test(e.target.value)){
    this.setState({ error: { password: "Please enter a password as per password pattern instructions" } });
  } else {
    this.setState({ password:e.target.value, error: { password: ""} });
  }
}

handleConfirmPassword = e => {
  let Password = this.state.password;
  let ConfirmPassword = e.target.value;
  let ErrorMessage = ''
  if(Password !== ConfirmPassword){
    ErrorMessage = 'Confirm password not matched'; 
  }
  this.setState({confirmpassword:e.target.value,error:{ reset : ErrorMessage} });
}

handleSubmit = e => {

  e.preventDefault();
    
      // const pwdCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/
      const pwdCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
      if(!pwdCheck.test(this.state.password)){
        this.setState({ error: { password: "Please enter a password as per password pattern instructions" } });
        toast("Please enter a password as per password pattern instructions", { type: "error" });
        return null;
      }
      else if(this.state.password.length === 0 || this.state.password === "" || this.state.password.length <= 5){
        this.setState({ error: { password: "Please enter a password" } });
        toast("Please enter a password and follow password pattern instructions.", { type: "error" });
        return null;
      }
     else if(this.state.password !== this.state.confirmpassword){
        this.setState({error:'Confirm password not matched'})
        toast('Confirm password not matched',{type:'error'})
        return null;
    } else {
      let data = {
        otp_token : this.state.token,
        new_password : this.state.password
      }
    
      this.setState({loading:true})
      Api.post('/auth/reset-password',data).then((response)=> {
          this.setState({success:true,error:'',loading:false})
          
          toast(response.data.message,{type:'success'});
          window.localStorage.setItem('otp_token',null);
          this.props.history.push(`/login`);
        }).catch((e)=>{
          
          toast(e.response.data.message,{type:'error'});
          this.setState({error:e.response.data.message,loading:false})
        })
      }
}




  render() {
    return (
      <Layoutone MyClass="hero-login reset-password-page" >
        <div className="row justify-content-center justify-content-md-start">
          <div className="col-10 col-md-9">
            <h2>Reset Password</h2>
            <form onSubmit={this.handleSubmit} className="login-form needs-validation form-control" noValidate >
                <input type="password" onChange={this.handlePassword} 
                title="Use 6 or more characters with a mix of letters, numbers & symbols. Example: Abcde$1"
                minLength={6}
                maxLength={15}
                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
                className="form-control is-valid" name="password" id="password" placeholder="Password" required />
                <small className="text-warning">Password must be at least 6 characters in length with minimum 1 uppercase, 1 lowercase, 1 symbol from @#$%^&-+= and 1 number characters.</small>
                <small style={{ whiteSpace: 'nowrap' }} className="text-danger">
                  {" "}
                  {this.state.error.password ?? ""}{" "}
                </small>
                <input type="password" onChange={this.handleConfirmPassword} className="form-control" name="confirmPassword" id="confirmPassword" placeholder="Confirm password" required />
                <small className="text-warning" >{this.state.error.reset} </small>
                <button type="submit" className="btn-white small-btn">{this.state.loading ? 'loading . . . .' : 'Reset password'  }</button>
            </form>
            <div className="col-12 text-center back-btn">
              <Link to="/login"><i className="fa fa-chevron-left"> </i> Back to login </Link>
            </div>
          </div>
        </div>
      </Layoutone>
    );
  }
}

export default ResetPass;
