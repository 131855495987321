/* eslint-disable eqeqeq */
import React, { Component } from "react";
import Layouttwo from "../../../layouts/Layouttwo";
import AvatarPlaceholder from "../../../assets/images/avatar.png";
import Api from "../../../context/Api";
import moment from 'moment';
import { toast } from "react-toastify";
import Select from "react-select";
import Cookies from "js-cookie";
import S3bucket from "../../../services/S3bucket";

import { Link } from "react-router-dom";

class ParentSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp_token: window.localStorage.getItem("otp_token"),
      firstname: "",
      lastname: "",
      password: "",
      phone_number: "",
      religion: null,
      spousename: "",
      address: "",
      city:'',
      state:'',
      zip_code:'',
      profile_pic: AvatarPlaceholder,
      language: "English",
      ispet: "no",
      petsdetails: "",
      amenities: [],
      getAmenities: [], 
      references: [
        { f_name: "", l_name: "", country_code: "+1", phone: "" },
        { f_name: "", l_name: "", country_code: "+1", phone: "" },
      ],
      url: AvatarPlaceholder,
      email: window.localStorage.getItem("email"),
      child_age: [{f_name: "", dob: "", name: ""}],
      terms:'',
      loading: false,
      error: [],
      uploading: false,
    };
    this.elementRef = React.createRef();
  }

  handleFirstname = (e) => {
    const firstNamePattern = /^[a-zA-Z0-9\s]+$/
    if(!firstNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length<2 || e.target.value.length>40){
      if(e.target.value.trim().length === 0){
        this.setState({firstname: e.target.value, error: { firstname: "First name is required." } });
      } else if(!firstNamePattern.test(e.target.value)){
        this.setState({firstname: e.target.value, error: { firstname: "First name should consist of alphabets only." } });
      } else if(e.target.value.length<2){
        this.setState({firstname: e.target.value, error: { firstname: "First name should be 2 characters or more." } });
      } else if(e.target.value.length>40){
        this.setState({firstname: e.target.value, error: { firstname: "First name should be 40 characters or less than 40 characters." } });
      }
    } else if(e.target.value.length>1 && e.target.value.length<41){
      this.setState({ firstname: e.target.value, error: { firstname: "" } });
      }
  };
  handleLastname = (e) => {
    const lastNamePattern = /^[a-zA-Z0-9\s]+$/
    if(!lastNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length<2 || e.target.value.length>40){
      if(e.target.value.trim().length === 0){
        this.setState({lastname: e.target.value, error: { lastname: "Last name is required." } });
      } else if(!lastNamePattern.test(e.target.value)){
        this.setState({lastname: e.target.value, error: { lastname: "Last name should consist of alphabets only." } });
      } else if(e.target.value.length<2){
        this.setState({lastname: e.target.value, error: { lastname: "Last name should be 2 characters or more." } });
      } else if(e.target.value.length>40){
        this.setState({lastname: e.target.value, error: { lastname: "Last name should be 40 characters or less than 40 characters." } });
      }
    } else{
      this.setState({ error: { lastname: "" }, lastname: e.target.value });
    }
  };
  handlePhoneNumber = (e) => {
    const PhoneNumberPattern = /^[2-9][0-9]*$/
    if(!PhoneNumberPattern.test(e.target.value) || e.target.value.length === 0 || e.target.value.length>11){
      if(e.target.value.length === 0){
        this.setState({phone_number: e.target.value, error: { phone_number: "Phone number is required." } });
      } else if(!PhoneNumberPattern.test(e.target.value)){
        this.setState({phone_number: e.target.value, error: { phone_number: "Phone number should consist of 10 numbers only." } });
      } else if(e.target.value.length<10){
        this.setState({phone_number: e.target.value, error: { phone_number: "Phone number should consist of 10 numbers." } });
      } else if(e.target.value.length>11){
        this.setState({phone_number: e.target.value, error: { phone_number: "Phone number should consist of 10 numbers." } });
      }
    } else{
      this.setState({ error: { phone_number: "" }, phone_number: e.target.value });
    }
  };
  handlePassword = (e) => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/
        if(!passwordPattern.test(e.target.value) || e.target.value.length<6) {
        this.setState({ error: { password:"\nPassword must be at least 6 characters in length with minimum 1 uppercase, 1 lowercase, 1 symbol and 1 number characters." } ,password: e.target.value })
        } else{
        this.setState({ password: e.target.value, error: { password: "" }} );
        }
  };
  // handleReligion = (e) => {
  //   this.setState({ religion: e.target.value });
  // };
  handleSpousename = (e) => {
      const spouseNamePattern = /^[a-zA-Z0-9\s]+$/
      if(!spouseNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length<2 || e.target.value.length>40){
        if(e.target.value.trim().length===0){
          this.setState({ spousename: e.target.value, error: { spousename: "Spouse name is optional." } });
        } else if(!spouseNamePattern.test(e.target.value)){
          this.setState({ spousename: e.target.value, error: { spousename: "Spouse name should consist of alphabets only." } });
        } else if(e.target.value.length<2){
          this.setState({spousename: e.target.value, error: { spousename: "Spouse name should be 2 characters or more." } });
        } else if(e.target.value.length>40){
          this.setState({spousename: e.target.value, error: { spousename: "Spouse name should be 40 characters or less than 40 characters." } });
        }
      } else{
        this.setState({ spousename: e.target.value, error: { spousename: "" } });
      }
  };
  handleAddress = (e) => {
    if( e.target.value.trim().length===0 || e.target.value.length<5 || e.target.value.length>250){
        if(e.target.value.trim().length===0)  {
          this.setState({address: e.target.value, error: { address: "Address is required." } });
          return null;
        } else if(e.target.value.length<5){
          this.setState({address: e.target.value, error: { address: "Address should be 5 characters or more." } });
          return null;
        } else if(e.target.value.length>150){
          this.setState({address: e.target.value, error: { address: "Address should be 250 characters or less than 250 characters." } });
          return null;
        }
      } else{
        this.setState({ address: e.target.value, error: { address: "" } });
      }
  };
  handleCity = (e) => {
    const cityPattern = /^[a-zA-Z0-9\s]+$/
    if( e.target.value.trim().length===0 || !cityPattern.test(e.target.value) || e.target.value.length<2 || e.target.value.length>40){
      if(e.target.value.trim().length===0){
        this.setState({city: e.target.value , error: { city: "City name is required." } });
      }else if(!cityPattern.test(e.target.value)){
        this.setState({city: e.target.value , error: { city: "City name should consist of alphabets only." } });
      } else if(e.target.value.length<2){
        this.setState({city: e.target.value , error: { city: "City name should be 2 characters or more." } });
      } else if(e.target.value.length>40){
        this.setState({ city: e.target.value , error: { city: "City name should be 40 characters or less than 40 characters." } });
      }
    } else{
      this.setState({ city: e.target.value , error: { city: "" } });
    }
  };
  handleState = (e) => {
    const statePattern = /^[a-zA-Z0-9\s]+$/
    if(e.target.value.trim().length===0 || !statePattern.test(e.target.value) || e.target.value.length<2 || e.target.value.length>40){
        if(e.target.value.trim().length===0){
          this.setState({ state: e.target.value, error: { addressState: "State name is required." } });
        }else if(!statePattern.test(e.target.value)){
          this.setState({ state: e.target.value, error: { addressState: "State name should consist of alphabets only." } });
        } else if(e.target.value.length<2){
          this.setState({ state: e.target.value , error: { addressState: "State name should be 2 characters or more." } });
        } else if(e.target.value.length>40){
          this.setState({ state: e.target.value, error: { addressState: "State name should be 40 characters or less than 40 characters." } });
        }
    } else{
      this.setState({ state: e.target.value, error: { state: "" } });
    }
  };
  handleZipCode = (e) => {
    const zipPattern = /^[0-9][0-9]*$/
    //console.log('match or not ',zipPattern.test(e.target.value));
    if(e.target.value.length===0 || !zipPattern.test(e.target.value) || e.target.value.length<5 || e.target.value.length>5){
      if(e.target.value.length===0){
        this.setState({zip_code: e.target.value, error: { zip: "Zip code is required." } });
      }if(!zipPattern.test(e.target.value)){
          this.setState({zip_code: e.target.value, error: { zip: "Zip code should consist of numbers only." } });
        } else if(e.target.value.length<5 || e.target.value.length>5){
          this.setState({ zip_code: e.target.value, error: { zip: "Zip code should consist of 5 numbers only." } });
        }
    } else{
      this.setState({ zip_code: e.target.value, error: { zip: "" } });
    }
  };
  handleLanguage = (e) => {
      this.setState({ language: e.target.value });
  };
  handleIspet = (e) => {
    if(e.target.value!=="no"){
      this.setState({ispet: e.target.value , error: { pets: "Please provide details about your pet." } });
    }else{
      this.setState({ispet: e.target.value , error: { pets: "" } });
    }
   
  };
  handlePetDetails = (e) => {
    // if(e.target.value == '')
    this.setState({ petsdetails: e.target.value, ispet: "yes" });
  };
  handleAmenities = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      this.setState({ amenities: selectedOptions.map((value) => value.value), error: { amenities: "" } });
    } else{
      this.setState({ amenities: selectedOptions.map((value) => value.value), error: { amenities: "Please select amenities" } });
    }
  };
  handleReferences = (e, index) => {
    const References = [...this.state.references];

    if (e.target.name == 'f_name') {
      const firstNamePattern = /^[a-zA-Z0-9\s]+$/
     if(!firstNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length === "" || e.target.value.length === undefined){
        if(!firstNamePattern.test(e.target.value)){
            References[index]['f_name'] = e.target.value;
            this.setState({references: References, error: { refFname: "Reference first name can only have alphabets." } });
            return null;
        }
        References[index]['f_name'] = e.target.value;
        this.setState({references: References, error: { refFname: "Reference first name is required." } });
        return null;
      }
        References[index]['f_name'] = e.target.value;
        this.setState({ references: References});
      //console.log(this.state.child_age);
    } else if (e.target.name == 'l_name') {
      const lastNamePattern = /^[a-zA-Z0-9\s]+$/
      if(!lastNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.length === "" || e.target.value.length === undefined){
        if(!lastNamePattern.test(e.target.value)){
            References[index]['l_name'] = e.target.value;
            this.setState({references: References, error: { refFname: "Reference last name can only have alphabets." } });
            return null;
        }
        References[index]['l_name'] = e.target.value;
        this.setState({references: References, error: { refLname: "Reference last name is required." } });
      }
      References[index]['l_name'] = e.target.value;
      this.setState({ references: References});
    } else if(e.target.name == 'phone'){
      let phonePattern = /^[2-9][0-9]*$/
      if(e.target.value.length === 0 || e.target.value.length === "" || e.target.value.length === undefined  || e.target.value.length < 10){
          References[index]['phone'] = e.target.value;
          this.setState({references: References, error: { refPhone: "Reference phone detail is required." } });
          return null;
      } else if(!phonePattern.test(e.target.value)){
          References[index]['phone'] = e.target.value;
          this.setState({references: References, error: { refPhone: "Reference phone detail should be a number." } });
          return null;
      }
      References[index]['phone'] = e.target.value;
      this.setState({ references: References});
    }
  };

  termsAccepted = (e) => {
    const  { checked }= e.target;
     if(checked){
      this.setState({ terms: "yes", error: { terms: "" } });
    }else{
      this.setState({ terms: "no", error: { terms: "In order to use our services you need to agree to our Terms of Use." } });
      return null;
    }
  }

  // handlePlus = (e) => {
  //   if (this.state.child_age.length < 8) {
  //     const Age = this.state.child_age;
  //     this.setState({ child_age: [...Age, "0-12 "] });
  //   }
  // };
  // handleMinus = (e) => {
  //   if (this.state.child_age.length > 1) {
  //     const Age = this.state.child_age;
  //     const lastAge = Age.length - 1;
  //     this.setState({
  //       child_age: Age.filter((item, index) => index !== lastAge),
  //     });
  //   }
  // };

  // handleChildAge = (e, index) => {
  //   const ChildAge = [...this.state.child_age];
  //   ChildAge[index] = e.target.value;
  //   this.setState({ child_age: ChildAge });
  // };
  //New features added:

  handlePlus = (e) => {
    if (this.state.child_age.length < 8) {
      const element = {
        f_name: "",
        dob: "",
        name: ""
      };
      this.setState({ child_age: [...this.state.child_age, element] });
    }
  };
  handleMinus = (e) => {
    if (this.state.child_age.length > 1) {
      const Age = this.state.child_age;
      const lastAge = Age.length - 1;
      this.setState({
        child_age: Age.filter((item, index) => index !== lastAge),
      });
    }
  };
  handleChildInput = (e, index) => {
    const Childage = [...this.state.child_age];

    if (e.target.name === 'f_name') {
      if(e.target.value.trim().length === 0 || e.target.value.length === "" || e.target.value.length === undefined){
        Childage[index]['f_name'] = e.target.value;
        this.setState({child_age: Childage, error: { childName: "Please fill the child name." } });
        return null;
      }
      Childage[index]['f_name'] = e.target.value;
        this.setState({ child_age: Childage, error: { childName: "" } });
    } else if (e.target.name === 'dob') {
        if(e.target.validity.valueMissing || e.target.value.length===0 || e.target.value.length === "" || e.target.value.length === undefined){
          Childage[index]['dob'] = e.target.value;
          e.target.setCustomValidity('Please provide a birthdate of your child.');
          this.setState({ error: { childDob: "Please fill the child dob." } });
          return null;
        } else if(e.target.validity.rangeUnderflow || e.target.value < moment().subtract(10, 'years').add(1, 'days').format('YYYY-MM-DD')){
          Childage[index]['dob'] = e.target.value;
          e.target.setCustomValidity('Child is too old for babysitting.');
          this.setState({ error: { childDob: "Child is too old for babysitting." } });
          return null;
        } else if(e.target.validity.rangeOverflow || e.target.value > moment().subtract(0, 'years').format('YYYY-MM-DD')){
          Childage[index]['dob'] = e.target.value;
          e.target.setCustomValidity('Child is too young for babysitting.');
          this.setState({ error: { childDob: "Child is too young for babysitting." } });
          return null;
        } else {
          e.target.setCustomValidity('');
          this.setState({ error: { childDob: "" } });
          Childage[index]['dob'] = e.target.value;
          this.setState({ child_age: Childage });
        }
        let age = moment().diff(e.target.value, 'years');
        
        if (age >= 0 && age < 1) {
          Childage[index]['name'] = "0-12 months";
          this.setState({ child_age: Childage });
        } else if (age >= 1 && age < 2) {
          Childage[index]['name'] = "1-2 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 2 && age < 3) {
          Childage[index]['name'] = "2-3 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 3 && age < 4) {
          Childage[index]['name'] = "3-4 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 4 && age < 5) {
          Childage[index]['name'] = "4-5 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 5 && age < 6) {
          Childage[index]['name'] = "5-6 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 6 && age < 7) {
          Childage[index]['name'] = "6-7 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 7 && age < 8) {
          Childage[index]['name'] = "7-8 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 8 && age < 9) {
          Childage[index]['name'] = "8-9 yrs";
          this.setState({ child_age: Childage });
        } else if (age >= 9 && age < 10) {
          Childage[index]['name'] = "9-10 yrs";
          this.setState({ child_age: Childage });
        } else {
          Childage[index]['name'] = "0-12 months";
          this.setState({ child_age: Childage });
        }
    } else if (e.target.name == 'child-name') {
      //let age = moment().diff(e.target.value, 'years');
      Childage[index]['name'] = e.target.value;
      //console.log(ChildAgeGroups[index]);
      this.setState({ child_age: Childage });
    }
  };

  handleProfileImage = (e) => {
    var File = e.target.files[0];
    if(File !== undefined){
      if(File.type.match('image/jpeg')||File.type.match('image/jpg')||File.type.match('image/png')||File.type.match('image/gif')||File.type.match('image/webp')){
        var reader = new FileReader();
        // eslint-disable-next-line no-unused-vars
        var url = reader.readAsDataURL(File);
        this.setState({
          uploading: true,
          url: URL.createObjectURL(File),
        });
        S3bucket.uploadFile(File, File.name)
          .then((res) => {
            this.setState({ uploading: false, profile_pic: res.location });
            //console.log(res.location);
          })
          .catch((error) => {
            //console.log(error);
          });
      } else{
        e.target.value = null;
        File = e.target.files[0];
        toast("The selected file is not an image or allowed image file type. Please select an image file.", { type: "error" });
      }
      }
        
  };

  handleSubmit = (e) => {
    e.preventDefault();
      // console.log('FIRSTnAME: ',this.state.firstname);
      if(this.state.firstname.trim().length === 0 || this.state.firstname === "" || this.state.firstname === undefined){
        this.setState({ error: { firstname: "Please enter first name" } });
        toast("Please enter first name", { type: "error" });
        return null;
      }
      if(this.state.lastname.trim().length === 0 || this.state.lastname === "" || this.state.lastname === undefined){
        this.setState({ error: { lastname: "Please enter last name" } });
        toast("Please enter last name", { type: "error" });
        return null;
      }
      if(this.state.phone_number.trim().length === 0 || this.state.phone_number === "" || this.state.phone_number === undefined){
        this.setState({ error: { phone_number: "Please enter phone number" } });
        toast("Please enter phone number", { type: "error" });
        return null;
      }
      if(this.state.password || this.state.password.length === 0 || this.state.password || this.state.password === ""){
        const pwdCheck = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/
        // console.log('match or not ',pwdCheck.test(this.state.password));
        if(!pwdCheck.test(this.state.password)){
          this.setState({ error: { password: "Please enter a password as per password pattern instructions" } });
          toast("Please enter a password as per password pattern instructions", { type: "error" });
          return null;
        }
        else if(this.state.password.length === 0 || this.state.password === ""){
          this.setState({ error: { password: "Please enter a password" } });
          toast("Please enter a password and follow password pattern instructions.", { type: "error" });
          return null;
        }
      }
      if(this.state.address.trim().length === 0 || this.state.address === "" || this.state.address === undefined  || this.state.address<5 || this.state.address.length>250){
        this.setState({ error: { address: "Please provide an address is required and it should be minimum 5 and maximum 250 characters in length." } });
        toast("Please provide an address.", { type: "error" });
        return null;
      }
      if(this.state.state.trim().length === 0 || this.state.state === "" || this.state.state === undefined){
        this.setState({ error: { addressState: "Please provide a state" } });
        toast("Please provide a state.", { type: "error" });
        return null;
      }
      if(this.state.city.trim().length === 0 || this.state.city === "" || this.state.city === undefined){
        this.setState({ error: { city: "Please provide a city" } });
        toast("Please provide a city.", { type: "error" });
        return null;
      }
      if(this.state.zip_code.length === 0 || this.state.zip_code === "" || this.state.zip_code === undefined || this.state.zip_code || this.state.zip_code.length<5 || this.state.zip_code.length>5){
        const zipPattern = /^[0-9][0-9]*$/
      //console.log('match or not ',zipPattern.test(this.state.zip_code));
          if(!zipPattern.test(this.state.zip_code)){
              this.setState({zip_code: this.state.zip_code, error: { zip: "Zip code is required and should consist of 5 numbers only." } });
              toast("Zip code should consist of 5 numbers only.", { type: "error" });
              return null;
          }
          if(this.state.zip_code.length===0){
            this.setState({zip_code: this.state.zip_code, error: { zip: "Zip code is required." } });
            toast("Please enter a Zip code.", { type: "error" });
            return null;
          }
          if(this.state.zip_code.length<5 || this.state.zip_code.length>5){
            this.setState({ zip_code: this.state.zip_code, error: { zip: "Zip code should consist of 5 numbers only." } });
            toast("Please provide a 5 number Zip code.", { type: "error" });
            return null;
          }
      }
      if(this.state.language !== "English" && this.state.language.trim().length === 0){
        this.setState({error: { otherLanguage: "Please provide a other language." } });
        toast("Please fill the other language.", { type: "error" });
        return null;
      }
      if(this.state.ispet !== "no" && this.state.petsdetails.trim().length === 0){
        this.setState({error: { pets: "Please provide details about your pet." } });
        toast("Please fill the pet details.", { type: "error" });
        return null;
      }
      if (this.state.amenities.length === 0 || this.state.amenities === "" || this.state.amenities === undefined) {
        this.setState({ error: { amenities: "Please select amenities" } });
        toast("Please select amenities", { type: "error" });
        return null;
      }
      let count=0; //child error counter
      if(this.state.child_age.length >= 0){
        if(this.state.child_age.length < 1){
          toast("Please select atleast one children", { type: "error" });
          return null;
        } else{
          this.state.child_age.forEach((value, index)=>{
            if(this.state.child_age[index].f_name.trim().length===0 || this.state.child_age[index].f_name===undefined || this.state.child_age[index].f_name===""){
              this.setState({ error: { childName: "Child name is required." } });
              count++;
              toast("Please provide child name", { type: "error" });
            } else if(this.state.child_age[index].dob.length===0 || this.state.child_age[index].dob===undefined || this.state.child_age[index].dob===""){
              this.setState({ error: { childDob: "Child dob is required." } });
              count++;
              toast("Please provide child dob", { type: "error" });
            }
          } );
        }
      }
      if(count>0){
        return null;
      }
      let refCount = 0; //ref error counter
      if(this.state.references.length >= 0){
        if(this.state.references.length < 2){
          toast("Please fill both the references.", { type: "error" });
          return null;
        } else {
          this.state.references.forEach((value, index)=>{
            if(this.state.references[index].f_name.trim().length===0 || this.state.references[index].f_name===undefined || this.state.references[index].f_name===""){
              refCount++;
              this.setState({ error: { refFname: "Reference first name is required." } }) 
              toast("Please provide first name of your reference", { type: "error" });
            }
            else if(this.state.references[index].l_name.trim().length===0 || this.state.references[index].l_name===undefined || this.state.references[index].l_name===""){
              refCount++;
              this.setState({ error: { refLname: "Reference last name is required." } }); 
              toast("Please provide last name of your reference", { type: "error" });
            }
            else if(this.state.references[index].phone.length===0 || this.state.references[index].phone===undefined || this.state.references[index].phone===""){
              refCount++;
              this.setState({ error: { refPhone: "Reference phone detail is required." } }); 
              toast("Please provide phone detail of your reference", { type: "error" });
            }
          });
        }
      }
      if(refCount>0){
         return null;
      }
      if (this.state.terms != 'yes' || this.state.terms == 'no' || this.state.terms == '') {
        this.setState({ error: { terms: "Please accept the Terms of Use policy." } });
        toast("Please accept the Terms of Use policy", { type: "error" });
        return null;
      }

    let data = {
      otp_token: this.state.otp_token,
      first_name: this.state.firstname.trim(),
      last_name: this.state.lastname.trim(),
      phone_number: this.state.phone_number,
      address: this.state.address.trim(),
      city: this.state.city.trim(),
      state: this.state.state.trim(),
      zip_code: this.state.zip_code,
      spouse: this.state.spousename.trim(),
      pets_status: this.state.ispet == "no" ? false : true,
      pets_details: this.state.petsdetails.trim(),
      password: this.state.password,
      device_id: "wy3te6ge374y38y3uf834y3u89ryu48f",
      device_type: "1",
      device_token: "q2w3e4er5t6y7u8i9o0m,m",
      religion: this.state.religion,
      image:(this.state.profile_pic == AvatarPlaceholder) ? '' : this.state.profile_pic  ,
      children: this.state.child_age,
      amenities: this.state.amenities,
      references: this.state.references,
      language: this.state.language.trim(),
      terms: this.state.terms
    };

    //console.log(data);
    this.setState({ loading: true });
    // console.log('posting user;==== ');
    Api.post("/auth/register/user", data)
      .then((response) => {
        //console.log(response);
        this.setState({ loading: false });
        toast(response.data.message, { type: "success" });
        Cookies.set("isLoggedIn", true);
        Cookies.set("role", true);
        this.props.history.push(`/login`);

        // Cookies.set('UserName',response.data.);
      })
      .catch((e) => {
        this.setState({ loading: false });
        toast(e.response.data.message, { type: "error" });
        //console.log(e.response.data.message);
      });
  };
  componentDidMount() {
    Api.get("/amenities")
      .then((response) => {
        this.setState({ getAmenities: response.data.data });
      })
      .catch(() => {});
    
  }

  render() {
    return (
      <Layouttwo myclass="parent-registration" ViewName="Parent's registration">
        <form
          onSubmit={this.handleSubmit}
          className="registration needs-validation form-control"
        >
          <div className="row">
            <div className="col-12 col-md-6 form-floating">
              <input
                title="First name should be 2 characters or more." 
                required={true}
                minLength={2} 
                maxLength={40} 
                pattern="^[a-zA-Z0-9\s]+$"
                // onKeyDown={(e) => {
                //   var x = e.key;
                //   // eslint-disable-next-line eqeqeq
                //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                //     e.preventDefault();}}}
                onChange={this.handleFirstname}
                type="text"
                className="form-control"
                name="f_name"
                id="f_name"
                placeholder="First name"
              />
              <label htmlFor="f_name">First name</label>
              <small className="text-danger">
                {" "}
                {this.state.error.firstname ?? ""}{" "}
              </small>
            </div>
            <div className="col-col-12 col-md-6 form-floating">
              <input
                title="Last name should be 2 characters or more." 
                required={true}
                minLength={2} 
                maxLength={40} 
                pattern="^[a-zA-Z0-9\s]+$"
                // onKeyDown={(e) => {
                //   var x = e.key;
                //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                //     e.preventDefault();}}}
                onChange={this.handleLastname}
                type="text"
                className="form-control"
                name="l_name"
                id="l_name"
                placeholder="Last name"
              />
              <label htmlFor="l_name">Last name</label>
              <small className="text-danger">
                {" "}
                {this.state.error.lastname ?? ""}{" "}
              </small>
            </div>
            <div className="col-col-12 col-md-6 form-floating">
              <input
                readOnly
                type="email"
                required={true}
                className="form-control"
                name="email"
                id="email"
                placeholder="Email"
                value={this.state.email}
              />
              <label htmlFor="email">Email</label>
            </div>
            <div className="col-12 col-md-6 form-floating">
              <input
                required={true}
                type="password"
                title="Use 6 or more characters with a mix of letters, numbers & symbols. Example: Abcde$1" 
                minLength={6}
                maxLength={15} 
                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
                onChange={this.handlePassword}
                className="form-control"
                name="password"
                id="password"
                placeholder="Password"
              />
              <label htmlFor="password">Password</label>
              <small className="text-pink">Password must be at least 6 characters in length with minimum 1 uppercase, 1 lowercase, 1 symbol and 1 number characters.</small>
              <small className="text-danger">
                <br></br>{""}
                {this.state.error.password ?? ""}{" "}
              </small>
            </div>
            <div className='col-12 col-md-12 form-floating'>
              <div className='row'>
                <div className="col-4 col-sm-4 col-md-4 ref_ccode">
                  <select
                    title='Country Code'
                    name="country_code"
                    className="c_code"
                  >
                    {/* <option defaultValue={(value.country_code == '+91') } value="+91">+91 </option> */}
                    <option defaultValue={'+1'} value="+1">+1</option>
                  </select>
                </div>
                <div className="col-8 col-md-8 col-lg-6 col-xl-6 form-floating">
                  <input
                    title="Please enter exactly 10 digits with correct area code."
                    inputMode="numeric"
                    pattern="^[2-9][0-9]*$"
                    maxLength={10}
                    minLength={10}
                    onChange={(e) =>
                      this.handlePhoneNumber(e)
                    }
                    type="text"
                    value={this.state.phone_number}
                    className="form-control"
                    name="phone_number"
                    id="phone_number"
                    placeholder="Phone number"
                    required={true}
                  />
                  <label htmlFor="phone_number">Your Phone Number (10 digits)</label>
                  <small className="text-danger">
                    {" "}
                    {this.state.error.phone_number ?? ""}{" "}
                  </small>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-6 form-floating">
              <input
                title="Please enter a religion without digits." 
                minLength={2} 
                maxLength={15} 
                pattern="^[A-Za-z]+$"
                onKeyDown={(e) => {
                  var x = e.key;
                  if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                    e.preventDefault();}}}
                onChange={this.handleReligion}
                type="text"
                className="form-control"
                name="religion"
                id="religion"
                placeholder="Religion"
                required
              />
              <label htmlFor="religion">Religion</label>
            </div> */}
            
            <div className="col-6"></div>
            <div className="col-12 form-floating">
              <input
                title="Spouse name should be 2 characters or more."
                pattern="^[a-zA-Z0-9\s]+$"
                minLength={2} 
                maxLength={40}
                // onKeyDown={(e) => {
                //   var x = e.key;
                //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                //     e.preventDefault();}}}
                onChange={this.handleSpousename}
                type="text"
                className="form-control"
                name="spouse_name"
                id="spouse_name"
                placeholder="Spouse name (optional)"
              />
              <label htmlFor="spouse_name">Spouse name (optional)</label>
            </div>

            <div className="col-12 form-floating">
              <input
                required="required"
                minLength={5}
                maxLength={250}
                onChange={this.handleAddress}
                type="text"
                className="form-control"
                name="address"
                id="address"
                placeholder="Address"
              />
              <label htmlFor="address">Address</label>
              <small className="text-danger">
                {" "}
                {this.state.error.address ?? ""}{" "}
              </small>
            </div>
            <div className="col-4 form-floating">
              <input
                required={true}
                title="Please enter a city name without digits and not more than one space between two words."
                minLength={2}
                maxLength={40}
                pattern="^[a-zA-Z0-9\s]+$"
                // onKeyDown={(e) => {
                //   var x = e.key;
                //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                //     e.preventDefault();}}}
                onChange={this.handleCity}
                type="text"
                className="form-control"
                name="city"
                id="address"
                placeholder="Address"
              />
              <label htmlFor="address">City</label>
              <small className="text-danger">
                {" "}
                {this.state.error.city ?? ""}{" "}
              </small>
            </div>
            <div className="col-4 form-floating">
              <input
                required="required"
                title="Please enter a state name without digits and not more than one space between two words."
                minLength={2}
                maxLength={40}
                pattern="^[a-zA-Z0-9\s]+$"
                // onKeyDown={(e) => {
                //   var x = e.key;
                //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                //     e.preventDefault();}}}
                onChange={this.handleState}
                type="text"
                className="form-control"
                name="address"
                id="address"
                placeholder="Address"
              />
              <label htmlFor="address">State</label>
              <small className="text-danger">
                {" "}
                {this.state.error.addressState ?? ""}{" "}
              </small>
            </div>
            <div className="col-4 form-floating">
              <input
                required="required"
                title="Please enter 5 digits only."
                inputMode="numeric"
                minLength={5}
                maxLength={5}
                pattern="^[0-9][0-9]*$"
                onChange={this.handleZipCode}
                type="text"
                className="form-control"
                name="address"
                id="address"
                placeholder="Address"
              />
              <label htmlFor="address">Zip Code</label>
              <small className="text-danger">
                {" "}
                {this.state.error.zip ?? ""}{" "}
              </small>
            </div>
            <div className="col-12 upload-profile-picture">
              <img src={this.state.url} alt="" />
              <span className="h4 text-blue">
                Upload photo <span className="text-grey">(optional)</span>
              </span>
              <input
                onChange={this.handleProfileImage}
                accept="image/jpg, image/png, image/gif, image/jpeg, image/webp"
                type="file"
                name="profile_pic"
                id="profile_pic"
              />
              <div><small className="text-pink" >Only jpg, jpeg, gif, webp and png image file formats are allowed.</small></div>
            </div>
            <div className="col-12 pt-2">
              <h4 className="text-blue">What language do you speak at home?</h4>
            </div>
            <div className="col-12">
              <div className="form-check">
                <ul className="list-inline spacing">
                  <li className="list-inline-item">
                    <input
                    required="required"
                      checked={this.state.language == "English"}
                      onChange={this.handleLanguage}
                      type="radio"
                      className="form-check-input"
                      id="language-eng"
                      name="language"
                      value="English"
                    />
                    <label className="form-check-label" htmlFor="language-eng">
                      English
                    </label>
                  </li>
                  <li className="list-inline-item">
                    <input
                      required="required"
                      checked={this.state.language != "English"}
                      onChange={this.handleLanguage}
                      type="radio"
                      className="form-check-input"
                      id="language-other"
                      name="language"
                    />
                    <label className="form-check-label" htmlFor="language-other">
                      Other
                    </label>
                  </li>
                  <li className="list-inline-item">
                    <input
                      maxLength={40}
                      required={this.state.language != "English"}
                      onKeyDown={(e) => {
                        var x = e.key;
                        if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                          e.preventDefault();}}}
                      onChange={this.handleLanguage}
                      type="text"
                      htmlFor="language-other"
                      className="form-control"
                      name="language_name"
                      id="language_name"
                      placeholder="List language here"
                    />
                  </li>
                </ul>
                  <small className="text-danger">
                    {" "}
                    {this.state.error.otherLanguage ?? ""}{" "}
                  </small>
              </div>
            </div>

            <div className="col-12 pt-2 ">
              <h4 className="text-blue">Do you have any pets?</h4>
            </div>
            <div className="col-12 ">
              <div className="form-check">
                <ul className="list-inline spacing">
                  <li className="list-inline-item">
                    {" "}
                    <input
                      required={true}
                      type="radio"
                      checked={this.state.ispet == "no"}
                      onChange={this.handleIspet}
                      className="form-check-input"
                      id="ispet-no"
                      name="pets"
                      value="no"
                    />
                    <label className="form-check-label" htmlFor="ispet-no">
                      No
                    </label>
                  </li>
                  <li className="list-inline-item">
                    <input
                      required={true}
                      checked={this.state.ispet != "no"}
                      onChange={this.handleIspet}
                      type="radio"
                      className="form-check-input"
                      id="ispet-yes"
                      name="pets"
                    />
                    <label className="form-check-label" htmlFor="ispet-yes">
                      Yes
                    </label>
                  </li>
                  <li className="list-inline-item">
                   
                    <input
                      maxLength={250}
                      required={this.state.ispet != "no"}
                      onChange={this.handlePetDetails}
                      type="text"
                      value={this.state.petsdetails}
                      className="form-control"
                      name="pets_name"
                      id="pets_name"
                      placeholder="List pets here"
                    />
                  </li>
                </ul>
                  <small className="text-danger">
                    {" "}
                    {this.state.error.pets ?? ""}{" "}
                  </small>
              </div>
            </div>

            <div className="col-12 col-md-7 py-3">
              <h4 className="text-blue">Select amenities</h4>
              <Select
                required={true}
                onChange={this.handleAmenities}
                isMulti
                options={this.state.getAmenities.map((value, index) => {
                  return { value: value.amenity_id, label: value.name };
                })}
              />
              {/* <select onChange={this.handleAmenities} multiple name="amenities" id="amenities">
                                    { this.state.getAmenities.map((value,index) => {
                                        return (<option value={value.amenity_id}>{value.name}</option>);
                                    }) }
                                </select> */}
              <small className="text-danger">
                {" "}
                {this.state.error.amenities ?? ""}{" "}
              </small>
            </div>
            <div className="col-12 col-md-12 children-count py-3">
              <span className="h4 text-blue pe-4">Number of children</span>
              <button
                onClick={this.handleMinus}
                className="number-control"
                id="count-minus"
                type="button"
              >
                <i className="fa fa-minus-circle"></i>
              </button>
              <input
                readOnly
                type="number"
                name="children"
                id="children"
                className="number-of-children"
                value={this.state.child_age.length}
              />
              <button
                onClick={this.handlePlus}
                className="number-control"
                id="count-plus"
                type="button"
              >
                <i className="fa fa-plus-circle"></i>
              </button>
            </div>
            <div className="col-12 children-age pb-3">
              {this.state.child_age.map((value, index) => {
                return (
                  <div key={index}>
                    <div className="col align-self-center">
                      <h6 htmlFor="" className="child-number m-0 " data-count="1">
                        Child {index + 1}
                      </h6>
                    </div>
                    <div className="row">

                      <div className="col-3 ">
                        {/* <h4 className="text-blue">Child name</h4> */}
                        <li className="list-inline-item">
                          <input
                            required="required"
                            type="text"
                            name="f_name"
                            id="f_name"
                            className="form-control"
                            placeholder="Child name"
                            title="Child name should be 2 characters or more."
                            minLength={2} maxLength={40} pattern="^[a-zA-Z0-9\s]+$"
                            // onKeyDown={(e) => {
                            //   var x = e.key;
                            //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                            //     e.preventDefault();}}}
                            onChange={(event) => this.handleChildInput(event, index)}
                          />
                        </li>
                      </div>
                      <div className="col-5 ">
                        {/* <h4 className="text-blue">Child date of birth</h4> */}

                        <input
                          type="date"
                          name="dob"
                          autoComplete="off" 
                          required="required"
                          //key={index}
                          title="Please enter appropriate birthdate of your child."
                          className='form-control'
                          placeholder="DOB"
                          onChange={(event) => this.handleChildInput(event, index)}
                          max={moment().subtract(0, 'years').format('YYYY-MM-DD')}
                          min={moment().subtract(10, 'years').add(1, 'days').format('YYYY-MM-DD')}

                        />
                      </div>
                      
                      <div className="col-4">
                        {moment().diff(value.dob, 'years') == 1 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="1-2 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 0 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="0-12 months" />) : ''}
                        {moment().diff(value.dob, 'years') == 2 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="2-3 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 3 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="3-4 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 4 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="4-5 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 5 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="5-6 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 6 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="6-7 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 7 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="7-8 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 8 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="8-9 yrs" />) : ''}
                        {moment().diff(value.dob, 'years') == 9 ? (<input type="text" readOnly className='form-control' name="name" placeholder="Age group" required onChange={(event) => this.handleChildInput(event, index)} value="9-10 yrs" />) : ''}
                        {value.dob == '' ? (<input type="text" readOnly name="name" placeholder="Age group" required value="0-12 months" />) : ''}
                      </div>
                    </div>
                  </div>
                );
              })}
                    <small className="text-danger">
                      {" "}
                      {this.state.error.childName ?? ""}{" "}
                    </small>
                    <small className="text-danger">
                          {" "}
                          {this.state.error.childDob ?? ""}{" "}
                    </small>
            </div>
            <div className="col-12">
              <span className="h4 text-blue ">References</span>
              <div className="row references py-2">
                {this.state.references.map((value, index) => {
                  return (
                    <div key={index}>
                      <div className="col-12">
                        <h5 className="text-grey mb-0">Reference {index + 1}</h5>
                      </div>
                      <div className="col-12 col-md-6 form-floating">
                        <input
                          title="Please enter a proper reference first name and do not use digits." 
                          pattern="^[a-zA-Z0-9\s]+$" 
                          minLength={2} 
                          maxLength={40}
                          // onKeyDown={(e) => {
                          //   var x = e.key;
                          //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                          //     e.preventDefault();}}}
                          onChange={(event) =>
                            this.handleReferences(event, index)
                          }
                          type="text"
                          className="form-control"
                          name="f_name"
                          id={"ref"+(index+1)+"f_name"}
                          placeholder="First name"
                          required
                        />
                        <label htmlFor={"ref"+(index+1)+"f_name"}>First name</label>
                      </div>
                      <div className="col-12 col-md-6 form-floating">
                        <input
                          title="Please enter a proper reference last name and do not use digits." 
                          pattern="^[a-zA-Z0-9\s]+$" 
                          minLength={2} 
                          maxLength={40}
                          // onKeyDown={(e) => {
                          //   var x = e.key;
                          //   if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                          //     e.preventDefault();}}}
                          onChange={(event) =>
                            this.handleReferences(event, index)
                          }
                          type="text"
                          className="form-control"
                          name="l_name"
                          id={"ref"+(index+1)+"l_name"}
                          placeholder="Last name"
                          required
                        />
                        <label htmlFor={"ref"+(index+1)+"l_name"}>Last name</label>
                      </div>
                      <div className="col-xs-4 col-sm-4 col-md-3 col-4  ref_ccode">
                        <select
                          onChange={(event) =>
                            this.handleReferences(event, index)
                          }
                          name="country_code"
                          className="c_code"
                        >
                          <option value="+1">+1</option>
                          {/* <option value="+91">+91</option> */}
                        </select>
                      </div>
                      <div className="col-xs-6 col-sm-7 col-md-6 col-8 form-floating">
                        <input
                          title="Please enter exactly 10 digits with correct area code."
                          inputMode="numeric"
                          pattern="^[2-9][0-9]*$" 
                          maxLength={10} 
                          minLength={10}
                          onChange={(event) =>
                            this.handleReferences(event, index)
                          }
                          type="text"
                          className="form-control"
                          name="phone"
                          id={"ref"+(index+1)+"_phone"}
                          placeholder="Name"
                          
                          required
                        />
                        <label htmlFor={"ref"+(index+1)+"_phone"}>Number (10 digits)</label>
                      </div>
                    </div>
                  );
                })}
                <small className="text-danger">
                  {" "}
                  {this.state.error.refFname ?? ""}{" "}
                </small>
                <small className="text-danger">
                  {" "}
                  {this.state.error.refLname ?? ""}{" "}
                </small>
                <small className="text-danger">
                  {" "}
                  {this.state.error.refPhone ?? ""}{" "}
                </small>
              </div>
            </div>
            <div className="col-12 py-3">
              <label className="form-check-label " htmlFor="terms_of_use">
              <input
                className="form-check-input m-1"
                title="Please accept the Terms of Use policy."
                name="terms_of_use"
                type="checkbox"
                value=""
                id="terms_of_use"
                onChange={(e)=>this.termsAccepted(e)}
                required
              />
                I agree to the{" "}
                <Link to="/term"  target="_blank" className="text-blue">
                  Terms of Use
                </Link>
                <span> and to only contact Simplisitter Babysitters through <a className="text-blue" target="_blank" rel="noreferrer" href="https://simplisitter.com/#/" >simplisitter.com</a> for any future babysitting jobs.</span>
              </label>
              <div></div>
              <small className="text-danger">
                {" "}
                {this.state.error.terms ?? ""}{" "}
              </small>
              <p className="text-grey">
                For details on collection and use of your information, see our{" "}
                <Link to="/privacy-policy"  target="_blank" className="text-blue"> Privacy Policy</Link>
              </p>
            </div>
            <div className="col-12">
              <button
                disabled={this.state.loading || this.state.uploading}
                className="btn-blue float-end blue-grad"
                type="submit"
              >
                {this.state.loading == true
                  ? "Loading . . . ."
                  : this.state.uploading == true
                  ? "Uploading file  . . . ."
                  : "Register"}
              </button>
            </div>
          </div>
        </form>
      </Layouttwo>
    );
  }
}

ParentSignup.propTypes = {};

export default ParentSignup;
