import React, { Component } from 'react';
import LayoutDash from '../../layouts/DashLayout';
import Personal from '../../components/Profile/Section.js/Parent/Personal';
import About from '../../components/Profile/Section.js/Parent/About';
import ImgAvatar from '../../assets/images/avatar.png'
import AvatarPlaceholder from '../../assets/images/avatar.png';
import {toast} from 'react-toastify'
import Api from '../../context/Api';
import instance from '../../context/AuthApi';
import TokenService from '../../services/Token.Service';
import Select from "react-select";
import ChildAgeGroups from "../../Constants/ChildAgeGroups";
import S3bucket from '../../services/S3bucket';
import moment, {min} from 'moment'
import { Link } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import shortid from 'shortid';
import { Modal } from 'react-bootstrap';


// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';

class SitterProfile extends Component {

    constructor(props){
        super(props);
    
        this.state = {
            activetab:1,
            userdetails:{},
            firstname: "",
            lastname: "",
            parentname: "",
            password: "",
            phone_number: "",
            religion: "",
            school_name: [{school_name:'mm Public',school_id:''}],
            address: "",
            city:'',
            state:'',
            zip_code:'',
            profile_pic: ImgAvatar,
            transportation: "",
            sitter_signature:AvatarPlaceholder,
            hourrate: 0,
            dob:'',
            age:'',
            about:'',
            references: [
                { f_name: "", l_name: "", country_code: "+1", phone: "" ,reference_id: '' },
                { f_name: "", l_name: "", country_code: "+1", phone: "", reference_id:'' },
            ],
            url: ImgAvatar,
            email: '',
            loading: true,
            error: [],
            uploading: false,
            current_password:'',
            new_password:'',
            form1Enable: false,
            form2Enable: false,
            form3Enable: false,
            showSignatureModal: false,
            imageUrl: null,
        }
        this.sigCanvas = null;
        this.elementRef = React.createRef();
    }
    componentDidMount(){
        instance.get('/get-sitter-profile').then((res) => {
            //console.log('Get Profile',res.data.data);
            this.setState({
                loading:false,
                userdetails:res.data.data,
                url: res.data.data.image,
                profile_pic: res.data.data.image,
                firstname: res.data.data.first_name,
                lastname: res.data.data.last_name,
                parentname: res.data.data.parent_name,
                email:res.data.data.email,
                phone_number:res.data.data.phone_number,
                address:res.data.data.address,
                city:res.data.data.city,
                state:res.data.data.state,
                religion:res.data.data.religion,
                dob:res.data.data.dob,
                hourrate:res.data.data.hourly_rate,
                transportation: res.data.data.transportation,
                about:res.data.data.details,
                zip_code:res.data.data.zip_code,
                school_name:res.data.data.sitter_schools,
                references:res.data.data.references,  
                pets_status:res.data.data.pets_status, 
                pets_details:res.data.data.pets_details,
                imageUrl:res.data.data.signature,
                sitter_signature:res.data.data.signature,
            })
        
        }).catch((er) => {
            //console.log(er);
            this.setState({loading:false});

        })
        // const scriptText = document.querySelector(".form").addEventListener("submit", (e) => {
        //     e.preventDefault();
        // });
        this.dob.addEventListener('invalid', this.handledob);
    }

    dataURLtoBlob = (dataURL) => {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        // const b64 = atob(parts[1]);
        const b64 = Buffer.from(parts[1], 'base64').toString('binary');
        let uInt8Array = new Uint8Array(b64.length);
        for (let i = 0; i < b64.length; i++) {
          uInt8Array[i] = b64.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
      }

    handleShowSignatureModal = () => {
        this.setState({ showSignatureModal: true });
      }
    
      handleCloseSignatureModal = () => {
        this.setState({ showSignatureModal: false });
      }
    
      handleClearSignature = () => {
        if (this.sigCanvas) {
          this.sigCanvas.clear();
        }
      }
    
      handleCreateSignature = () => {
        if (this.sigCanvas) {
          const signatureDataUrl = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png');
          // Do something with the signature data URL, e.g. save it to state
          const trimmedBlob = this.dataURLtoBlob(signatureDataUrl);
          const trimmedFile = new File([trimmedBlob], `signature_${shortid.generate()}.png`, { type: 'image/png' });

        //   console.log('Signature name :', trimmedFile.name);
          this.setState({ loading:true, imageUrl: signatureDataUrl });
          //let dataURL = signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png');
          //let file = dataURLtoFile(dataURL, 'signature.png');

          S3bucket.uploadFile(trimmedFile, trimmedFile.name)
           .then((res) => {
            //    console.log(res.location);
               this.setState({ loading: false, sitter_signature: res.location });
            })
           .catch((error) => {
               console.log(error);
           });
        }
        this.handleCloseSignatureModal();
      }

    handleParentname = (e) => {
        const parentNamePattern = /^[a-zA-Z0-9\s]+$/;

        if(!parentNamePattern.test(e.target.value) || e.target.value.trim().length === 0 || e.target.value.trim().length<2 || e.target.value.trim().length>40){
            if(e.target.value.length === 0){
                this.setState({ parentname: e.target.value, error: { parentname: "Parent/Guardian name is required." } });
            } else if(!parentNamePattern.test(e.target.value)){
                this.setState({parentname: e.target.value, error: { parentname: "Parent/Guardian name should consist of alphabets only." } });
            } else if(e.target.value.length<2){
                this.setState({parentname: e.target.value, error: { parentname: "Parent/Guardian name should be 2 characters or more." } });
            } else if(e.target.value.length>40){
                this.setState({parentname: e.target.value, error: { parentname: "Parent/Guardian name should be 15 characters or less than 15 characters." } });
            }
        } else if(e.target.value.length>1 && e.target.value.length<41){
            this.setState({ parentname: e.target.value.trim(), form2Enable:true, error: { parentname: "" } });
        }
    }

    handlePlus = (e,index) => {
        if(this.state.school_name.length < 6){
            const School = this.state.school_name;
            this.setState({ school_name: [...School,{school_name:'',school_id:''}]});
        }
    } 
    handleMinus = (e,index) => {
        if(this.state.school_name.length > 1){
            const School = this.state.school_name;
            const Remove = index;
            this.setState({ school_name: School.filter((item, index) => index !== Remove) });
           
        } 
    } 
    handleSchoolname = (e,index) => {
        const Schools = [...this.state.school_name];
        Schools[index].school_name = e.target.value
        this.setState({school_name:Schools,form2Enable:true});
    }
    handleAbout = (e) => {
        this.setState({about:e.target.value,form2Enable:true})
    }
    handledob = (e) => {

        let age = moment().diff(e.target.value,'years');

        if (e.target.validity.valueMissing) {
            e.target.setCustomValidity('Please provide your birthdate.');
        } else if(e.target.validity.rangeUnderflow) {
            e.target.setCustomValidity('You are too old to be a sitter.');
        } else if(e.target.validity.rangeOverflow){
            e.target.setCustomValidity('You are too young to be a sitter.');
        } else {
            e.target.setCustomValidity('');
        }
        this.setState({dob:e.target.value,age:age,form2Enable:true})
    
    }

    // handleSitterSignature = (e) => {
    //     var File = e.target.files[0];
    //     if(File !== undefined){
    //         if(File.type.match('image/jpeg')||File.type.match('image/jpg')||File.type.match('image/png')){
    //             var reader = new FileReader();
    //             var url = reader.readAsDataURL(File);
    //             this.setState({
    //                 uploading: true,
    //                 url: URL.createObjectURL(File),
    //                 form2Enable:true,
    //             });
    //             S3bucket.uploadFile(File, File.name)
    //             .then((res) => {
    //                 this.setState({ uploading: false, sitter_signature: res.location });
    //                 console.log(res.location);
    //             })
    //             .catch((error) => {
    //                 //console.log(error);
    //             });
    //         }else{
    //             e.target.value = null;
    //             File = e.target.files[0];
    //             toast("The selected file is not an image or allowed image file type. Please select an image file.", { type: "error" });
    //         }
    //     }

    // }

    handleHourrate = (e) => {
        const pattern = /^[1-9][0-9]*$/;
        
        if(!pattern.test(e.target.value) || e.target.value.length === 0 || e.target.value.length<1 || e.target.value.length>3){
            if(e.target.value.length === 0){
                this.setState({ hourrate: e.target.value, error: { hourrate: "Hourly rate is required." } });
            } else if(!pattern.test(e.target.value)){
                this.setState({hourrate: e.target.value, error: { hourrate: "Hourly rate should be numeric only." } });
            } else if(e.target.value.length<1){
                this.setState({hourrate: e.target.value, error: { hourrate: "Hourly rate should be 1 digit or more." } });
            } else if(e.target.value.length>3){
                this.setState({hourrate: e.target.value, error: { hourrate: "Hourly rate should be 3 digits or less than 3 digits." } });
            }
        } else if(e.target.value.length>0 && e.target.value.length<4 && pattern.test(e.target.value)){
            this.setState({hourrate:e.target.value,form2Enable:true, error: { hourrate: "" } })
        }

            // if(!pattern.test(e.target.value) || e.target.value.length === 0 || e.target.value.length<1 || e.target.value.length>3){
            //     if(e.target.value.length === 0){
            //         this.setState({ hourrate: e.target.value, error: { hourrate: "Hourly rate is required." } });
            //     } else if(!pattern.test(e.target.value)){
            //         this.setState({hourrate: e.target.value, error: { hourrate: "Hourly rate should be numeric only." } });
            //     } else if(e.target.value.length<1){
            //         this.setState({hourrate: e.target.value, error: { hourrate: "Hourly rate should be 1 digit or more." } });
            //     } else if(e.target.value.length>3){
            //         this.setState({hourrate: e.target.value, error: { hourrate: "Hourly rate should be 3 digits or less than 3 digits." } });
            //     }
            // } else if(e.target.value.length>0 && e.target.value.length<4 && pattern.test(e.target.value)){
            //     this.setState({hourrate:e.target.value,form2Enable:true})
            // }
    }

      handleProfileImage = (e) => {
        var File = e.target.files[0];
        if(File !== undefined){
            if(File.type.match('image/jpeg')||File.type.match('image/jpg')||File.type.match('image/png')||File.type.match('image/gif')||File.type.match('image/webp')){
                var reader = new FileReader();
                var url = reader.readAsDataURL(File);
                this.setState({
                    uploading: true,
                    url: URL.createObjectURL(File),
                    form1Enable:true,
                });
                S3bucket.uploadFile(File, File.name)
                .then((res) => {
                    this.setState({ uploading: false, profile_pic: res.location });
                    //console.log(res.location);
                })
                .catch((error) => {
                    //console.log(error);
                });
            }else{
                e.target.value = null;
                File = e.target.files[0];
                toast("The selected file is not an image or allowed image file type. Please select an image file.", { type: "error" });
            }
        }
      };

      handleReferences = (e, index) => {
        // console.log(index,e.target.value);
        const References = [...this.state.references];
        if (e.target.name == 'f_name') {
            References[index]['f_name'] = e.target.value;
            this.setState({ references: References, form3Enable:true });
            //console.log(this.state.child_age);
        } else if (e.target.name == 'l_name') {
            References[index]['l_name'] = e.target.value;
            this.setState({ references: References, form3Enable:true });
        } else if(e.target.name == 'phone'){
            References[index]['phone'] = e.target.value;
            this.setState({ references: References, form3Enable:true });
        }
      };

      handleInputText = (e) => {
        this.setState({ [e.target.name]: e.target.value, form1Enable:true });
    };
    
    handleTransportation = (e) => {
        this.setState({transportation: e.target.value,form2Enable:true});
      }

      handleSubmitPersonal = (e) => {
            e.preventDefault();
            this.setState({ loading: true});

            let data  = {
                first_name:this.state.firstname.trim(),
                last_name:this.state.lastname.trim(),
                phone_number:this.state.phone_number.trim(),
                address:this.state.address.trim(),
                religion:this.state.religion,
                city:this.state.city.trim(),
                state:this.state.state.trim(),
                zip_code:this.state.zip_code,
                image:this.state.profile_pic
            }
            //console.info(data)

            instance.post('/update-personal-details',data).then((res) => {
                this.setState({
                image:res.data.data.image,
                url: res.data.data.image,
                profile_pic: res.data.data.image,
                firstname: res.data.data.first_name.trim(),
                lastname: res.data.data.last_name.trim(),
                email:res.data.data.email,
                phone_number:res.data.data.phone_number.trim(),
                address:res.data.data.address.trim(),
                city:res.data.data.city.trim(),
                state:res.data.data.state.trim(),
                religion:res.data.data.religion,
                zip_code:res.data.data.zip_code.trim(),
                loading:false
                })
                toast('Your profile details Updated Successfully', { type: "success" });
                
            }).catch((error) => {
            this.setState({ loading: false});
                // console.error(error)
                toast(error.response.data.message, { type: "error" });
                
            })
            this.setState({form1Enable : false});
      };

      
      handleSubmitExtra = (e) => {
        e.preventDefault();

        let data  = {
            hourly_rate:this.state.hourrate,
            parent_name: this.state.parentname,
            dob:this.state.dob,
            details:this.state.about.trim(),
            transportation:this.state.transportation.trim(),
            signature:(this.state.sitter_signature == AvatarPlaceholder) ? '' : this.state.sitter_signature, 
            schools:this.state.school_name.map((value) => {return value.school_name})
        }
        if(this.state.age > 17){
            data.signature = '';
            data.parent_name = '';
            this.setState({sitter_signature:"", parentname:""});
        }

        this.setState({ loading: true});

        //console.info(data)
        instance.post('/update-sitter-profile',data).then((res) => {
            //console.info(res)
            this.setState({ loading: false});

            toast('Your profile details Updated Successfully', { type: "success" });
        }).catch((error) => {
            this.setState({ loading: false});
            // console.error(error)
            toast(error.response.data.message, { type: "error" });
        })
        this.setState({form2Enable : false});
    };
      handleSubmitReferences = (e) => {
        this.setState({ loading: true});

          e.preventDefault();
         let data  = {references:this.state.references}
            // console.info(data)
            instance.post('/update-references',data).then((res) => {
            this.setState({ loading: false});

                toast('Your References Updated Successfully', { type: "success" });
            }).catch((error) => {
                // console.error(error)
            this.setState({ loading: false});

            toast(error.response.data.message, { type: "error" });

        })
        this.setState({form3Enable : false});
    };

        handleSubmitPassword = (e) => {
          e.preventDefault();
        //   this.setState({ loading: false});

          if(this.state.password == this.state.new_password){
            let data  = {password:this.state.current_password,new_password:this.state.new_password}
            // console.info(data)
            this.setState({ loading: true});

            instance.post('/change-password',data).then((res) => {
                this.setState({
                    password:'',new_password:'',current_password:'',loading:false
                })
                toast('Your Password Updated Successfully', { type: "success" });
            }).catch((error) => {
                //console.log(error.response)
                this.setState({ loading: false});
                toast(error.response.data.message, { type: "error" });
                // this.setState({current_password:''
                // })
        })
          }else{
            toast('Confirm password is not same | Please enter same password ', { type: "error" });
            this.setState({
                password:'',new_password:'',current_password:''
            })
          }
           
        };
        
handleTabs = (e,key) => { this.setState({activetab:key})}
    render() {
        return (<>
        <div className={this.state.loading ? 'loader' : ''}>
            <header>
            <div className="container">
                <div className="row settings-page-title">
                    <div className="col-12">
                        <Link to="/sitter/dashboard" className="text-grey back-button"><i className="fa fa-arrow-left"></i>Back to dashboard
                        </Link>
                    </div>
                </div>
            </div>
        </header>
        <section className="">
            <div className="container settings-pills">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="col-12 blue-grad sidebar">
                            <div className="basic-details">
                                <div className="col-12 heading">
                                    <i className="fa fa-user"></i><span className="h5">My profile</span>
                                </div>
                                <div className="row">
                                    <div className="col-5 profile-pic">
                                    <img src={(this.state.profile_pic == '') ? ImgAvatar : this.state.profile_pic  } alt=""/>
                                    </div>
                                    <div className="col-7 logout">
                                        {/* <small className="verified"><i className="fa fa-check"></i>Background verified</small> */}
                                        {/* <small className="not-verified"><i className="fa fa-exclamation"></i>Background not verified</small> */}
                                        <h4> {TokenService.getUserName()} </h4>
                                    </div>
                                </div>
                                <div className="row pills-navbar">
                                    <div className="col-12 pt-4">
                                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {/* <!-- personal detail nav pill --> */}

                                            <button onClick={ e => this.handleTabs(e,1) } className={`nav-link ${this.state.activetab == 1 ? 'active' : ''}`} id="pill-personal-details-tab" data-bs-toggle="pill" data-bs-target="#pill-personal-details" type="button" role="tab" aria-controls="pill-personal-details" aria-selected="true">Personal details <small className="sub-title">Name, Email, Address
                                            {/* , Religion */}
                                            </small></button>
    
                                            {/* <!-- About me nav pill --> */}
                                            <button onClick={ e => this.handleTabs(e,2) } className={`nav-link ${this.state.activetab == 2 ? 'active' : ''}`} id="pill-about-tab" data-bs-toggle="pill" data-bs-target="#pill-about" type="button" role="tab" aria-controls="pill-about" aria-selected="false">
                                                Education and rate<small className="sub-title">Birthday, your discription,School attended, Hourly Rate</small></button>
    
                                            {/* <!-- References nav pill --> */}
                                            <button onClick={ e => this.handleTabs(e,3) } className={`nav-link ${this.state.activetab == 3 ? 'active' : ''}`} id="pill-references-tab" data-bs-toggle="pill" data-bs-target="#pill-references" type="button" role="tab" aria-controls="pill-references" aria-selected="false">References (2 required)</button>
    

                                            {/* <!-- Password nav pill --> */}
                                            {/* <button onClick={ e => this.handleTabs(e,5) } className={`nav-link ${this.state.activetab == 5 ? 'active' : ''}`} id="pill-password-tab" data-bs-toggle="pill" data-bs-target="#pill-password" type="button" role="tab" aria-controls="pill-password" aria-selected="false">Change password</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 pills-content">
                        <div className="tab-content" id="v-pills-tabContent">
    
                            {/* <!-- personal detail content pane--> */}
                            <div className={`tab-pane fade  pill-personal-details ${this.state.activetab == 1 ? 'show active' : ''}`}   id="pill-personal-details" role="tabpanel" aria-labelledby="pill-personal-details-tab">
                              
                                <form onSubmit={this.handleSubmitPersonal}  className="edit-personal-details bordered">
                                    <div className="row px-2">
                                        <div className="col-12 col-md-7 upload-profile-picture">
                                            <input onChange={this.handleProfileImage} type="file" accept="image/jpg, image/png, image/gif, image/jpeg, image/webp" name="profile_pic" id="profile_pic"/>
                                            <img src={(this.state.url === '') ? ImgAvatar : this.state.url} alt="Profile avatar"/>
                                            <span className="h4 text-blue">Upload photo <span className="text-grey">(optional)</span></span>
                                            <div><small className="text-pink" >Only jpg, jpeg, gif, webp and png image file formats are allowed.</small></div>
                                        </div>
                                        <div className="col-6 col-md-6 ">
                                            <label htmlFor="f_name">First name</label>
                                                <input type="text" title="First name should be 2 characters or more." minLength={2} maxLength={40}
                                                // onKeyDown={(e) => {
                                                //     var x = e.key;
                                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                //         e.preventDefault();
                                                //     }
                                                // }} 
                                                pattern="^[a-zA-Z0-9\s]+$" onChange={this.handleInputText} value={this.state.firstname} className="form-control" name="firstname" id="f_name" placeholder="First name" required />
                                            
                                        </div>
                                        <div className="col-6 col-md-6 ">
                                            <label htmlFor="l_name">Last name</label>
                                                <input type="text" title="Last name should be 2 characters or more." minLength={2} maxLength={40}
                                                // onKeyDown={(e) => {
                                                //     var x = e.key;
                                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                //         e.preventDefault();
                                                //     }
                                                // }}     
                                                pattern="^[a-zA-Z0-9\s]+$" onChange={this.handleInputText} value={this.state.lastname} className="form-control" name="lastname" id="l_name" placeholder="lastname" required />
                                        </div>
                                        <div className="col-col-12 col-md-7 ">
                                            <label htmlFor="email">Email</label>
                                            <input type="email"  disabled   defaultValue={this.state.userdetails.email} className="form-control" name="email" id="email" placeholder="Email" required/>
                                        </div>
                                        <div className='col-12 col-md-9'>
                                                <div className='row'>
                                                    <div className="col-4 col-sm-4 col-md-4">
                                                        <label htmlFor="country_code">Country Code</label>
                                                        <select
                                                            title='Country Code'
                                                            name="country_code"
                                                            className="c_code"
                                                        >
                                                            {/* <option defaultValue={(value.country_code == '+91') } value="+91">+91 </option> */}
                                                            <option defaultValue={'+1'} value="+1">+1</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-8 col-md-8 col-lg-6 col-xl-6">
                                                        <label htmlFor="phone_number">Your Phone Number (10 digits)</label>
                                                        <input
                                                            title="Please enter exactly 10 digits with correct area code."
                                                            inputMode="numeric"
                                                            pattern="^[2-9][0-9]*$"
                                                            maxLength={10}
                                                            minLength={10}
                                                            onChange={(e) =>
                                                                this.handleInputText(e)
                                                            }
                                                            type="text"
                                                            value={this.state.phone_number}
                                                            className="form-control"
                                                            name="phone_number"
                                                            id="phone_number"
                                                            placeholder="Phone number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="address">Address</label>
                                            <input type="text" minLength={5} maxLength={250} onChange={this.handleInputText} value={this.state.address} className="form-control" name="address" id="address" placeholder="Address" required/>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="address">City</label>
                                            <input type="text" title="Please enter a city name without digits and not more than one space between two words."
                                            // onKeyDown={(e) => {
                                            //         var x = e.key;
                                            //         if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                            //             e.preventDefault();
                                            //         }
                                            //     }} 
                                            minLength={2} maxLength={40} pattern="^[a-zA-Z0-9\s]+$" onChange={this.handleInputText} value={this.state.city} className="form-control" name="city" id="city" placeholder="City Name" required />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="address">State</label>
                                                <input type="text" title="Please enter a state name without digits and not more than one space between two words."
                                                // onKeyDown={(e) => {
                                                //     var x = e.key;
                                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                //         e.preventDefault();
                                                //     }
                                                // }}     
                                                pattern="^[a-zA-Z0-9\s]+$" minLength={2} maxLength={40} onChange={this.handleInputText} value={this.state.state} className="form-control" name="state" id="state" placeholder="State Name" required />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="address">ZipCode</label>
                                            <input type="text" title="Please enter 5 digits only." inputMode="numeric" minLength={5} maxLength={5} pattern="^[0-9][0-9]*$" onChange={this.handleInputText} value={this.state.zip_code} className="form-control" name="zip_code" id="zip_code" placeholder="Zip Code" required/>
                                        </div>
                                        {/* <div className="col-12 col-md-6 ">
                                            <label htmlFor="religion">Religion</label>
                                                <input type="text" title="Please enter a religion without digits."
                                                onKeyDown={(e) => {
                                                    var x = e.key;
                                                    if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                        e.preventDefault();
                                                    }
                                                }}     
                                                minLength={2} maxLength={15} pattern="^[A-Za-z]+$" onChange={this.handleInputText} value={this.state.religion} className="form-control" name="religion" id="religion" placeholder="Religion" required />
                                        </div> */}
                                        {/* <div className="col-12">
                                            <div className="col-12 col-md-6 pt-3">
                                                <h5 className="text-grey">Enter password to save changes</h5>
                                                <label htmlFor="password">Password</label>
                                                <input type="password" className="form-control" name="password" id="password" placeholder="password"/>
                                            </div>
                                        </div> */}
                                    <div className="col-12 action-buttons">
                                        <button disabled={!this.state.form1Enable || this.state.loading || this.state.uploading} className="btn-blue float-end blue-grad" type="submit">
                                            {(this.state.loading == true) ? 
                                            'Loading . . . .' :
                                            (this.state.uploading == true) ? 'Uploading file  . . . .'  : 'Save changes'  }</button>
                                        {/* <a href="#" className="text-blue float-end">Don't save</a> */}
                                    </div>
                                    </div>
                                </form>
                           
                            </div>
    
                            {/* <!-- About me content pane --> */}
                            <div className={`tab-pane fade  pill-about ${this.state.activetab == 2 ? 'show active' : ''}`} id="pill-about" role="tabpanel" aria-labelledby="pill-about-tab">

                                <form onSubmit={this.handleSubmitExtra} className="edit-about-me bordered">
                                    <div className="row px-2">
                                        <div className="col-7 pt-2">
                                        <label htmlFor="h_rate">Hourly rate ($)</label>
                                        <input inputMode="numeric" onChange={this.handleHourrate} title="Please enter digits only and first digit should not be 0." minLength={1} maxLength={3} min={1} max={999}  value={this.state.hourrate} pattern="^[1-9][0-9]*$" type="number" className="form-control" name="h_rate" id="h_rate" placeholder="Hourly rate" required />
                                        <small className="text-pink">(You will receive 93% of it)</small>
                                        <div></div>
                                        <small className="text-pink">Payment will be sent every Friday for the jobs done each week</small>
                                        </div>
                                        <div className="col-12 col-md-7 pt-3">
                                <label className="text-blue">
                                    Birthday
                                </label>
                                <input value={this.state.dob} title="Please enter appropriate birthdate." autoComplete="off" max={moment().subtract(13,'years').format('YYYY-MM-DD')} 
                                type="date" ref={elem => this.dob = elem} name="dob" onChange={this.handledob}  min={moment().subtract(60,'years').format('YYYY-MM-DD')} id="dob"/>
                            </div>
                            <div className="col-12 pt-2">
                                <label className="text-blue m-0">
                                    School Attended (attends)
                                </label>
                            </div>
                            <div className="col-12 my-2">
                                { this.state.school_name.map((value,index) => {
                                    return (
                                    <div key={index} className='row'>
                                    <div className="col-10" >
                                                <input title="Please a school name." minLength={2} maxLength={40}
                                                // onKeyDown={(e) => {
                                                //     var x = e.key;
                                                //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                //         e.preventDefault();
                                                //     }
                                                // }} 
                                                onChange={event => this.handleSchoolname(event, index)} type="text" className="form-control" name="schools_attended" value={value.school_name} id="schools_attended" placeholder=" School name " required />
                                    </div>
                                    <div className="col-2" >
                                        <button onClick={event => this.handleMinus(event,index)} className="number-control" id="count-minus" type="button"><i className="fa fa-minus-circle"></i></button> 
                                        <button onClick={event => this.handlePlus(event,index)} className="number-control" id="count-plus" type="button"><i className="fa fa-plus-circle"></i></button>    
                                    </div> 
                                </div>);

                                }) }
                                
                            </div>
                                
                            <div className="col-12 pt-2 ">
                                <h4 className="text-blue">Transportation</h4>
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                    <ul className="list-item spacing">
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I drive my own car."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-car"
                                            name="transportation"
                                            value="I drive my own car."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-car">
                                            I drive my own car.
                                        </label>
                                    </li>
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I can take care of my own transportation."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-own"
                                            name="transportation"
                                            value="I can take care of my own transportation."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-own">
                                            I can take care of my own transportation.
                                        </label>
                                    </li>
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I need one-way transportation."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-one"
                                            name="transportation"
                                            value="I need one-way transportation."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-one">
                                            I need one-way transportation.
                                        </label>
                                    </li>
                                    <li className="list-inline">
                                        <input
                                            checked={this.state.transportation == "I need two-way transportation."}
                                            onChange={this.handleTransportation}
                                            type="radio"
                                            className="form-check-input"
                                            id="t_mode-two"
                                            name="transportation"
                                            value="I need two-way transportation."
                                            required
                                        />
                                        <label className="form-check-label" htmlFor="t_mode-two">
                                            I need two-way transportation.
                                        </label>
                                    </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-12 py-2">
                                <label className="text-blue">
                                    Tell parents a little bit about yourself
                                </label>
                                <textarea value={this.state.about} minLength={5} maxLength={600} onChange={this.handleAbout}  className="form-control" name="about_sitter" id="about_sitter" rows="5" placeholder="(Include details about previous babysitting experience, your occupation, your personality, about your passion for children etc.)">
                                    {this.state.about}
                                </textarea>
                            </div>
                            {/* {console.log('age: ',this.state.age==null)} */}
                            {(this.state.age >= 13 && this.state.age <= 17) ?  <><div className="col-12 pt-2">
                                <h4 className="text-blue">
                                    Parent’s signature
                                </h4>
                                <h6 className="text-pink">(if you are between the ages of 13-17)</h6>
                            </div>
                            <div className="col-12 py-2"onClick={this.handleShowSignatureModal}>
                                <div className="upload_sign text-blue text-center" style={{cursor:"pointer"}}>
                                    <i className="fa fa-signature"></i>
                                    <h6>Parent's Signature</h6>
                                    {/* <small>max file size 500 kb</small> */}
                                    {/* <input onChange={this.handleSitterSignature} type="file" name="parent_sign"  accept="image/jpg, image/png, image/jpeg" id="parent_sign" required/> */}
                                    {/* <button >Create Signature</button> */}
                                </div>
                                <div><small className="text-pink" >Only png image file format is allowed.</small></div>
                            </div>
                               {/* { this.state.imageUrl ? <img src={this.state.imageUrl || this.state.sitter_signature} alt="signature" className="signature" />
                                     : null} */}
                            <small className="text-danger">
                                {" "}
                                {this.state.error.signature ?? ""}{" "}
                            </small>
                            <div className="col-12 py-2">
                                <h4 className="text-blue">
                                    Parent/Guardian printed name
                                </h4>
                            </div>
                            <div className="col-6 py-2">
                                <input title="Parent/Guardian's name should be 2 characters or more." minLength={2} maxLength={40}
                                //     onKeyDown={(e) => {
                                // var x = e.key;
                                // if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                //         e.preventDefault();
                                //     }
                                // }}
                                onChange={this.handleParentname} value={this.state.parentname} type="text" className="form-control" name="parent_name" id="parent_name" placeholder="Parent/Guardian name" required />
                                {/* <label htmlFor="parent_name">Parent/Guardian</label> */}
                                <small className="text-danger">
                                    {" "}
                                    {this.state.error.parentname ?? ""}{" "}
                                </small>
                            </div>
                            </> : null }
                            <div>{ this.state.parentname ? <div className="col-6 py-2">
                                <h6 className="text-blue">
                                    Parent/Guardian's printed name and signature
                                </h6>
                                <input disabled type="text" class="form-control" name="parent_name_label" id="parent_name_label" placeholder="Parent Name" value={this.state.parentname} />
                            </div>: null}
                            </div>
                            <div>{ this.state.imageUrl ? <img src={this.state.imageUrl || this.state.sitter_signature} alt="signature" className="signature" />
                                     : null}
                            </div>
                            <div className="col-12 col-md-7 py-3 action-buttons">
                                <button disabled={!this.state.form2Enable || this.state.loading || this.state.uploading} className="btn-blue float-end blue-grad" type="submit">
                                    {(this.state.loading == true) ? 
                                    'Loading . . . .' :
                                     (this.state.uploading == true) ? 'Uploading file  . . . .'  : 'Save changes'  }</button>
                                {/* <a href="#" className="text-blue float-end">Don't save</a> */}
                            </div>
                                    </div>
                                </form>
                                
                            </div>
    
                            {/* <!-- References content pane --> */}
                            <div className={`tab-pane fade  pill-references ${this.state.activetab == 3 ? 'show active' : ''}`} id="pill-references" role="tabpanel" aria-labelledby="pill-references-tab">

                                <form onSubmit={this.handleSubmitReferences} className="references bordered">
                                    <div className="row px-2">
                                        <div className="col-12">
                                            <span className="h4 text-blue ">References</span>
                                            <div className="row references py-2">
                                            {this.state.references.map((value, index) => {
                                                    return (
                                                        <div key={index}>
                                                        <div className="col-12">
                                                            <h5 className="text-grey mb-0">Reference {index + 1}</h5>
                                                        </div>
                                                        <div className="col-12 col-md-6 ">
                                                            <label htmlFor={"ref"+(index+1)+"f_name"}>First name</label>
                                                            <input
                                                            title="Please enter a proper reference first name and do not use digits." 
                                                            pattern="^[a-zA-Z0-9\s]+$" 
                                                            minLength={2}
                                                            maxLength={40}
                                                            // onKeyDown={(e) => {
                                                            //     var x = e.key;
                                                            //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                            //         e.preventDefault();
                                                            //     }
                                                            // }} 
                                                            onChange={(event) =>
                                                                this.handleReferences(event, index)
                                                            }
                                                            value={value.f_name}
                                                            type="text"
                                                            className="form-control"
                                                            name="f_name"
                                                            id={"ref"+(index+1)+"f_name"}
                                                            placeholder="First name"
                                                            required
                                                            />
                                                            
                                                        </div>
                                                        <div className="col-12 col-md-6 ">
                                                            <label htmlFor={"ref"+(index+1)+"l_name"}>Last name</label>
                                                            <input
                                                            title="Please enter a proper reference last name and do not use digits." 
                                                            pattern="^[a-zA-Z0-9\s]+$" 
                                                            minLength={2}
                                                            maxLength={40}
                                                            // onKeyDown={(e) => {
                                                            //     var x = e.key;
                                                            //     if (x == "0" || x == "1" || x == "2" || x == "3" || x == "4" || x == "5" || x == "6" || x == "7" || x == "8" || x == "9") {
                                                            //         e.preventDefault();
                                                            //     }
                                                            // }} 
                                                            onChange={(event) =>
                                                                this.handleReferences(event, index)
                                                            }
                                                            value={value.l_name}
                                                            type="text"
                                                            className="form-control"
                                                            name="l_name"
                                                            id={"ref"+(index+1)+"l_name"}
                                                            placeholder="Last name"
                                                            required
                                                            />
                                                            
                                                        </div>
                                                            <div className='row'>
                                                                <div className="col-3 col-md-2 ref_ccode">
                                                                    <label htmlFor="country_code">Code</label>
                                                                    <select
                                                                        onChange={(event) =>
                                                                            this.handleReferences(event, index)
                                                                        }
                                                                        name="country_code"
                                                                        className="c_code"
                                                                    >
                                                                        <option defaultValue={(value.country_code == '+1')} value="+1">+1</option>
                                                                        {/* <option defaultValue={(value.country_code == '+91') } value="+1">+91 </option> */}
                                                                    </select>
                                                                </div>
                                                                <div className="col-8 col-md-8 ">
                                                                    <label htmlFor={"ref" + (index + 1) + "_phone"}>Number (10 digits)</label>
                                                                    <input
                                                                        title="Please enter exactly 10 digits with correct area code."
                                                                        pattern="^[2-9][0-9]*$"
                                                                        inputMode="numeric"
                                                                        maxLength={10}
                                                                        minLength={10}
                                                                        onChange={(event) =>
                                                                            this.handleReferences(event, index)
                                                                        }
                                                                        type="text"
                                                                        value={value.phone}
                                                                        className="form-control"
                                                                        name="phone"
                                                                        id={"ref" + (index + 1) + "_phone"}
                                                                        placeholder="Reference phone number"
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                    })}
                                            </div>
                                        </div>
    
                                        <div className="col-12 py-3 action-buttons">
                                            <button disabled={!this.state.form3Enable} className="btn-blue float-end blue-grad" type="submit">Save changes</button>
                                            {/* <a href="#" className="text-blue float-end">Don't save</a> */}
                                        </div>
                                    </div>
                                </form>

                            </div>
    
                            {/* <!-- Payments content pane --> */}
                            <div className={`tab-pane fade  pill-payments ${this.state.activetab == 4 ? 'show active' : ''}`} id="pill-payments" role="tabpanel" aria-labelledby="pill-payments-tab">

                   
                            {/* <lines className="shine"></lines><br></br>
                            <box className="shine"></box><br></br>
                            <lines className="shine"></lines><br></br>
                            <lines className="shine"></lines><br></br>
                             */}
                            </div>
                            {/* <!-- Password content pane --> */}
                            {/* <div className={`tab-pane fade  pill-payments ${this.state.activetab == 5 ? 'show active' : ''}`} id="pill-password" role="tabpanel" aria-labelledby="pill-payments-tab">

                                <form onSubmit={this.handleSubmitPassword} className="edit-personal-details bordered">
                                    <div className="row px-2">
                                        <div className="col-12 col-md-7 ">
                                            <label htmlFor="current_password">Current password</label>
                                            <input type="password" title="Please include at least 1 uppercase character, 1 lowercase character, 1 special character and 1 number." minLength={7} maxLength={15}  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[@#$%^&+=]).*$" value={this.state.current_password} onChange={this.handleInputText} className="form-control" name="current_password" id="current_password" placeholder="Password" required />
                                            <h6 className="text-pink" >
                                                If you don’t remember your current password, logout of your profile, and use the forgot password option to reset your password
                                            </h6>
                                        </div>
                                        <div className="col-12 col-md-7 ">
                                            <label htmlFor="new_password">New password</label>
                                            <input type="password" title="Please include at least 1 uppercase character, 1 lowercase character, 1 special character and 1 number." minLength={7} maxLength={15}  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[@#$%^&+=]).*$" value={this.state.password} className="form-control" onChange={this.handleInputText} name="password" id="new_password" placeholder="Password" required />
                                        </div>
                                        <div className="col-12 col-md-7 ">
                                            <label htmlFor="confirm_password">Confirm new password</label>
                                            <input type="password" title="Please include at least 1 uppercase character, 1 lowercase character, 1 special character and 1 number." minLength={7} maxLength={15}  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[@#$%^&+=]).*$" value={this.state.new_password} className="form-control" name="new_password" onChange={this.handleInputText} id="confirm_password" placeholder="Password" required />
                                        </div>
                                        <div className="col-12 col-md-7 py-3 action-buttons">
                                            <button className="btn-blue float-end blue-grad" type="submit">Save changes</button>
                                        </div>
                                    </div>
                                </form>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
    
        </section>
        </div>
        
        <Modal show={this.state.showSignatureModal} onHide={this.handleCloseSignatureModal}>
            <Modal.Header closeButton>
                <Modal.Title>Parent's Signature</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <SignatureCanvas
                ref={(ref) => { this.sigCanvas = ref; }}
                penColor='black'
                canvasProps={{width: 400, height: 300, pixelRatio:2, className: 'sigCanvas'}}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-blue float-end blue-grad" variant="secondary" onClick={this.handleClearSignature}>
                Clear
                </button>
                <button className="btn-blue float-end blue-grad" variant="primary" onClick={this.handleCreateSignature}>
                Create Signature
                </button>
            </Modal.Footer>
            </Modal></>
        );
    }
}

export default SitterProfile;