import S3 from "react-aws-s3";

const config = {
    bucketName: process.env.REACT_APP_BACKET_NAME,
    dirName: process.env.REACT_APP_BACKET_DIRNAME,
    region: process.env.REACT_APP_BACKET_region,
    accessKeyId: process.env.REACT_APP_BACKET_access_key,
    secretAccessKey: process.env.REACT_APP_BACKET_secret_key
  };

  const ReactS3Client = new S3(config);

export default ReactS3Client;