
import React from 'react';

import { Route ,Redirect} from 'react-router-dom';
const PrivateRoute = ({ component: Component,role, ...rest }) => {
  
  
  
  return <Route
    {...rest}
    render={props =>
        (localStorage.getItem("isloggedin") == 'true' && localStorage.getItem("role") == role)  ? 
        (<Component {...props} />) : (
        <Redirect to={{pathname: "/"}}
/>
      )
    }
  />
};

const PublicRoute = ({ component: Component,role, ...rest }) => {
  
  //console.log('islogged',localStorage.getItem("isloggedin"))
  //console.log('role',localStorage.getItem("role"))
  
  return <Route
  {...rest}
  render={props => (<Component {...props} />)
      // (localStorage.getItem("isloggedin") == 'true' && localStorage.getItem("role"))  ? 
      // (<Redirect to={{pathname: (role == 1) ? '/parent/dashboard' : '/sitter/profile'}}/>) :
      // (<Component {...props} />)  
    
  }
/>
};


export  {PrivateRoute,PublicRoute};