class ProfileService {
   
    getUserName() {
      const user = JSON.parse(localStorage.getItem("UserProfile"));
      return user?.first_name;
    }

    getUserImage() {
      const user = JSON.parse(localStorage.getItem("UserProfile"));
      return user?.image;
    }

    getUserFullName() {
      const user = JSON.parse(localStorage.getItem("UserProfile"));
      return user?.first_name + ' ' + user?.last_name;
    }
    getReviewsAvg() {
      const user = JSON.parse(localStorage.getItem("UserProfile"));
      return user?.review_avg;
    }

    getReviewsCount() {
      const user = JSON.parse(localStorage.getItem("UserProfile"));
      return user?.review_count;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem("UserProfile"));
      user.api_token = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  
    getUserProfile() {
      return JSON.parse(localStorage.getItem("UserProfile"));
    }

    getUseronboarding(){
      const user = JSON.parse(localStorage.getItem("UserProfile"));
      return user?.onboarding;
    }
  
    setUserProfile(UserProfile) {
      // console.log(JSON.stringify(UserProfile));
      localStorage.setItem("UserProfile", JSON.stringify(UserProfile));
    }
  
    removeUserProfile() {
      localStorage.removeItem("UserProfile");
    }
  }
  
  export default new ProfileService();