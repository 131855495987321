import React, { Component } from "react";
import Layoutone from "../../layouts/Layoutone";
import { Link } from "react-router-dom";
import Input from "../../components/Forms/inputs";
import Api from "../../context/Api";
import {toast} from 'react-toastify'
import { localeData } from "moment";
import TokenService from "../../services/Token.Service";
import instance from "../../context/AuthApi";




class onboarding extends Component {


  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      token: "",
      error: "",
      success: false,
      loading: false
    }


   
    
  }

  handleEmail = (e) => {this.setState({ email: e.target.value })};
  handlePassword = (e) => {this.setState({ password: e.target.value })};


  handleSubmit = (e) => {
    e.preventDefault();
    const  data = {
      email:this.state.email,
      password:this.state.password,
      device_id:'demo--1',
      device_type:'demo--1',
      device_token:'q2w3e4er5t6y7u8i9o0',
    }
    this.setState({loading:true});


    Api.post('auth/login',data).then((res)=>{
      //console.log(res)
      this.setState({loading:false,success:true})
      localStorage.setItem("isloggedin",true);
      localStorage.setItem("api_token",res.data.data.api_token);
      localStorage.setItem("role",res.data.data.role);
      localStorage.setItem("refresh_token",res.data.data.refresh_token);
      TokenService.setUser(res.data.data);
      toast(res.data.message,{ type:'success' });
      switch (res.data.data.role) {
        case 1:
          this.props.history.push('/parent/dashboard');
        break;
      
        case 2:
          this.props.history.push('/sitter/dashboard');
          
          break;
        default:
          this.props.history.push('/');
          break;
      }





    }).catch((e) => {
      this.setState({loading:false,success:false})
      toast(e.response.data.message,{type:'error'});
    });
  }

  componentDidMount() {


    if(this.props.match.params.id){
    //console.log('User Id',this.props.match.params.id)
    instance.get(`success-onboarding-web/${this.props.match.params.id}`).then((res) => {

      toast('Payment added successfully', { type: "success" });
      this.props.history.push({pathname:'/sitter/dashboard'})

      }).catch((error) => {
          toast(error.response.data.message, { type: "error" });
      })  
    }else{
      this.props.history.push({pathname:'/'})
    }
  
  }

  render() {



    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h2>Please Wait</h2>
            <h3>We are checking</h3>
          </div>
          {/* <Link to="/"  >Go TO BACK</Link> */}
        </div>
      </div>
    );
  }
}

export default onboarding;

