import axios from "axios";
import TokenService from "../services/Token.Service";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage?.getItem('api_token');
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/refresh-token", {
            refresh_token: TokenService.getLocalRefreshToken(),
          });
          //console.log(TokenService.getLocalRefreshToken());

          const { token } = rs.data.data;
          TokenService.updateLocalAccessToken(token);
          //console.log(token);
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;