import React, { Component } from 'react';
import  ProfilePicturePlaceholder from "../../../assets/images/profile-picture (1).png";
import  DashIllustration  from  "../../../assets/images/dash-illustration (1).png";
import  ParentPoster  from  "../../../assets/images/poster-parent (1).png";
import {Link} from 'react-router-dom';
import TokenService from '../../../services/Token.Service';
import instance from '../../../context/AuthApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import ProfileService from '../../../services/Profile.Service';
import { Modal } from 'react-bootstrap'
import SidebarOne from '../../../components/SideBars/SidebarOne';
import TipsArray from '../../../Constants/TipsArray.';
import Chat from '../../../components/Chats/Chat';
import StarRatings from 'react-star-ratings';
import { fetchEventSource } from '@microsoft/fetch-event-source';
//import  io  from "socket.io-client";

// setup AbortController
let controller;
let ongoingSSEConnections = [];
// signal to pass to fetch
// let signal;
let eventSource=null; 
let isConnect = false;
let isProcessingBid = false;
let shouldUpdate = false;

class ParentDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        profile: ProfilePicturePlaceholder,
        loading:true,
        FirstTabs:(this.props.location?.state?.firsttab) ? this.props.location.state.firsttab : 1,
        SecondTabs:(this.props.location?.state?.secondtab) ? this.props.location.state.secondtab : 1,
        PostsList:[],
        RunningJobs:[],
        UpcomingJobs:[],
        CompletedJobs:[],
        bidQueue:[],
        CancelModal:false,
        TipModal:false,
        EndJobModal:false,
        TipAmount:0,
        CancelId:'',
        rating:5,
        ReviewText:'',
        Oursocket:"",
        ReviewModal:false,
        startTime:"",
        endTime:"",
        BannerOpen:(localStorage.getItem("BannerShows") !== '0') ? true : false,
        authHeaders:localStorage.getItem('api_token')!=="" || localStorage.getItem('api_token')!==null ? { 'Authorization': `Bearer ${localStorage.getItem('api_token')}` } : "",
        }
        this.getJobPosting = this.getJobPosting.bind(this);
        this.getProfileDetails = this.getProfileDetails.bind(this);
        this.getUpcomingJobs = this.getUpcomingJobs.bind(this);
        this.getRunningJobs = this.getRunningJobs.bind(this);
        this.getCompletedJobs = this.getCompletedJobs.bind(this);
        this.listenForSSE = this.listenForSSE.bind(this);
        //this.GotoUserChat = this.GotoUserChat.bind(this);
    }

    HandleAddReview = (e) => {
        // this.setState({:true})
        let data = {job_id:this.state.CancelId,review:this.state.ReviewText,rating:this.state.rating.toString()};

        this.HandleModalClose(e,'ReviewModal')
        instance.post('parent/add-review',data).then((res) => {
            
            this.getCompletedJobs();
            toast('Review Added Successfully',{type:'success'});
            this.setState({rating:5,ReviewText:''});

        }).catch((error) => {
            this.setState({rating:5,ReviewText:''});        
            console.error(error)
            // this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })
        
    }
    // GotoUserChat = (Chat) => {
    //     let SenderId = ''
    //     let ReceiverId = ''

    //     if(TokenService.getUserRole() === 1){
    //         SenderId = TokenService.getUserId();
    //         ReceiverId = Chat.sitter_id;
    //     }else{
    //         SenderId = TokenService.getUserId();
    //         ReceiverId = Chat.parent_id;
    //     }
    //     // let Data = {
    //     //     "parent_id": SenderId,
    //     //     "sitter_id": ReceiverId,
    //     //     "job_id": Chat.id,
    //     // }

    //     //console.log(Data);
    // }

    HandleTipAmount = (e,tip) => {
        //console.log('tip is: ',tip);
        // if(tip===" " || tip===isNaN || tip===undefined || tip===null){
        //     tip=0;
        //     // console.log('Hey bro tip amount was NAN or undef or null so setting it to 0. TIP:- ', tip);
        //     this.setState({TipAmount:tip},console.log('tip amount first: ',this.state.TipAmount));
        // }
        
        // console.log('tip is after: ',tip);
        this.setState({TipAmount:tip})
    } 


    HandleCloseBanner = () => {
        this.setState({BannerOpen:false});
        localStorage.setItem("BannerShows",'0');
    }

    HandleFirstTab  = (e,index) => {
        this.setState({ FirstTabs:index })
        // console.log(e,index)
    }
    HandleSecondTab  = (e,index) => {
        this.setState({ SecondTabs:index })    
    }

    handleLogoClick() {
        if(document.getElementById('jobs-tab')){
            document.getElementById('jobs-tab').click();
        }
    }
    
    componentDidMount() {
        if(localStorage.getItem('api_token') !== ""){
            // Check if the user is using an iOS device
            const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
            .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
            if (isIOS && isSafari && iOSVersion >= 17) {
                setTimeout(() => {
                    this.getProfileDetails();
                }, 50);
                setTimeout(() => {
                    this.getJobPosting();
                    // console.log("Job posts")
                }, 200);
                setTimeout(() => {
                    this.getUpcomingJobs();
                    // console.log("upcoming Job")
                }, 450);
                setTimeout(() => {
                    this.getRunningJobs();
                    // console.log("running Job")
                }, 670);
                setTimeout(() => {
                    this.getCompletedJobs();
                    // console.log("completed Job")
                }, 820);
            } else {
                this.getProfileDetails();
                this.getJobPosting();
                this.getUpcomingJobs();
                this.getRunningJobs();
                this.getCompletedJobs();
            }
        }
    }

    componentDidUpdate(){
    }

    // A function to listen for server-sent events
    listenForSSE = () => {
        let retryCount = 0;
        let catchRetryCount = 0;
        const maxRetries = 4;
        const user = JSON.parse(localStorage.getItem('user'));
        const uuid = user.user_id;
        // signal = controller.signal;
        let connectSSE = () => {   
            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            controller = abortController;
            const abortSignal = abortController.signal
            // If there is an ongoing SSE connection, cancel it
            if(ongoingSSEConnections.length > 0){
                // console.log('inside if ongoingLength: ',ongoingSSEConnections);
                ongoingSSEConnections.forEach(existingController => {
                    // console.log('Aborting SSE connection:', existingController);
                    existingController.abort();
                });
                // Clear the array after aborting all connections
                ongoingSSEConnections = [];
            } 
        try {
            if(this.state.authHeaders!=="" && localStorage.getItem('api_token')!=="" && localStorage.getItem("isloggedin") === 'true' && isConnect === false){
                
                const headers = {
                    ...this.state.authHeaders,
                    Accept: "text/event-stream",
                    id: uuid,
                  };
                const token = TokenService.getLocalAccessToken();
                if (token) {
                    headers.Authorization = `Bearer ${token}`;
                }
                eventSource =  fetchEventSource(`${process.env.REACT_APP_API_PARENT_SSE_BASE_URL}`, {
                withCredentials: true,
                openWhenHidden: true,
                signal: abortSignal,
                method: 'POST',
                headers,
                onopen : (response) => {
                    if ((response.status >= 200 && response.status <= 299) || response.status === 304) {
                        if(isConnect) {
                            // isConnect = false;
                            abortController.abort();

                            ongoingSSEConnections.push(abortController);
                        }else {
                            // console.log("Connected.");
                            isConnect = true;
                            // console.log('eventSource is ',eventSource)
                            ongoingSSEConnections.push(abortController);
                        }
                        console.log('Connected Successfully');
                        return; // everything's good
                    } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                        // client-side errors are usually non-retriable:
                        isConnect = false;
                        console.log("Client side error: ", response);
                    } else {
                        isConnect = false;
                        console.log("Server side error: ", response);
                    }
                },
                onmessage : (msg) => {
                    // handle incoming message
                    // console.log('new event: ', msg.event);
                    //let state = this.state.bind(this);
                    const newBids = JSON.parse(msg.data);
                    const startJob = JSON.parse(msg.data);
                    const delay = 4000;
                    // New bid update state logic here
                if(localStorage.getItem('api_token')!==""){
                    if(msg.event==="newBid"){
                            if (newBids !== '' && newBids !== undefined) {
                            this.state.bidQueue.push(() => {    
                            toast(newBids, {
                                position: "top-right",
                                autoClose: 4000,
                                draggable: false,
                                pauseOnHover: false,
                                progress: undefined,
                                hideProgressBar: false,
                            });
                                
                            })
                        } 
                    }
                    if(msg.event==="jobStarted"){  
                        if (startJob !== '' && startJob !== undefined) {
                            toast(startJob, {
                                    position: "top-right",
                                    autoClose: 4000,
                                    draggable: false,
                                    pauseOnHover: false,
                                    progress: undefined,
                                    hideProgressBar: false,
                                });
                                shouldUpdate = true;
                                if(shouldUpdate){
                                    this.handleLogoClick()
                                }
                            }
                    }
                    
                    if (msg.event === 'FatalError') {
                        // console.log('Fatal Error:', msg.data);
                        isConnect = false;
                        eventSource.close(); // close the connection
                    }
                    // check if there are bids in the queue and if the previous bid has completed
                    if (this.state.bidQueue.length > 0 && !isProcessingBid) {
                        // set processing flag to true and dequeue bid from the queue
                        isProcessingBid = true;
                        const bid = this.state.bidQueue.shift();

                        // call the bid after the specified delay
                        setTimeout(() => {
                        bid();
                        // set processing flag to false and check if there are more bids in the queue
                        isProcessingBid = false;
                        if (this.state.bidQueue.length > 0) {
                            // if there are, call the next bid after the specified delay
                            setTimeout(() => {
                            const nextJob = this.state.bidQueue.shift();
                            nextJob();
                            isProcessingBid = false;
                            }, delay);
                        }
                        shouldUpdate = true;
                        if(shouldUpdate){
                            // console.log('Inside elIf');
                            this.handleLogoClick();
                        }
                        }, delay);
                    }
                } else{
                    if (controller) {
                        controller.abort();
                        controller = null; // Reset the current AbortController
                    }
                    if(ongoingSSEConnections.length > 0){
                        console.log('inside if ongoingLength: ',ongoingSSEConnections);
                        ongoingSSEConnections.forEach(existingController => {
                            // console.log('Aborting SSE connection:', existingController);
                            existingController.abort();
                        });
                        // Clear the array after aborting all connections
                        ongoingSSEConnections = [];
                    }
                }
                },
                onclose : (evt) => {
                    // if the server closes the connection unexpectedly, retry:
                    console.log('Connection closed unexpectedly, retrying in 5 seconds');
                    if (evt.target.readyState === EventSource.CLOSED) {
                        isConnect = false;
                        abortController.abort();
                        console.log('Connection was closed by the server');
                    } else if (retryCount < maxRetries) {
                        isConnect = false;
                        console.log('Connection lost, retrying...');
                        setTimeout(()=>{
                            retryCount++;
                            if(this.state.authHeaders!=="" && localStorage.getItem('api_token')!=="" && localStorage.getItem('api_token')!==null){
                             // retry connection after 10 seconds
                                connectSSE();
                            }    
                        }, 5000*(retryCount*2)); // retry after 5 seconds
                    } else {
                        console.log(`Connection lost after ${maxRetries} attempts, giving up. OnClose`);
                        isConnect = true;
                        abortController.abort();
                    }
                },
                onerror : (err) => {
                    console.error('An error occurred: ', err);
                    console.log('Retrying after 10 seconds');
                    
                    if (retryCount < maxRetries) {
                        // isConnect = false;
                        setTimeout(() => {
                            console.log('Retry Count : ',retryCount);
                            if (retryCount < maxRetries) {
                                if(this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined && localStorage.getItem('api_token')!==""&& localStorage.getItem('api_token')!==null){
                                    connectSSE(); // retry connection after 10 seconds
                                }
                                retryCount++;
                            }
                            return null;
                        }, 10000*(retryCount*2));
                    }else{
                        console.log(`Connection lost after ${maxRetries} attempts, giving up.OnErr`);
                        isConnect = true;
                        // controller.abort();
                        throw new Error("Connection failed after maximum retries.");
                    }
                },
            });
        }
    } catch (err) {
        console.error('An error occurred:', err);
        isConnect = false;
        if(!isConnect && catchRetryCount < maxRetries){
            setTimeout(() => {
                    console.log(`Retrying in ${catchRetryCount}`);
                    catchRetryCount++;
                if(!isConnect && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined  && localStorage.getItem('api_token')!==""){
                    connectSSE(); // retry connection after 10 seconds
                }
            }, 10000*(retryCount*2));
        }
      }
    }
        if(this.state.authHeaders!==""  && localStorage.getItem('api_token')!==""){
            if (controller) {
                controller.abort();
                controller = null; // Reset the current AbortController
            }
            connectSSE();
        }
    };

    HandleModalOpen = (e,modalname,id='') => {
        this.setState({[modalname]:true,CancelId:id})
    }

    HandleModalClose = (e,modalname,id='') => {
        this.setState({[modalname]:false,CancelId:id});
    }

    HandleCloseJob = (e) => {
        //console.log('Close job');
        this.setState({loading:true,CancelModal:false});
        instance.post('/parent/job/delete',{job_id:this.state.CancelId}).then((res) => {
            this.setState({loading:false,CancelId:''});
            setTimeout(() => {
                this.getJobPosting();
            }, 250)
            setTimeout(() => {
                this.getUpcomingJobs();
            }, 400)
            toast("Job has been cancelled successfully",{type: "success"});
        }).catch((error) => {
            this.setState({loading:false,CancelId:''});
            toast(error.response.data.message, { type: "error" });
        })
    }
    HandleRedirect = (e,route,state)=>{
        this.props.history.push({pathname:route,state:state})
    }

    getUpcomingJobs = () => {
        // Check if the user is using an iOS device
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;     
        if (isIOS && isSafari && iOSVersion >= 17) {
            // console.log('Is iOS:', isIOS);
            // console.log('Is Safari ifc gpd:', isSafari);
            const token = localStorage.getItem('api_token');
            const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/parent/upcoming-jobs?page=1`;
            // Include your headers in the options object
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            };
            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            const abortSignal = abortController.signal;

            // Set a timeout for the request (in milliseconds)
            const timeout = 2200;

            // Use setTimeout to trigger the abort after the specified timeout
            const timeoutId = setTimeout(() => {
                abortController.abort();
            }, timeout);
            // Make the fetch request with the specified method (GET)
            setTimeout(() => {
                fetch(url, {
                    method: 'GET',
                    headers: headers,
                    signal: abortSignal
                    // You can include additional options if needed
                })
                .then((res) => {
                    clearTimeout(timeoutId);
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.json(); // Assuming the response is in JSON format
                })
                .then((res) => {
                    // Handle the data
                    this.setState({UpcomingJobs:res.data,loading:false});
                    // console.log('iOS Version: psd guj ', iOSVersion);
                }).catch((error) => {
                    this.setState({loading:false});
                    // toast(error.message, { type: "error" });
                    this.getUpcomingJobs();
                });
            }, 800);
        } else{
            instance.get('/parent/upcoming-jobs?page=1').then((res) => {
                this.setState({UpcomingJobs:res.data.data,loading:false});
                // console.log(res.data.data);
            }).catch((error) => {
                console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            })
        }
    }
    getRunningJobs = () => {
        // Check if the user is using an iOS device
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
        if (isIOS && isSafari && iOSVersion >= 17) {
            // console.log('Is iOS:', isIOS);
            // console.log('Is Safari:', isSafari);
            const token = localStorage.getItem('api_token');
            const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/parent/ongoing-jobs`;

            // Include your headers in the options object
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            };
            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            const abortSignal = abortController.signal;

            // Set a timeout for the request (in milliseconds)
            const timeout = 2200;

            // Use setTimeout to trigger the abort after the specified timeout
            const timeoutId = setTimeout(() => {
                abortController.abort();
            }, timeout);
            // Make the fetch request with the specified method (GET)
            setTimeout(() => {
                fetch(url, {
                    method: 'GET',
                    headers: headers,
                    signal: abortSignal
                    // You can include additional options if needed
                })
                .then((res) => {
                    clearTimeout(timeoutId);
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.json(); // Assuming the response is in JSON format
                })
                .then((res) => {
                    // Handle the data
                    this.setState({RunningJobs:res.data,loading:false});
                    // console.log('iOS Version: psd grunj ', iOSVersion);
                }).catch((error) => {
                    this.setState({loading:false});
                    // toast(error.message, { type: "error" });
                    this.getRunningJobs();
                });
            }, 800);
        }else {
            instance.get('/parent/ongoing-jobs').then((res) => {
                this.setState({RunningJobs:res.data.data,loading:false});
                // console.log('getRunningJobs',res.data.data);
            }).catch((error) => {
                console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            })
        }
    }
 
    getCompletedJobs = () => {
        //console.log('getCompletedJobs');
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        // Check if the user is using Safari
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
        
        if (isIOS && isSafari && iOSVersion >= 17) {
                // console.log('Is iOS:', isIOS);
                // console.log('Is Safari:', isSafari);
                const token = localStorage.getItem('api_token');
                const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/parent/completed-jobs`;
                // Include your headers in the options object
                const headers = {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                };
                // Create an AbortController and signal for aborting the request
                const abortController = new AbortController();
                const abortSignal = abortController.signal;

                // Set a timeout for the request (in milliseconds)
                const timeout = 2000;

                // Use setTimeout to trigger the abort after the specified timeout
                const timeoutId = setTimeout(() => {
                    abortController.abort();
                }, timeout);
                // Make the fetch request with the specified method (GET)
                setTimeout(() => {
                    fetch(url, {
                        method: 'GET',
                        headers: headers,
                        signal: abortSignal
                        // You can include additional options if needed
                    })
                    .then((res) => {
                        clearTimeout(timeoutId);
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.json(); // Assuming the response is in JSON format
                    })
                    .then((res) => {
                        // Handle the data
                        this.setState({CompletedJobs:res.data,loading:false});
                        // console.log('iOS Version: pgcj ', iOSVersion);
                    }).catch((error) => {
                        this.setState({loading:false});
                        // toast(error, { type: "error" });
                        this.getCompletedJobs();
                    });
                }, 650);
        }else{
            instance.get('/parent/completed-jobs').then((res) => {
                this.setState({CompletedJobs:res.data.data,loading:false});
                console.log('getcompletedobs',res.data.data);
            }).catch((error) => {
                console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            })
        }
    }

    HandleEndJob = (e,tip=false) => {
        // console.log('tip bool: ',tip);
        this.setState({loading:true})
        if(tip===true && (this.state.TipAmount==="" || isNaN(this.state.TipAmount) || this.state.TipAmount===" " || this.state.TipAmount===null || this.state.TipAmount===undefined)){
            // console.log('nan found and setting it to zerooooo.');
            this.setState({TipAmount:0})
        }else if(tip===false && (this.state.TipAmount==="" || isNaN(this.state.TipAmount) || this.state.TipAmount===" " || this.state.TipAmount===null || this.state.TipAmount===undefined)){
            this.setState({TipAmount:0})
        }
        
        this.HandleModalClose(e,'TipModal')
        let CancelId = this.state.CancelId;
        // console.log('tip ',this.state.TipAmount);
        this.setState({ TipAmount: (tip && (this.state.TipAmount === "" || isNaN(this.state.TipAmount))) ? 0 : this.state.TipAmount }, () => {
            let data = {job_id:CancelId,tip:this.state.TipAmount}
            instance.post('parent/end-job',data).then((res) => {
                if(document.getElementById('jobs-tab')){
                    document.getElementById('jobs-tab').click();
                    setTimeout(() => {
                        this.getProfileDetails();
                    }, 25);
                    setTimeout(() => {
                        this.getJobPosting();
                        // console.log("Job posts")
                    }, 170)
                    setTimeout(() => {
                        this.getUpcomingJobs();
                        // console.log("upcoming Jobs")
                    }, 310)
                    setTimeout(() => {
                        this.getRunningJobs();
                        // console.log("running Jobs")
                    }, 500)
                    setTimeout(() => {
                        this.getCompletedJobs()
                        // console.log("completed Jobs")
                    }, 650)
                }
                // console.log(res.data.data);
                // this.getUpcomingJobs();
                toast('Your Sitting is Completed',{type:'success'});
                this.props.history.replace({ 
                    pathname:'/parent/profile'
                });
                this.props.history.push({ 
                    pathname:'/parent/dashboard', 
                    state: {
                        firsttab:2,
                        secondtab:3
                    }
                })
                
            }).catch((error) => {
                console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            })
        })
        // this.HandleModalClose(e,'StartJob')

    }



    getJobPosting = () => {
        
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        // Check if the user is using Safari
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
        // You can also get the iOS version
        // let source = this.props.location.state?.source;
        
        if (isIOS && isSafari && iOSVersion >= 17) {
            // source = ""
            // console.log('Is iOS:', isIOS);
            // console.log('Is Safari:', isSafari);
            const token = localStorage.getItem('api_token');
            const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/parent/job-postings?page=1`;
            // Include your headers in the options object
            const headers = {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            };
            // Create an AbortController and signal for aborting the request
            const abortController = new AbortController();
            const abortSignal = abortController.signal;

            // Set a timeout for the request (in milliseconds)
            const timeout = 2500;

            // Use setTimeout to trigger the abort after the specified timeout
            const timeoutId = setTimeout(() => {
                abortController.abort();
            }, timeout);
            // Make the fetch request with the specified method (GET)
            setTimeout(() => {
                fetch(url, {
                    method: 'GET',
                    headers: headers,
                    signal: abortSignal
                })
                .then((res) => {
                    clearTimeout(timeoutId);
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    if(!isConnect && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined){
                        if (res.status >= 200 && res.status <= 299) {
                            if(isConnect === false){
                                setTimeout(() => {
                                    this.listenForSSE();
                                }, 700);
                            }
                        }
                    }
                    return res.json(); // Assuming the response is in JSON format
                }).then((res) => {
                    // Handle the data
                    this.setState({PostsList:res.data,loading:false});
                    // console.log('iOS Version ifc gjp: ', iOSVersion);
                    // if(!isConnect && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined){
                    //     if (res.status >= 200 && res.status <= 299) {
                    //         this.listenForSSE();
                    //     }
                    // }
                })
                .catch((error) => {
                    this.setState({loading:false});
                    // toast(error.message, { type: "error" });
                    this.getJobPosting();
                });
            }, 700);
        } else{
            instance.get('/parent/job-postings?page=1').then((res) => {
                this.setState({PostsList:res.data.data,loading:false});
                //let sortedJobs = this.state.PostsList.sort((a, b) => (Date.parse(new Date(b.start_date.split("/").reverse().join("-"))) - Date.parse(new Date(a.start_date.split("/").reverse().join("-")))));
                //let sortedJobs = this.state.PostsList.sort((a, b) => ((moment(moment(b.start_date).diff(moment(a.start_date))))));
                // console.log(res.data.data);
                if(!isConnect && this.state.authHeaders!=="" && this.state.authHeaders!==null && this.state.authHeaders!==undefined){
                    this.listenForSSE();
                }
            }).catch((error) => {
                console.error(error)
                this.setState({loading:false});
                toast(error.response.data.message, { type: "error" });
            });
        }
    }



    getProfileDetails = () => {
        // Check if the user is using an iOS device
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        // Check if the user is using Safari
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        // Log the information
       //  console.log('Is iOS:', isIOS);
       //  console.log('Is Safari:', isSafari);
        
        const iOSVersion = isIOS ? parseFloat(('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')) || undefined : undefined;
        // You can also get the iOS version
       //  console.log('iOS Version:', iOSVersion);
        
        // Check if the last visited page was the login page
       //  let source = this.props.location.state?.source;
        // if (isIOS && isSafari && iOSVersion >= 17 && source === "login") {
        if (isIOS && isSafari && iOSVersion >= 17) {
        //    console.log('Is iOS:', isIOS);
        //    console.log('Is Safari gpd:', isSafari);
        //    console.log('iOS Version ifc gpd:', iOSVersion);
           const token = localStorage.getItem('api_token');
           const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/get-parent-profile`;

           // Include your headers in the options object
           const headers = {
               "Content-Type": "application/json",
               'Authorization': `Bearer ${token}`
           };

           // Create an AbortController and signal for aborting the request
           const abortController = new AbortController();
           const abortSignal = abortController.signal;

           // Set a timeout for the request (in milliseconds)
           const timeout = 2200;

           // Use setTimeout to trigger the abort after the specified timeout
           const timeoutId = setTimeout(() => {
               abortController.abort();
           }, timeout);

           // Make the fetch request with the specified method (GET)
           fetch(url, {
               method: 'GET',
               headers: headers,
               signal: abortSignal
               // You can include additional options if needed
           })
           .then((res) => {
               clearTimeout(timeoutId);
               if (!res.ok) {
                   throw new Error(`HTTP error! Status: ${res.status}`);
               }
               return res.json(); // Assuming the response is in JSON format
           })
           .then((res) => {
           // Handle the data
               ProfileService.setUserProfile(res.data);
            //    console.log(`iOS-${iOSVersion} ifc gpd`);
           })
           .catch((error) => {
               // toast(error.message, { type: 'error' });
               //Retry the request
               this.getProfileDetails();
           });
        } else {
            instance.get('/get-parent-profile').then((res) => {
                ProfileService.setUserProfile(res.data.data);
                //  console.log("Testing string: ",res.data.data);
            }).catch((error) => {
                //console.log(error.response);
                toast(error.response.data.message, { type: "error" });
            })
        }
    }
    
    logout = () => {
        if(ongoingSSEConnections){
            ongoingSSEConnections.forEach(existingController => {
                // console.log('Aborting SSE connection:', existingController);
                existingController.abort();
            });
            controller.abort();
            controller = null;
            controller = new AbortController();
        }
        this.setState({authHeaders:null}); 
        isConnect = false;
        localStorage.clear();
        this.props.history.push('/')
      toast('Logged out successfully',{ type:'success' });
        
    }

    render() {
        // let date = moment().format('YYYY-MM-DD HH:mm:ss');
        // let onlyDate = moment(date).format('YYYY-MM-DD');
        // let onlyTime = moment(date).format('HH:mm:ss');
        let activities = [
            {name:'Your job posts',count:this.state.PostsList.length},
            {name:'Current jobs',count:this.state.RunningJobs.length},
            {name:'Upcoming jobs',count:this.state.UpcomingJobs.length},
            {name:'Completed jobs',count:this.state.CompletedJobs.length},
        ]

        if(shouldUpdate){
            shouldUpdate=false;
                this.getJobPosting();
                this.getUpcomingJobs();
                this.getCompletedJobs();
                this.getRunningJobs();
                this.getProfileDetails();
        }

        return (
            <>
<section className={`dashboard ${this.state.loading ?'loader' : ''} `}>
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-3 sidebar">
                <SidebarOne rating={ProfileService.getReviewsAvg()} rating_count={ProfileService.getReviewsCount()} type="parent" 
                button={<>
                <Link to='/job-post' className="view-profile-button" title='Post Job' style={{fontSize:"18px"}}><i className="fa fa-briefcase" style={{fontSize:"13px"}}></i></Link>
                <Link to='/parent/profile' className="view-profile-button logoutbtn" title='Profile' style={{marginLeft:"5px", fontSize:"18px"}}><i className="fa fa-user" style={{fontSize:"13px"}}></i></Link>
                <Link to='/' replace onClick={this.logout} className="view-profile-button logoutbtn" title='Logout' style={{marginLeft:"5px", fontSize:"18px"}}><i className="fa fa-sign-out-alt" style={{fontSize:"13px"}}></i></Link>
                </>}    activities={activities} ></SidebarOne>
                </div>
                <div className="col-12 col-lg-9">
                    <div onClick={this.HandleCloseBanner} hidden={!this.state.BannerOpen} className="top-poster">
                        <img src={ParentPoster} alt=""/>
                        <a><i className="fa fa-times"></i></a>
                    </div>
                    <div className="col-12 navbar">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button onClick={(event) => this.HandleFirstTab(event,1)} 
                                className={`nav-link  dashboard-tab ${(this.state.FirstTabs == 1) ? ' active' : ''}`} id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="true"><i className="dashboard"></i> <span>Dashboard</span></button>
                            </li>
                            <li className="nav-item " role="presentation">
                                <button className={`nav-link  jobs-tab ${(this.state.FirstTabs == 2) ? ' active' : ''}`} onClick={(event) => this.HandleFirstTab(event,2)} id="jobs-tab" data-bs-toggle="tab" data-bs-target="#jobs" type="button" role="tab" aria-controls="jobs" aria-selected="false"><i className="breifcase"></i><span>Jobs</span></button>                            </li>
                            <li className="nav-item " role="presentation">
                                {/* <button className={`chats-tab ${(this.state.FirstTabs == 3) ? ' active' : ''}`} id="chats-tab" data-bs-toggle="tab" data-bs-target="#chats" type="button" role="tab" aria-controls="chats" aria-selected="false"><i className="chat"></i><span>Chats</span></button> */}
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 main-content">
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade dashboard-content ${(this.state.FirstTabs == 1) ? 'show active' : ''}`} id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">

                                {/* <!-- No jobs yet --> */}


                                {(this.state.RunningJobs.length == 0) ? <div className="row justify-content-center">
                                    <div className="col-12 col-md-3 illustration p-5 p-md-2 image-container">
                                        <img src={DashIllustration} alt=""/>
                                        <div className="image-overlay"></div>
                                    </div>
                                    <div className="col-12 col-md-6 right-content">
                                        <h1 className="text-blue">Welcome, {TokenService.getUserFullname()}</h1>
                                        <h6 className="text-grey">No current jobs at this time.</h6>
                                         <Link to="/job-post" >
                                            <button className="post-a-job blue-grad btn-blue"><i className="breifcase-white"></i> Post a job</button>
                                         </Link>
                                    </div>
                                </div> : 
                                <div className="row ">
                                    <div className="col-12">
                                        <h4 className='text-gray'> Ongoing Jobs </h4>
                                        {this.state.RunningJobs.map((value) => {
                                            let jobPostStartDuration = value.job_startedAt;
                                            let now = moment(new Date());
                                            let duration = moment.duration(now.diff(jobPostStartDuration))
                                            let minutes = Math.ceil(duration.asMinutes());
                                            let jobStarted = moment.utc(jobPostStartDuration).subtract(4, 'hours').format('DD-MM-YYYY hh:mm:ss');
                                            
                                            // let jobPostEndDuration = moment(value.end_date+' '+value.end_time);
                                            return (
                                        //         <div className="col-12 col-lg-8 job-list-item">
                                        //     <div className="card">
                                        //         <div className="row">
                                        //             <div className="col-7 col-lg-8">
                                        //                 <span className="date"><i className="fa fa-calendar-week"></i>
                                        //                 {moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}  </span>
                                        //                 <br></br>
                                        //                 <span className="date"><i className="fa fa-clock"></i>
                                        //                 {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}


                                        //                 {/* <span className="date"><i className="fa fa-clock"></i>{value.start_time} - 
                                        //                 {value.end_time}</span> */}
                                        //                 </span>
                                        //             </div>
                                        //         </div>
                                        //         <span className="h5 sitter-name"> <div className="avatar-image"><img src={value.sitter.image ?? ProfilePicturePlaceholder} alt=""/></div> 
                                        //         Sitter: {value.sitter.first_name} {value.sitter.last_name}
                                        //         </span>
                                        //         <small className="text-blue">Health condition details</small>
                                        //         <p>
                                        //            {value.allergies}
                                        //         </p>
                                        //         <small className="text-blue">Description</small>
                                        //         <p>
                                        //            {value.job_details}
                                        //         </p>
                                        //     </div>
                                        // </div>

                                        <div key={value.job_id} className="col-12 col-lg-8 job-list-item">
                                            {/* {console.log('Job actual start time and end time : ',moment.duration(moment(new Date()).diff(value.job_startedAt)).asMinutes())}
                                            {console.log('Job Post duration : ',moment.duration(jobPostEndDuration.diff(jobPostStartDuration)).asMinutes())} */}
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-6">
                                                    {/* <span className=" date">August 20, 2021</span> */}
                                                    <span className=" date">{moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}</span>
                                                </div>
                                                <div className="col-6"> 
                                                    <a type="button" onClick={(e)=> this.HandleModalOpen(e,'TipModal',value.job_id)} className="end-sitting float-end" data-bs-toggle="modal" data-bs-target="#add-tip">End Sitting <i className="toggle-on"></i></a>
                                                </div>
                                                {minutes<60?<small className="alert alert-danger">You will be charged sitter's hourly rate of ${value.sitterdata.hourly_rate} as a cancellation fee for ending job before an hour. Total job duration elapsed is {minutes} minute/s.</small>:""}
                                            </div>
                                            <h4 className="sitter-name">Sitter : {value.sitter.first_name} {value.sitter.last_name}</h4>
                                            <ul>
                                                <li>
                                                    <h5><i className="fa fa-circle text-blue"></i>Start : {value.start_time}</h5>
                                                </li>
                                                <li><i className="line"></i></li>
                                                <li><h5 className="text-pink"><i className="fa fa-hourglass-start text-blue"></i>Sitting started at : {jobStarted}</h5></li>
                                            </ul>
                                        </div>
                                    </div>
                                            );
                                    })} 
                                        </div>
                                    </div>}

                                

                                {/* <!-- List of jobs --> */}

                                {/* ongoing JObs are not visible */}
                                {/* <div className="row ongoing-jobs job-list">

                                    {this.state.RunningJobs.map((value)=> {
                                        return (
                                        
                                            <div className="col-12 col-lg-8 job-list-item">
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-6">
                                                    {/* <span className=" date">August 20, 2021</span> */}
                                                    {/* <span className=" date">{moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}</span>
                                                </div>
                                                <div className="col-6">
                                                    <a type="button" onClick={(e)=> this.HandleModalOpen(e,'TipModal',value.job_id)} className="end-sitting float-end" data-bs-toggle="modal" data-bs-target="#add-tip">End Sitting <i className="toggle-on"></i></a>
                                                </div>
                                            </div>
                                            <h4 className="sitter-name">{TokenService.getUserFullname()}</h4>
                                            <ul>
                                                <li>
                                                    <h5><i className="fa fa-circle text-blue"></i>Start : {value.start_time}</h5>
                                                </li>
                                                <li><i className="line"></i></li>
                                                <li>
                                                    <h5><i className="fa fa-circle text-pink"></i>End : {value.end_time}</h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>) */}
                                    {/* })} */}
                                    {/*</div>  */}
                                    
                                    {/* <div className="col-12 col-lg-8 job-list-item">
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-6">
                                                    <span className=" date">August 20, 2021</span>
                                                </div>
                                                <div className="col-6">
                                                    <a  className="end-sitting float-end">End Sitting <i className="toggle-on"></i></a>
                                                </div>
                                            </div>
                                            <h4 className="sitter-name">Elyse Perry</h4>
                                            <ul>
                                                <li>
                                                    <h5><i className="fa fa-circle text-blue"></i>Start : 8:00 AM</h5>
                                                </li>
                                                <li><i className="line"></i></li>
                                                <li>
                                                    <h5><i className="fa fa-circle text-pink"></i>Start : 8:00 AM</h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}


                            </div>


                            <div className={`tab-pane fade jobs-content ${(this.state.FirstTabs == 2) ? 'show active' : ''}`}  id="jobs" role="tabpanel" aria-labelledby="jobs-tab">
                                <div className="col-12">
                                <ul  className="navi nav-tabs job-tabbar" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${(this.state.SecondTabs == 1) ? 'active' : ''}`} onClick={(e) => this.HandleSecondTab(e,1)} id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="true">Upcoming jobs</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${(this.state.SecondTabs == 2) ? 'active' : ''}`} id="current-posting-tab" onClick={(e) => this.HandleSecondTab(e,2)} data-bs-toggle="tab" data-bs-target="#active-posting" type="button" role="tab" aria-controls="active-posting" aria-selected="false">Current postings</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className={`nav-link ${(this.state.SecondTabs == 3) ? 'active' : ''}`} id="active-posting-tab" onClick={(e) => this.HandleSecondTab(e,3)} data-bs-toggle="tab" data-bs-target="#active-posting" type="button" role="tab" aria-controls="active-posting" aria-selected="false">Completed Jobs</button>
                                    </li>
                                </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className={`tab-pane fade  ${(this.state.SecondTabs == 1) ? 'show active' : ''}`}  id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                                        {/* <!-- List of jobs --> */}

                                        {(this.state.UpcomingJobs.length == 0) ? <div className="row justify-content-left">
                                    <div className="col-12 col-md-6 mt-4 ml-4">
                                        <h5 className="text-grey">No upcoming job at this time.</h5>
                                    </div>
                                </div> : 
                                        <div className="row ongoing-jobs job-list">
                                            <div className="scrolling"> 
                                            {this.state.UpcomingJobs.map((value) => {
                                                return (        
                                                    <div key={value.job_id} className="col-12 col-lg-8 job-list-item">
                                                <div className="card">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span className="date"><i className="fa fa-calendar-week"></i>{moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}</span>
                                                            <br></br>
                                                            <span className="date"><i className="fa fa-clock"></i> {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}</span>
                                                        </div>
                                                        <div className="col-6">
                                                        <a onClick={(e) => this.HandleModalOpen(e,'CancelModal',value.job_id)} data-bs-toggle="modal" data-bs-target="#cancel-job" className="cancel-job float-end">Cancel job</a>
                                                        </div>
                                                    </div>
                                                    
                                                    <span className="h5 sitter-name"> <div className="avatar-image">
                                                        <img src={value.sitter.image} alt=""/></div> {value.sitter.first_name} {value.sitter.last_name}</span>
                                                    <small className="text-blue">Description</small>
                                                    <p>
                                                        {value.job_details}
                                                    </p>
                                                    <p>
                                                        {value.allergies}
                                                    </p>
                                                    <h5 className="underline pt-3">Sitter References:</h5>
                                                    <p>
                                                        {value.sitter.references.map((value,index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <span className="text-blue">Reference-{index+1} : </span>
                                                                    <span className="text-blue">{value.f_name} {value.l_name} | {value.country_id} {value.phone}</span>
                                                                </div>
                                                            )
                                                        })}
                                                    </p>

                                                    {/* <p><i className="fa fa-road"> </i> {value.sitterdata.transportation}</p> */}
                                                    <span> <h4> Otp : {value.otp} </h4> </span>
                                                    
                                                    {/* {value.sitter.references.map((reference, index)=> {
                                                        return <span key={index}>
                                                        Reference {index+1} : <br/>
                                                        <i className="fa fa-users"></i> {reference.f_name} {reference.l_name} <span>&nbsp; || &nbsp;</span> <i className='fab fa-whatsapp' aria-hidden="true"></i>Phone : {reference.country_id} {reference.phone}
                                                        </span>
                                                    })} */}
                                                    {/* <span onClick={(e) => this.GotoUserChat(value)} className="chat-button"><i className="chat"></i>Chat</span> */}
                                                </div>
                                            </div>);
                                            })}
                                            </div>
                                        </div>}
                                    </div>
                                    

                                    <div className={`tab-pane fade  ${(this.state.SecondTabs == 2) ? 'show active' : ''}`}  id="active-posting" role="tabpanel" aria-labelledby="active-posting-tab">

                                    {(this.state.PostsList.length == 0) ? <div className="row justify-content-left">
                                    <div className="col-12 col-md-6 mt-4 ml-4">
                                        <h5 className="text-grey">You currently don't have any jobs posted</h5>
                                    </div>
                                </div> :                                              
                                    <div className="scrolling">
                                        {this.state.PostsList.map((value,index) => {
                                            return (
                                            <div key={value.job_id} className="col-12 col-lg-8 job-list-item">        
                                            {/* {console.log('value: ',value.job_id)}   */}
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-9 col-lg-8" onClick={(e)=>this.HandleRedirect(e,'/parent/job/applied-shitters',{job_id:value.job_id})} >
                                                        <span className="date"><i className="fa fa-calendar-week"></i>
                                                        {moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}<span></span> - <span></span>{moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}  </span>
                                                        <br></br>
                                                        <span className="date"><i className="fa fa-clock"></i>
                                                        {moment(value.start_time,'hh:mm:ss').format('hh:mm A')}<span></span> - <span></span>{ moment(value.end_time,'hh:mm:ss').format('hh:mm A')}


                                                        {/* <span className="date"><i className="fa fa-clock"></i>{value.start_time} - 
                                                        {value.end_time}</span> */}
                                                        </span>
                                                    </div>
                                                    <div className="col-3 col-lg-4">
                                                        <a onClick={(e) => this.HandleModalOpen(e,'CancelModal',value.job_id)} data-bs-toggle="modal" data-bs-target="#cancel-job" className="cancel-job float-end">Cancel job</a>
                                                    </div>
                                                    </div>
                                                <span className="h5 sitter-name" onClick={(e)=>this.HandleRedirect(e,'/parent/job/applied-shitters',{job_id:value.job_id})} > <div className="avatar-image"><img src={ProfileService.getUserImage() ?? ProfilePicturePlaceholder} alt=""/></div> 
                                                {ProfileService.getUserFullName()}
                                                </span>
                                                <small className="text-blue" onClick={(e)=>this.HandleRedirect(e,'/parent/job/applied-shitters',{job_id:value.job_id})} >Description</small>
                                                <p onClick={(e)=>this.HandleRedirect(e,'/parent/job/applied-shitters',{job_id:value.job_id})}>
                                                   {value.job_details}
                                                </p>
                                                <div className="col-12" onClick={(e)=>this.HandleRedirect(e,'/parent/job/applied-shitters',{job_id:value.job_id})}>
                                                     
                                                        <a onClick={(e)=>this.HandleRedirect(e,'/parent/job/applied-shitters',
                                                        {job_id:value.job_id})} className={`float-end + ${value.bid_count===0 ? "number-sitters-appliedZero":"number-sitters-applied"}`}>
                                                            <i className="fa fa-list-alt"></i>
                                                            {value.bid_count} sitters applied
                                                            </a>
                                                    </div>
                                                {/* <a href="applied-sitters.html" className="cancel-job float-end"><i className="fa fa-list-alt"></i>2 sitters applied</a> */}
                                                {/* <a  className="chat-button"><i className="chat"></i>Chat</a> */}
                                            </div>
                                            
                                        </div>)
                                        })}
                                        </div>}
                                    </div>

                                    <div className={`tab-pane fade  ${(this.state.SecondTabs == 3) ? 'show active' : ''}`}  id="active-posting" role="tabpanel" aria-labelledby="active-posting-tab">

                                    {(this.state.CompletedJobs.length == 0) ? <div className="row justify-content-left">
                                    <div className="col-12 col-md-6 mt-4 ml-4">
                                        <h5 className="text-grey">You don't have any completed jobs</h5>
                                    </div>
                                </div> : 
                                <div className="scrolling">
                                {this.state.CompletedJobs.map((value,index) => {
                                    let jobStarted = moment.utc(value.job_startedAt).subtract(4, 'hours').format('MM-DD-YYYY hh:mm:ss')
                                    let jobEnded = moment.utc(value.job_endedAt).subtract(4, 'hours').format('MM-DD-YYYY hh:mm:ss')
                                    return (
                                                <div key={value.job_id} className="col-12 col-lg-8 job-list-item">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-9 col-md-9 col-lg-9">
                                                        <span className="date"><i className="fa fa-calendar-week"></i>
                                                        {moment(value.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')} - {moment(value.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}  </span>
                                                        <br></br>
                                                        <span className="date"><i className="fa fa-clock"></i>
                                                        {moment(value.start_time,'hh:mm:ss').format('hh:mm A')} - { moment(value.end_time,'hh:mm:ss').format('hh:mm A')}
                                                        </span>
                                                        <br></br>
                                                        <span className="date"><i className="fa fa-hourglass-start text-blue"></i> Sitting started at : {jobStarted}</span>
                                                        <br></br>
                                                        <span className="date"><i className="fa fa-hourglass-start text-blue"></i> Sitting ended at : {jobEnded}</span>
                                                        <br></br>
                                                        <span className="date"><i className="fa fa-dollar-sign text-blue"></i> Total amount : {value.total_amount}</span>

                                                    </div>
                                                </div>
                                                <span className="h5 sitter-name"> <div className="avatar-image">
                                                    <img src={value.sitter.image} alt=""/></div> 
                                                {value.sitter.first_name} {value.sitter.last_name}
                                                </span>
                                                <small className="text-blue">Description</small>
                                                <p>
                                                   {value.job_details}
                                                </p>
                                                <div className="text-right text-blue" >
                                                {
                                                (value.review_added == 0) 
                                                ? 
                                                <a onClick={(e) => this.HandleModalOpen(e,'ReviewModal',value.job_id)}   className="text-blue "> <i className="fa fa-star" ></i> Rate Sitter  </a>
                                                 : 
                                                <div className=' text-right'>
                                               <span className='mr-4 text-right' > You rated sitter </span> 
                                                <StarRatings
                                                        rating={value.rating}
                                                        starDimension="14px"
                                                        starRatedColor='#2986E3 '
                                                        starSpacing="3px"/></div>}
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
                                    </div>}
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade chats-content ${(this.state.FirstTabs == 3) ? 'show active' : ''}`} id="chats" role="tabpanel" aria-labelledby="chats-tab"> 
                           {/* <Chat 
                           SocketClient={this.state.Oursocket} 
                           /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad " className="cancel-job-modal " show={this.state.CancelModal} onHide={(e)=> this.HandleModalClose(e,'CancelModal')}> 
            <Modal.Body>
                <div className="col-12 text-center">
                        <h3 className="text-white pt-4">Are you sure you want to cancel?</h3>
                        <p className="text-white">You will be charged $15 if you have hired a babysitter and you cancel this job less than two hours prior to the start of a job.</p>
                </div>
            </Modal.Body>   
                <Modal.Footer className="justify-content-center" >
                <button onClick={(e)=> this.HandleModalClose(e,'CancelModal')} type="button" className="btn btn-primary small-btn">No, don't cancel</button> &nbsp;&nbsp;&nbsp;
                <button onClick={(e) => this.HandleCloseJob(e)} type="button" className="btn btn-white small-btn text-blue">Yes, cancel this job</button>
             </Modal.Footer>                                     
       
      </Modal>    

      <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName=" " className="add-tip-modal " show={this.state.TipModal} onHide={(e)=> this.HandleModalClose(e,'TipModal')}>        
            <Modal.Body>
                        <div className="col-12 text-center">                            
                            <h5 className="title">Want to add a tip for sitter before ending sitting?</h5>
                            <ul className="list-inline">
                                {TipsArray.map((tip, index)=> { return (
                                <li key={index} className="list-inline-item"><a onClick={(e)=> this.HandleTipAmount(e,tip)} className="btn-pink">${tip}</a></li>) })}
                            </ul>
                            <div className="col-12 tip-amount">
                                <i className="fa fa-dollar-sign"></i>
                                <input type="number" value={this.state.TipAmount}  onChange={(e)=> this.HandleTipAmount(e,e.target.value) } className="tip-amount" id="" required/>
                            </div>
                        </div>
            </Modal.Body>   
            <Modal.Footer className="justify-content-center" >
                        {/* <button type="button" onClick={(e)=> this.HandleEndJob(e,false) } className="btn btn-secondary skip-button" data-bs-dismiss="modal">Skip</button> */}
                        <button type="button" onClick={(e)=> this.HandleEndJob(e,true) } className="btn btn-primary btn-blue small-btn">Confirm</button>
            </Modal.Footer>                                                                       
       
      </Modal>            
      <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName=" " className="cancel-job-modal " show={this.state.ReviewModal} onHide={(e)=> this.HandleModalClose(e,'ReviewModal')}>        
             <Modal.Header> <div className="text-center" >
             <h3 className="text-blue "> Rate Parent</h3><p >(For this job)</p></div>  </Modal.Header>                             
            <Modal.Body>
                <div className="col-12 text-center">
                    <StarRatings 
                       rating={this.state.rating}
                       starRatedColor='#2986E3'
                       starHoverColor='#2986E3'
                       changeRating={(newrating,name) => {this.setState({rating:newrating})}}
                    />
                    <div className="col-12 py-2">
                                <label className="text-blue">
                                    Want to add something more? (optional)
                                </label>
                                <textarea  onChange={(e)=> {this.setState({ReviewText:e.target.value})}}  value={this.state.ReviewText}  className="form-control" name="about_sitter" id="about_sitter" rows="5" placeholder="(Include what you like the most)">
                                    {this.state.about}
                                </textarea>
                                <label className='text-pink' style={{textAlign:'justify'}}>When writing a negative review, you may want to consult with your Rabbi to ensure that the information you are sharing is in accordance with Halacha.</label>
                    </div>
                </div>
            </Modal.Body>   
            <Modal.Footer className="justify-content-center" >
                <button onClick={(e)=> this.HandleAddReview(e)} type="button" className="btn btn-primary small-btn">Submit </button> 
                {/* <button onClick={(e)=> this.HandleStartJob(e)} type="button" className="btn btn-primary small-btn">Yes </button>  */}
            </Modal.Footer>                                     
      </Modal>                                                             
            </>
        );
    }
}

ParentDashboard.propTypes = {

};

export default ParentDashboard;