import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal,Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import instance from '../../../context/AuthApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ChildAgeGroups, ChildAgeGroupsInYrs } from "../../../Constants/ChildAgeGroups";
import axios from 'axios';


class JobDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SendParposal:false,
            CancelModal:false,
            JobID:(this.props.location?.state?.job_id) ? this.props.location?.state?.job_id : '' ,
            loading:true,
            child_age: [{child_id:"",f_name: "", dob: "", name: ""}],
            GetPost:{
                job_id: "d324aecd-24bf-4e04-98cc-92e857d5e946",
                user_id: 147,
                start_date: "2021-12-11",
                end_date: "2021-12-11",
                start_time: "11:19:00",
                end_time: "12:19:00",
                late: false,
                allergies: "Western New Jersey and I the only person I have ever seen was a very good about and the rest of the world is the same as a very good friend ",
                job_location: "6, Sector 67, Sahibzada Ajit Singh Nagar, Punjab 160062, India",
                job_details: "There the way in that I am not sure if you have a good sense that I can be of assistance in this matter as I am ",
                setSelectedChild_id: ["fddc6738-12f6-484c-b10e-bbe8387fdcf6","yyyc6738-12f6-484c-b10e-bbe8387fdcf6"],
                job_status: "Posted",
                lat: "30.67928239999999",
                lng: "76.72932329999999",
                already_applied: false,
                parent: {
                    //user_id: "3e0a69dc-504a-43df-a228-0e3757ba352d",
                    first_name: "Parenting",
                    last_name: "Control",
                    //religion: "Christina ",
                    image: "https://s3simplisitter.s3.amazonaws.com/images/LbxqDjrIghrSEXpbvEa3d0KoWpILbXjmACeNLdvTMqcF1gqObd_1639114407998.jpg",
                    //child_count: 2,
                    language: "Bolivia",
                    pets: true,
                    pets_details: "bat man, spider man",
                    spouse: "👦BOY",
                    amenities: [
                        "Access to kitchen",
                        "House phone",
                        "Comfortable couch",
                        "Wifi"
                    ],
                    references: [
                        {
                            "f_name": "Pho",
                            "l_name": "Neel",
                            "country_id": "+1",
                            "phone": "7891234560"
                        },
                        {
                            "f_name": "Pho",
                            "l_name": "Neel",
                            "country_id": "+1",
                            "phone": "7896541230"
                        }
                    ],
                    UserChildren: [
                        {   
                            "child_id": "fddc6738-12f6-484c-b10e-bbe8387fdcf6",
                            "name": "0-12 months",
                            "f_name": "Tom",
                            "dob": "2022-01-01"
                        }
                    ]
                },
                bids: null
            }
        }

        this.getJobPostingDetails = this.getJobPostingDetails.bind(this)

        this.SendPerponsal = this.SendPerponsal.bind(this)
        this.HandleModalOpen = this.HandleModalOpen.bind(this)

    }

    getParentProfile = (user_id) => {
        
        instance.get(`/get-parent-profile?user_id=${user_id}`).then((res) => {
            this.setState({
                loading:false,
                child_age:res.data.data.children,
            });
        }).catch((er) => {
            //console.log(er);
            this.setState({loading:false});
        })}

    componentDidMount() {
        
        if(this.state.JobID == ''){
            this.props.history.push('/sitter/dashboard');
        }else{
            this.getJobPostingDetails();
            this.getParentProfile(this.state.GetPost.user_id);
        }
    }

    getJobPostingDetails = () => {

        instance.get(`/sitter/job-details?job_id=${this.state.JobID}`).then((res) => {
            this.setState({GetPost:res.data.data,loading:false});
            this.getParentProfile(this.state.GetPost.user_id);
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })

    }

    SendPerponsal = (e) => {

        this.setState({loading:true})
        instance.post(`/sitter/add-bid`,{job_id:this.state.JobID}).then((res) => {
            // console.log(res.data.data);
            this.HandleModalOpen(e,'SendParposal');
            this.setState({loading:false});
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })

    }

    HandleWithDraw = (e) => {
        this.HandleModalClose(e,'CancelModal');
        this.setState({loading:true})
        instance.put(`/sitter/cancel-bid`,{job_id:this.state.JobID}).then((res) => {
            this.getJobPostingDetails();
            toast('You withdrew your proposal successfully', { type: "success" });
            // this.setState({loading:false});
        }).catch((error) => {
            console.error(error)
            this.setState({loading:false});
            toast(error.response.data.message, { type: "error" });
        })

    }

    HandleModalOpen = (e,modalname,id='') => {
        this.setState({[modalname]:true,CancelId:id})
    }

    HandleModalClose = (e,modalname,id='') => {
        this.setState({[modalname]:false,CancelId:id});

        if(modalname == 'SendParposal'){
            this.props.history.push('/sitter/dashboard');
        }
    }

    render() {
        // Parent selected child during job post
        this.state.GetPost.setSelectedChild_id = this.state.GetPost.setSelectedChild_id.toString().replace(/[\[\]']+/g, '').replaceAll('"','').split(',');
        
        return (
            <>
<section className={`dashboard ${(this.state.loading) ? 'loader' : ''}`}>
        <div className="container">
            <div className="row job-detail-header">
                <div className="col-12 pb-4 pb-md-1"><Link to="/sitter/dashboard"  className="back-button"><i className="fa fa-arrow-left"></i>Back</Link></div>
                <a target="_blank" href={`http://www.google.com/maps/place/${this.state.GetPost.lat},${this.state.GetPost.lng}`} className="view-location blue-grad btn-blue">View location <i className="fa fa-external-link-alt"></i></a>
            </div>
            <div className="row mt-4 m-0 mt-md-4">
                <div className="col-12 col-md-8 py-2">
                    <div className="description d-grid">
                        <h4 className="underline"><i className="fa fa-briefcase text-pink"></i> Job details</h4>
                        {}
                        <span className="h5">Work Date</span>
                        <ul className="list-inline work-timings">
                            <li className="list-inline-item"><span>Start Date- {moment(this.state.GetPost.start_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}</span></li>
                            <li className="list-inline-item"><span>End Date- {moment(this.state.GetPost.end_date,'YYYY-MM-DD').format('DD MMMM, YYYY')}</span></li>
                        </ul>
                        <span className="h5">Work timings</span>
                        <ul className="list-inline work-timings">
                            <li className="list-inline-item"><span>Start time- {moment(this.state.GetPost.start_time,'hh:mm:ss').format('hh:mm A')}</span></li>
                            <li className="list-inline-item"><span>End time- {moment(this.state.GetPost.end_time,'hh:mm:ss').format('hh:mm A')}</span></li>
                        </ul>
                        <span className="h5">Health condition details</span>
                        <p>{this.state.GetPost.allergies}</p>
                        <span className="h5">Description</span>
                        <p>{this.state.GetPost.job_details}</p>

                        

                        <h4 className="underline pt-3">References</h4>
                        {this.state.GetPost.parent.references.map((reference,Rindex)=> {
                            return (
                            <div key={Rindex}> 
                            <span>Reference {Rindex+1} : </span>
                            <span className="text-blue">{reference.f_name} {reference.l_name} | {reference.country_id} {reference.phone}</span>

                            </div>)
                        })}
                        
                        <h4 className="underline pt-3">Children</h4>
                        {this.state.child_age && this.state.child_age.map((child,index)=> {
                            // console.log(child.child_id);
                            // console.log(this.state.GetPost.setSelectedChild_id);

                           // var matchId = Object.values(child.child_id);
                            var matchId = this.state.GetPost.setSelectedChild_id.filter(function (child_id) {return child.child_id == child_id});
                              if(matchId[0] !== undefined){
                                // console.log(this.state.GetPost.setSelectedChild_id[index]);
                                let age = moment().diff(child.dob, 'years');
                                let agegroup;

                                if (age >= 0 && age < 1) {
                                    agegroup = "0-12 months";
                                    child.name = agegroup;
                                
                                } else if (age >= 1 && age < 2) {
                                    agegroup = "1-2 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 2 && age < 3) {
                                    agegroup = "2-3 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 3 && age < 4) {
                                    agegroup = "3-4 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 4 && age < 5) {
                                    agegroup = "4-5 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 5 && age < 6) {
                                    agegroup = "5-6 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 6 && age < 7) {
                                    agegroup = "6-7 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 7 && age < 8) {
                                    agegroup = "7-8 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 8 && age < 9) {
                                    agegroup = "8-9 yrs";
                                    child.name = agegroup;
                                
                                } else if (age >= 9 && age < 10) {
                                    agegroup = "9-10 yrs";
                                    child.name = agegroup;
                                
                                } else {
                                    agegroup = "0-12 months";
                                    child.name = agegroup;
                                }   
                                //console.log(child.child_id);
                                return (
                                    <div key={index}> 
                                    <span className="text-blue"> {child.f_name} 
                                    <span > | {agegroup}</span>
                                    </span>
                                    </div>);  
                              } 
                                                      
                        })}
                    </div>
                </div>
                <div className="col-12 col-md-4 py-2">
                    <div className="references-detail d-grid about-sitter">
                        <h4 className="underline">{} About {this.state.GetPost.parent.first_name} {this.state.GetPost.parent.last_name}</h4>
                        <ul>
                            {/* <li><span className="bold">Religion : </span><span>{this.state.GetPost.parent.religion}</span></li> */}
                            <li><span className="bold">Language : </span><span>{this.state.GetPost.parent.language}</span></li>
                            <li><span className="bold">Spouse's name : </span><span>{this.state.GetPost.parent.spouse}</span></li>
                            <li><span className="bold">Family : </span>
                            <span> Children - {this.state.child_age != undefined && this.state.child_age.map((child,index)=> {
                                   this.state.GetPost.setSelectedChild_id = this.state.GetPost.setSelectedChild_id.toString().replace(/[\[\]']+/g, '').replaceAll('"','').split(',');
                                    return(null);
                                })}<span>{this.state.GetPost.setSelectedChild_id.length}</span>
                                </span>
                                </li> 
                            <li><span className="bold">Pet(s) : </span><span>{(this.state.GetPost.parent.pets) ? this.state.GetPost.parent.pets_details : 'No'}
                            </span></li>
                            <li>
                                <span className="bold">Amenities: </span>
                                <span>{this.state.GetPost.parent.amenities.map((amenitie,Aindex)=> ( `${amenitie}, ` ))}</span>
                            </li>
                            <li>
                            {/* <span className="bold" >Children age : </span>
                             <span>{this.state.GetPost.parent.UserChildren.map((child,Aindex)=> ( `${child}, ` ))}</span> */}
                            </li>
                         </ul>
                        {/* <span className="text-blue mt-2"><i className="fa fa-check-circle"></i> Background verified</span> */}
                    </div>
                    <div className="col-12 detail-actions pt-3">
                        <ul className="list-inline">
                            {/* <li className="list-inline-item"><a  className="btn chat-button small-btn text-white " data-bs-toggle="modal" data-bs-target="#attention"><i className="chat"></i>Chat</a></li> */}

                            <li className="list-inline-item">
                                <a  disabled={this.state.GetPost.already_applied} className={`btn  small-btn blue-grad text-white ${(this.state.GetPost.already_applied) ? 'disabled' : ''}`} onClick={this.SendPerponsal} >
                                    
                                    { (this.state.GetPost.already_applied) ? 'Proposal Sent' : 'Send Proposal'}
                                    </a>
                            </li>
                            { (this.state.GetPost.already_applied) ? <li className="list-inline-item" > 

                                    <p className="text-pink" > You sent a proposal for this job on {moment(this.state.GetPost.bids.createdAt).format('DD MMMM, YYYY hh:mm A')} </p>
                                    <a className="text-right pull-right" onClick={(e) => this.HandleModalOpen(e,'CancelModal')} > Withdraw  Proposal? </a>
                                    
                              </li> : ''}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="" className="send-proposal-modal " show={this.state.SendParposal} onHide={(e)=> this.HandleModalClose(e,'SendParposal')}>        
        
            <Modal.Body>
            <div className="col-12 text-center">
                            <i className="fa fa-check-circle"></i>
                            <h3 className="text-blue pt-5">Proposal sent successfully!</h3>
                            <p className="text-grey">You can send proposals to other jobs after 5 mins</p>
            </div>
            </Modal.Body>   
                <Modal.Footer className="justify-content-center" >
              <button onClick={(e)=> this.HandleModalClose(e,'SendParposal')} type="button" className="btn btn-primary btn-blue small-btn">Okay</button>
             </Modal.Footer>                                     
       
      </Modal>

      <Modal dialogClassName="modal-dialog-centered justify-content-center" contentClassName="blue-grad " className="cancel-job-modal " show={this.state.CancelModal} onHide={(e)=> this.HandleModalClose(e,'CancelModal')}>        
        
            <Modal.Body>
                <div className="col-12 text-center">
                        <h3 className="text-white pt-4">Are you sure </h3>
                        <p className="text-white"> you want to withdraw your proposal?</p>
                </div>
            </Modal.Body>   
                <Modal.Footer className="justify-content-center" >
                <button onClick={(e)=> this.HandleModalClose(e,'CancelModal')} type="button" className="btn btn-primary small-btn">No</button> &nbsp;&nbsp;&nbsp;
                <button onClick={(e) => this.HandleWithDraw(e)} type="button" className="btn btn-white small-btn text-blue">Yes</button>
             </Modal.Footer>                                     
       
      </Modal>      
            </>
        );
    }
}

JobDetails.propTypes = {

};

export default JobDetails;