import React, { Component } from 'react';
import ProfilePicturePlaceholder from "../../assets/images/profile-picture (1).png";
import LogoTextBlue from "../../assets/images/logo-text-blue (1).png";
import ProfileService from '../../services/Profile.Service';
import TokenService from '../../services/Token.Service';
import { Link } from 'react-router-dom';

class SidebarOne extends Component {

    constructor(props){
        super(props);
        this.state = {
            payments:this.props.payments
        }

    }

    componentDidMount(){
        // console.log('this', this.props.payments)
        // console.log('this---', this.state.payments)
    }
   
    componentDidUpdate(prevProps) {
        //console.log('this', this.props.payments)
        if(prevProps.payments !== this.props.payments) {
          this.setState({payments: this.props.payments});
        }
      }

    handleLogoClick() {
        if(document.getElementById('dashboard-tab')){
            document.getElementById('dashboard-tab').click();
        }
    }

    handleActivitiesClick(activity) {
        let role = TokenService.getUserRole();
        let jobsTab = document.getElementById('jobs-tab');
        let dashboardTab = document.getElementById('dashboard-tab');
        let homeTab = document.getElementById('home-tab');
        let upcomingTab = document.getElementById('upcoming-tab');
        let completedTab = document.getElementById('active-posting-tab');
        let currentPostingTab = document.getElementById('current-posting-tab');
        let jobPosts = document.getElementById('current-posting-tab');

        if(activity.name === 'Current jobs'){
            // determine whether the user is a sitter or a parent
            if(role === 1){
                if(dashboardTab){
                    dashboardTab.click();
                }
            } else if(role === 2){
                if(homeTab){
                    homeTab.click();
                    if(currentPostingTab){
                        currentPostingTab.click();
                    }
                }
            }            
        }
        if(activity.name === 'Upcoming jobs'){
            if(role === 1){
                if(jobsTab){
                    jobsTab.click();
                    if(upcomingTab){
                        upcomingTab.click();
                    }
                }
            } else if(role === 2){
                if(homeTab){
                    homeTab.click();
                    if(upcomingTab){
                        upcomingTab.click();
                    }
                }
            }
        }
        if(activity.name === 'Completed jobs'){
            if(role === 1){
                if(jobsTab){
                    jobsTab.click();
                    if(completedTab){
                        completedTab.click();
                    }
                }
            } else if(role === 2){
                if(jobsTab){
                    jobsTab.click();
                    if(completedTab){
                        completedTab.click();
                    }
                }
            }
        }
        if(activity.name === 'Your job posts'){
            if(role === 1){
                if(jobsTab){
                    jobsTab.click();
                    if(jobPosts){
                        jobPosts.click();
                    }
                }
            }
        }
        if(activity.name === 'All job posts'){
            if(role === 2){
                if(dashboardTab){
                    dashboardTab.click();
                    // if(jobPosts){
                    //     jobPosts.click();
                    // }
                }
            }
        }
    }

    render() {
        <Link to={`/${this.props.type}/profile`} >
        <img  src={ ProfileService.getUserImage() ?? ProfilePicturePlaceholder } alt=""/>
        </Link> 
    // <p className='text-blue' ><i className='fa fa-star' ></i> {this.props.rating}({this.props.rating_count} ratings)</p>;
        let nbsp = "\u00A0";
        return (
            <>
             <div className="profile">
                        <ul>
                            <li>
                                <Link to={`/${this.props.type}/dashboard`} onClick={this.handleLogoClick}>
                                    <img className="logo" src={LogoTextBlue} alt="" />
                                </Link>
                            </li>
                            <li>
                                <ul className="list-inline avatar-profile-list">
                                    <li className="list-inline-item">
                                        <div className="profile-pic">
                                            <Link to={`/${this.props.type}/profile`} >
                                                <img  src={ ProfileService.getUserImage() ?? ProfilePicturePlaceholder } alt=""/>
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <ul>
                                            {/* <li>
                                                <p className="text-blue">{TokenService.getUserFullname()}</p>
                                                <ConditionalLink>
                                                    {/* <p className='text-blue' ><i className='fa fa-star' ></i> {this.props.rating}({this.props.rating_count} ratings)</p> */}
                                                {/* </ConditionalLink> */}
                                            {/* </li> */}
                                            <li>
                                               {this.props.button}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 side-menu">
                       <div className="menu-items">
                       {this.state.payments}
                    <h5 className="text-grey">Your activities</h5>
                    {this.props.activities.map((value) => {
                        // if
                        if('Ongoing jobs' == value.name){
                            value.name = 'Current Jobs';
                        }
                        return (
                        <div key={value.name} className="col-12 mb-1">
                        <span className="h6 text-blue "><Link to={`/${this.props.type}/dashboard`} onClick={()=>this.handleActivitiesClick(value)}>{value.name}</Link></span><span className="float-end text-grey">{value.count}</span>
                    </div>
                    )
                    })}
                </div>
                        <div className="contacts">
                            <br/>
                            <h5 className="text-grey">Help</h5>
                            {/* <small><i className="fa fa-phone"></i> +91 90909-90909</small> */}
                            <small style={{fontSize: 14}}><a aria-label="Support Email" href='mailto:support@simplisitter.com'><i className="fa fa-envelope" style={{fontSize: 14}}>{nbsp}{nbsp} support@simplisitter.com</i></a></small>
                            <small style={{fontSize: 18}}><a aria-label="Chat on WhatsApp"  target='blank' href='https://wa.me/18455731801'><i className="fab fa-whatsapp" style={{fontSize: 18}}></i>{nbsp} +1 8455731801 </a></small>
                            <small style={{fontSize: 20}}><Link to="/term-sitter" aria-label="Terms of use" target="_blank" style={{fontSize:16}}><i className="fa fa-file-contract" style={{fontSize: 18}}></i>{nbsp}{nbsp} Terms of Use</Link></small>
                            <small style={{fontSize: 18}}><Link to="/privacy-policy" aria-label="Privacy policy" target="_blank" style={{fontSize:16}}><i className="fa fa-user-shield"  style={{fontSize: 16}}></i> Privacy Policy</Link></small>
                            <small style={{fontSize: 18}}><Link to="/faq" aria-label="FAQ" target="_blank" style={{fontSize: 16}}><i className="fa fa-question-circle" style={{fontSize: 17}}></i>{nbsp}{nbsp}FAQ</Link></small>
                        </div>
                    </div>   
            </>
        );
    }
}

export default SidebarOne;